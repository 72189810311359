import React, { useState } from "react";
import "./ChatWindow.scss";

const ChatWindow = ({ onClose }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const handleSendMessage = () => {
    if (message.trim()) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "buyer", content: message },
      ]);
      setMessage("");
    }
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        <h4>Chat with Seller</h4>
        <button className="close-btn" onClick={onClose}>
          ✕
        </button>
      </div>
      <div className="chat-body">
        {chatHistory.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${msg.sender === "buyer" ? "sent" : "received"}`}
          >
            {msg.content}
          </div>
        ))}
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message"
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatWindow;
