import React, { useState } from "react";
import { ReactComponent as EmailIcon } from "../assets/svg/Email.svg";
import "./ForgotPassword.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forget-user-password-email`,
        { email }
      );

      if (response.status === 200) {
        toast.success(t(response.data.message));
      }
    } catch (error) {
      console.error("Forgot password error:", error);
      toast.error(
        error.response?.data?.message || "Failed to send reset link."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__inner">
        <a href="#" className="themeBrand">
          <Trendies />
        </a>
        <h2 className="ForgotPassword__title">{t("FORGOT_PASSWORD")}</h2>

        <form onSubmit={handleSubmit} className="ForgotPassword__form">
          <div className="form-group">
            <EmailIcon className="icon" />
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
              className="input-with-icon"
            />
          </div>

          <div className="ForgotPassword__btn">
            <button type="submit" className="btn w-100" disabled={loading}>
              {loading ? "Sending..." : t('SEND_RESET_LINK')}
            </button>
          </div>
          <div className="ForgotPassword__footer">
            <p>
              {t("REMEMBER_YOUR_PASSWORD")}{" "}
              <Link to="/login">{t("LOGIN_HERE")}</Link>
            </p>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
