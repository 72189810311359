import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { ReactComponent as DismissCircle } from "../../assets/svg/DismissCircle.svg";
import { ReactComponent as UploadIcon } from "../../assets/svg/UploadIcon.svg";
import { categoryOptions } from "../../common/Category";
import axios from "axios";
import { SellerProfileValidationSchema } from "../../Validation/validationSchemas";
import { useFormik } from "formik";
import TermsAndConditionsModal from "../TermsAndConditions/TermsAndConditions";
import { ReactComponent as EyeHide } from "../../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../../assets/svg/EyeShow.svg";
import "./SellerProfileForm.scss";

const SellerProfileForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const loginViaSocial = localStorage.getItem("loginViaSocial");
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("text");
  const [showPassword, setShowPassword] = useState(false);
  const [getSellerPassword, setGetSellerPassword] = useState("");
  const fileInputRef = useRef(null);
  const identityInputRef = useRef(null);
  const [termsAnsConditionLoading, setTermsAnsConditionLoading] = useState(false)

  useEffect(() => {
    getSellerUserpassword();
  }, []);

  useEffect(() => {
    if (getSellerPassword) {
      formik.setFieldValue("password", getSellerPassword);
    }
  }, [getSellerPassword]);

  const handleFocus = () => {
    setInputType("date");
  };
  const handleBlur = () => {
    setInputType("text");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

  const authenticationProcessOption = [
    {
      value: "Platform",
      label: t("PLATFORM"),
    },
    {
      value: "Own Proof",
      label: t("PROOF"),
    },
  ];

  const sellerTypeOption = [
    { value: "Individual", label: t("INDIVIDUAL") },
    { value: "Business", label: t("BUSINESS") },
  ];

  async function getSellerUserpassword() {
    try {

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/decrypt/${userData?.id}`
      );

      if (response?.status === 200) {
        const decryptedPassword = response?.data?.data?.decryptedPassword;
        setGetSellerPassword(decryptedPassword);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      fullName: loginViaSocial
        ? userData?.username
        : userData?.nickname
          ? userData?.nickname
          : "",
      emailAddress: userData?.email ?? "",
      phoneNumber: "",
      password: getSellerPassword,
      governmentId: null,
      dateOfBirth: "",
      residentialAddress: "",
      showroomAddress: "",
      sellerType: "",
      accountHolderName: "",
      bankName: "",
      IBAN: "",
      taxIdentificationNumber: "",
      companyName: "",
      websiteOrSocialMedia: "",
      productCategories: [],
      termsAccepted: false,
      privacyPolicyAccepted: false,
      previousSalesExperience: false,
      authenticationProcess: "",
      profilePhoto: null,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: SellerProfileValidationSchema(t, "FR"),
    // onSubmit: (values) => handleSubmitSellerProfile(values),
    onSubmit: () => setOpenTermsAndConditions(true),
  });

  const getUserById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-user`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        const authData = response.data.data;
        localStorage.setItem("userData", JSON.stringify(authData));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitSellerProfile = async (values) => {
    try {
      setTermsAnsConditionLoading(true)
      const formData = new FormData();
      Object.keys(values).forEach((key) => formData.append(key, values[key]));
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seller`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        await handleSubmit(values?.password, userData?.id);
        await getUserById();
        toast.success(t("SELLER_PROFILE_SUCCESS"), {
          onClose: () => {

            navigate("/home");
          },
          autoClose: 1500, // 1.5 seconds
        });
        formik.resetForm();
      }
    } catch (error) {
      toast.error("Error in creating seller profile!");
      console.error("Error:", error);
    } finally {
      setTermsAnsConditionLoading(false)
    }
  };

  const handleSubmit = async (password, userId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/change-user-password`,
        { changedPassword: password, userId }
      );
      if (response.status == 200) {
        console.log("Password update successful!");
      }
    } catch (error) {
      console.error("Change password error:", error);
      toast.error(error.response?.data?.message || "Change failed. Try again.");
    }
  };

  return (
    <div style={{ marginBottom: "10px" }} className="main-Wrapper">
      <div className="add-product-container">
        <ToastContainer />
        <div className="sectionHeader">
          <h3>{t("SELLER_PROFILE")}</h3>
          <button className="btn btn-primary" onClick={() => navigate("/profile")}>
            {t("BACK")}
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="productForm">
          {/* Personal Information */}
          <h4>{t("PERSONAL_INFORMATION")}</h4>
          <div className="productForm__inner">
            <div className="form-group">
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder={t("FULL_NAME")}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <div className="error-message">{formik.errors.fullName}</div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="email"
                name="emailAddress"
                placeholder={t("EMAIL_ADDRESS")}
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.emailAddress && formik.errors.emailAddress ? (
                <div className="error-message">
                  {formik.errors.emailAddress}
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="tel"
                // ref={inputRef}
                name="phoneNumber"
                placeholder={t("PHONE_NUMBER")}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="error-message">{formik.errors.phoneNumber}</div>
              ) : null}
            </div>

            <div className="form-group">
              {/* <Lock className="icon" /> */}

              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={t("PASSWORD")}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="EyeIcon" onClick={togglePasswordVisibility}>
                {showPassword ? <EyeShow /> : <EyeHide />}
              </span>
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="inputUpload">
              <input
                type="file"
                name="profilePhoto"
                accept="image/*"
                onChange={(event) =>
                  formik.setFieldValue("profilePhoto", event.target.files[0])
                }
                ref={fileInputRef}
              />
              <UploadIcon />
              <p>{t("PROFILE_PICTURE")}</p>
            </div>
            {formik.values.profilePhoto && (
              <div className="imgPreview">
                <div className="imgPreview__inner">
                  <img
                    src={URL.createObjectURL(formik.values.profilePhoto)}
                    alt={t("UPLOADED_IMAGE")}
                  />
                  <button
                    className="remove-image"
                    onClick={() => {
                      formik.setFieldValue("profilePhoto", null);
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                    }}
                  >
                    <DismissCircle />
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Identity Verification */}
          <div>
            <h4>{t("IDENTITY_VERIFICATION")}</h4>
            <div className="productForm__inner">
              <div className="ImageUpload">
                <div className="inputUpload">
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    name="governmentId"
                    onChange={(event) =>
                      formik.setFieldValue(
                        "governmentId",
                        event.target.files[0]
                      )
                    }
                    ref={identityInputRef}
                  />
                  <UploadIcon />
                  <p>{t("IDENTIFICATION_PROOF")}</p>
                  {formik.touched.governmentId && formik.errors.governmentId ? (
                    <div className="error-message">
                      {formik.errors.governmentId}
                    </div>
                  ) : null}
                </div>
                {formik.values.governmentId && (
                  <div className="imgPreview">
                    <div className="imgPreview__inner">
                      {formik.values.governmentId.type === "application/pdf" ? (
                        <embed
                          style={{ maxHeight: "fit-ontent" }}
                          src={URL.createObjectURL(formik.values.governmentId)}
                          type="application/pdf"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(formik.values.governmentId)}
                          alt={t("UPLOADED_IMAGE")}
                        />
                      )}
                      <button
                        className="remove-image"
                        onClick={() => {
                          formik.setFieldValue("governmentId", null);
                          if (identityInputRef.current) {
                            identityInputRef.current.value = "";
                          }
                        }}
                      >
                        <DismissCircle />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="dateOfBirth" className="date-placeholder">
                  {t("BIRTH_DATE")}
                </label>
                <input
                  type={"date"}
                  name="dateOfBirth"
                  placeholder={t("BIRTH_DATE")}
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onFocus={handleFocus}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleBlur();
                  }}
                  max="2005-12-31"
                // max={new Date().toISOString().split("T")[0]}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                  <div className="error-message">
                    {formik.errors.dateOfBirth}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Address Details */}
          <div>
            <h4>{t("ADDRESS_DETAILS")}</h4>
            <div className="productForm__inner">
              <div className="form-group">
                <input
                  type="text"
                  name="residentialAddress"
                  placeholder={t("RESIDENTIAL_ADDRESS")}
                  value={formik.values.residentialAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.residentialAddress &&
                  formik.errors.residentialAddress ? (
                  <div className="error-message">
                    {formik.errors.residentialAddress}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="showroomAddress"
                  placeholder={t("SHOW_ROOM_ADDRESS")}
                  value={formik.values.showroomAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {/* {formik.touched.showroomAddress &&
                formik.errors.showroomAddress ? (
                  <div className="error-message">
                    {formik.errors.showroomAddress}
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>

          {/* Payment Information */}
          <div>
            <h4>{t("PAYMENT_INFORMATION")}</h4>
            <div className="productForm__inner">
              <div className="form-group">
                <input
                  type="text"
                  name="accountHolderName"
                  placeholder={t("ACCOUNT_NAME")}
                  value={formik.values.accountHolderName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.accountHolderName &&
                  formik.errors.accountHolderName ? (
                  <div className="error-message">
                    {formik.errors.accountHolderName}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="IBAN"
                  placeholder="IBAN"
                  value={formik.values.IBAN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.IBAN && formik.errors.IBAN ? (
                  <div className="error-message">{formik.errors.IBAN}</div>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="bankName"
                  placeholder={t("BANK_NAME")}
                  value={formik.values.bankName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.bankName && formik.errors.bankName ? (
                  <div className="error-message">{formik.errors.bankName}</div>
                ) : null}
              </div>
            </div>

            {/* Seller Type */}
            <div style={{ position: "relative", zIndex: 4 }}>
              <h4>{t("SELLER_TYPE")}</h4>
              <div className="productForm__inner">
                <div className="form-group">
                  <Select
                    className="customSelect"
                    name="sellerType"
                    options={sellerTypeOption}
                    value={sellerTypeOption.find(
                      (option) => option.value === formik.values.sellerType
                    )}
                    onChange={(selectedOption) =>
                      formik.setFieldValue("sellerType", selectedOption.value)
                    }
                    placeholder="Seller Type"
                  />
                  {formik.touched.sellerType && formik.errors.sellerType && (
                    <div className="error-message">
                      {formik.errors.sellerType}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Tax ID */}
            {formik.values.sellerType === "Business" && (
              <div>
                <h4>{t("TAX_INFORMATION")}</h4>
                <div className="productForm__inner">
                  <div className="form-group">
                    <input
                      type="text"
                      name="taxIdentificationNumber"
                      placeholder={t("TAX_IDENTIFICATION_NUMBER")}
                      value={formik.values.taxIdentificationNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.taxIdentificationNumber &&
                      formik.errors.taxIdentificationNumber ? (
                      <div className="error-message">
                        {formik.errors.taxIdentificationNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="companyName"
                      placeholder={t("COMPANY_NAME")}
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div className="error-message">
                        {formik.errors.companyName}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      type="url"
                      name="websiteOrSocialMedia"
                      placeholder={t("WEBSITE_SOCIALMEDIA")}
                      value={formik.values.websiteOrSocialMedia}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.websiteOrSocialMedia &&
                      formik.errors.websiteOrSocialMedia ? (
                      <div className="error-message">
                        {formik.errors.websiteOrSocialMedia}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Product Categories */}
          <div>
            <h4>{t("PRODUCT_CATEGORIES")}</h4>
            <div className="productForm__inner">
              <div className="form-group">
                <Select
                  classNamePrefix="customSelect"
                  name="productCategories"
                  placeholder={t("PRODUCT_CATEGORIES")}
                  isMulti={true}
                  options={categoryOptions}
                  value={categoryOptions.filter((option) =>
                    formik.values.productCategories.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    formik.setFieldTouched("productCategories", true);
                    formik.setFieldValue(
                      "productCategories",
                      selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []
                    );
                  }}
                // onBlur={() =>
                //   formik.setFieldTouched("productCategories", true)
                // }
                />
                {formik.touched.productCategories &&
                  formik.errors.productCategories ? (
                  <div className="error-message">
                    {formik.errors.productCategories}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Seller Agreement */}
          {/* <div>
            <h4>{t("AGREEMENTS")}</h4>
            <div className="productForm__inner">
              <label>
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formik.values.termsAccepted}
                  onChange={(event) =>
                    formik.setFieldValue("termsAccepted", event.target.checked)
                  }
                  onBlur={formik.handleBlur}
                />
                {t("ACCEPT_TERMS_AND_CONDITIONS")}
                {formik.touched.termsAccepted && formik.errors.termsAccepted ? (
                  <div className="error-message">
                    {formik.errors.termsAccepted}
                  </div>
                ) : null}
              </label>
              <label>
                <input
                  type="checkbox"
                  name="privacyPolicyAccepted"
                  checked={formik.values.privacyPolicyAccepted}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "privacyPolicyAccepted",
                      event.target.checked
                    )
                  }
                  onBlur={formik.handleBlur}
                />
                {t("ACCEPT_PRIVACY_POLICY")}
                {formik.touched.privacyPolicyAccepted &&
                formik.errors.privacyPolicyAccepted ? (
                  <div className="error-message">
                    {formik.errors.privacyPolicyAccepted}
                  </div>
                ) : null}
              </label>
            </div>
          </div> */}

          {/* Luxury Authentication */}
          <div>
            <h4>{t("BRAND_AUTHENTICATION")}</h4>
            <div className="productForm__inner">
              <label>
                <input
                  type="checkbox"
                  name="previousSalesExperience"
                  checked={formik.values.previousSalesExperience}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "previousSalesExperience",
                      event.target.checked
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
                {t("PREVIOUS_SALES_EXPERIENCE")}
                {formik.touched.previousSalesExperience &&
                  formik.errors.previousSalesExperience ? (
                  <div className="error-message">
                    {formik.errors.previousSalesExperience}
                  </div>
                ) : null}
              </label>
              <div className="form-group">
                <Select
                  className="customSelect"
                  name="authenticationProcess"
                  placeholder={t("AUTHENTICATION_PROCESS")}
                  options={authenticationProcessOption}
                  value={authenticationProcessOption.find(
                    (option) =>
                      option.value === formik.values.authenticationProcess
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "authenticationProcess",
                      selectedOption.value
                    )
                  }
                />
                {formik.touched.authenticationProcess &&
                  formik.errors.authenticationProcess ? (
                  <div className="error-message">
                    {formik.errors.authenticationProcess}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="btnPart">
            <button type="submit" className="btn">
              {loading ? "Loading..." : t("CREATE_PROFILE")}
            </button>
          </div>
        </form>
      </div>

      <TermsAndConditionsModal
        show={openTermsAndConditions}
        onHide={() => setOpenTermsAndConditions(false)}
        onProceed={() => handleSubmitSellerProfile(formik.values)}
        termsAnsConditionLoading={termsAnsConditionLoading}
      />
    </div>
  );
};

export default SellerProfileForm;
