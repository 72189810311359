// userSync.js
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Custom hook to handle user data synchronization
export const useUserSync = () => {
  const dispatch = useDispatch();
  const userData = JSON?.parse(localStorage?.getItem('userData'));
  const userId = userData?.id

  const syncUserData = async () => {
    try {
      const userData = JSON?.parse(localStorage.getItem("userData"));
      const token = localStorage.getItem("token");

      if (userData?.id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const freshUserData = response?.data?.data;
        if (!userId) {
          // localStorage.removeItem("token")
        }
        if (response.status === 200) {
          localStorage?.setItem("userData", JSON?.stringify(freshUserData));
          dispatch({
            type: "UPDATE_USER",
            payload: freshUserData,
          });
        } else {
          localStorage.removeItem("userData");
          localStorage.removeItem("token");
          dispatch({ type: "LOGOUT" });
        }
      } else {
        // localStorage.removeItem("token");
      }
    } catch (error) {
      if (error?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.setItem("loginViaSocial", false)
      }
      console.error("Error syncing user data:", error);
      // Handle error appropriately
    }
  };

  // Sync on mount and window focus
  // useEffect(() => {


  //   // Add focus event listener
  //   const handleFocus = () => {
  //     syncUserData();
  //   };

  //   window.addEventListener("focus", handleFocus);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener("focus", handleFocus);
  //   };
  // }, []);

  syncUserData();

  return { syncUserData };
};
