// src/pages/ResetPassword.js
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LockIcon } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import "./ResetPassword.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forget-user-password`,
        { token, newPassword: password },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message || "Password reset successful!");
        navigate("/login"); // Redirect to login page
      }
    } catch (error) {
      console.error("Reset password error:", error);
      toast.error(error.response?.data?.message || "Reset failed. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ResetPassword">
      <div className="ResetPassword__inner">
        <h2 className="ResetPassword__title">Reset Password</h2>

        <form onSubmit={handleSubmit} className="ResetPassword__form">
          <div className="form-group">
            <LockIcon className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="New Password"
              className="input-with-icon"
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              <EyeHide />
            </span>
          </div>

          <div className="form-group">
            <LockIcon className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm Password"
              className="input-with-icon"
            />
          </div>

          <div className="ResetPassword__btn">
            <button type="submit" className="btn w-100" disabled={loading}>
              {loading ? "Resetting..." : "Reset Password"}
            </button>
          </div>
          <div className="ResetPassword__footer">
            <p>
              Know your password? <Link to="/login">Log in here</Link>
            </p>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
