// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword {
  max-width: 450px;
  margin: auto;
  height: 100vh;
  width: 100%;
}
.ForgotPassword__inner {
  padding: 30px;
  height: calc(100% - 60px);
}
.ForgotPassword__title {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin: 54px 0 40px;
}
.ForgotPassword__form {
  padding: 35px 20px;
}
.ForgotPassword__footer {
  margin-top: 1.5rem;
}
.ForgotPassword__footer a {
  color: #000;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
}
.ForgotPassword__footer a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/ForgotPassword.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,yBAAA;AAEJ;AACE;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACE;EACE,kBAAA;AACJ;AACE;EACE,kBAAA;AACJ;AAAI;EACE,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;AAEN;AADM;EACE,0BAAA;AAGR","sourcesContent":[".ForgotPassword {\n  max-width: 450px;\n  margin: auto;\n  height: 100vh;\n  width: 100%;\n  &__inner {\n    padding: 30px;\n    height: calc(100% - 60px);\n    \n  }\n  &__title {\n    font-size: 30px;\n    line-height: 36px;\n    text-align: center;\n    margin: 54px 0 40px;\n  }\n  &__form {\n    padding: 35px 20px;\n  }\n  &__footer {\n    margin-top: 1.5rem;\n    a {\n      color: #000;\n      text-decoration: none;\n      transition: 0.3s;\n      font-weight: 600;\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
