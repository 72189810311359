import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as Shoues } from "../assets/svg/Shoues.svg";
import { ReactComponent as Jewelry } from "../assets/svg/jewelry.svg";
import { ReactComponent as Bag } from "../assets/svg/bag.svg";
import { ReactComponent as Accessories } from "../assets/svg/accessories.svg";
import { ReactComponent as Watch } from "../assets/svg/watch.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import { ReactComponent as Female } from "../assets/svg/Female.svg";
import { ReactComponent as Male } from "../assets/svg/Male.svg";
import { ReactComponent as Enfants } from "../assets/svg/Enfants.svg";
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SimpleSlider from "./Slider";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDeleteModal from "../utils/ConfirmDeleteProduct/ConfirmDeleteModal";
import EditProductModal from "../utils/EditProduct/EditProductModal";
import FilterPopup from "./FilterPopup/FilterPopup";
import axios from "axios";
import "./Home.scss";
import { addFavorite, clearFavorite, renderFavorite } from "../store/actions/favoritesAction";
import { useDispatch } from "react-redux";
import { useAuth } from "../AuthContext";
import { FaSearch } from "react-icons/fa";


const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;
  const { isAuthenticated, isAdminLogin } = useAuth();
  const [genderValue, setGenderValue] = React.useState(t("female"));
  const [activeCategory, setActiveCategory] = useState(t("WATCHES"));
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [loading, setLoading] = useState(false)
  const [sliderVisible, setSliderVisible] = useState({
    men: false,
    women: false,
    kids: false,
  });
  const applied = React.useRef(false);
  const categoryIcons = {
    [t("WATCHES")]: <Watch />,
    [t("JEWELRY")]: <Jewelry />,
    [t("BAGS")]: <Bag />,
    [t("SHOES")]: <Shoues />,
    [t("ACCESSORIES")]: <Accessories />,
  };
  const categories = Object.keys(categoryIcons);


  useEffect(() => {
    if (isAuthenticated && isAdminLogin) {
      navigate("/admin");
    }
  }, [isAuthenticated, isAdminLogin, navigate]);

  const handleGenderFilter = (event, newValue) => {
    setGenderValue(newValue);
    applyFilters(newValue, activeCategory);
  };

  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleSearchChange = () => {
    if (searchTerm === "") {
      handleCateogryFilter(activeCategory);
      localStorage?.setItem("searchProduct", JSON.stringify([]));
    } else {
      setActiveCategory("");
      setFilterProducts(
        products &&
        products?.length > 0 &&
        products?.filter((item) => {
          const searchTermLower = searchTerm.toLowerCase();

          // Match by title, brand, or price
          return (
            item.title.toLowerCase().includes(searchTermLower) ||
            item.brand.toLowerCase().includes(searchTermLower) ||
            item.price.toString().includes(searchTerm)
          );
        })
      );
      const filteredProducts =
        products &&
        products?.length > 0 &&
        products?.filter((item) => {
          const searchTermLower = searchTerm.toLowerCase();

          // Match by title, brand, or price
          return (
            item.title.toLowerCase().includes(searchTermLower) ||
            item.brand.toLowerCase().includes(searchTermLower) ||
            item.price.toString().includes(searchTerm)
          );
        });
      localStorage?.setItem("searchProduct", JSON.stringify(filteredProducts));
    }
  };

  const toggleFavorite = async (productId) => {
    if (!userId) {
      navigate("/login");
    } else {
      try {
        setLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/products/${productId}/favorite`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.status === 200) {
          toast?.success(t(response?.data?.message));
          await fetchFavoriteProducts();
          const updateProductList = (prevProducts) =>
            prevProducts.map((product) =>
              product.id === productId
                ? { ...product, isFavorite: !product.isFavorite }
                : product
            );
          setProducts(updateProductList);
          setFilterProducts(updateProductList);
          checkFavoriteProduct(productId);
        }
      } catch (error) {
        toast.error(t("ERROR_IN_TOGGING_FAVORITE"));
        console.error("Error toggling favorite:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const checkFavoriteProduct = (productId) => {
    const isFavorite = products.some(
      (product) => product.id === productId && product.isFavorite
    );

    if (isFavorite) {
      dispatch(clearFavorite(1));
    } else {
      dispatch(addFavorite(1));
    }
  };

  const fetchFavoriteProducts = async () => {
    if (!userId) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        const data = response.data.data;
        // dispatch(renderFavorite(data?.length ? data.length : 0));
        setFavoriteProducts(data);
      }
    } catch (error) {
      // toast.error(t("ERROR_FETCHING_FAVORITE_PRODUCTS"));
      console.error("Error fetching favorite products:", error);
      setFavoriteProducts([]);
    }
  };

  useEffect(() => {
    handleSearchChange();
  }, [searchTerm]);

  const toggleSlider = (category) => {
    setSliderVisible((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  useEffect(() => {
    getProducts();
    fetchFavoriteProducts();
  }, []);

  const getProducts = async () => {

    const frToEn = {
      montres: "watches",
      bijoux: "jewelry",
      sacs: "bags",
      chaussures: "shoes",
      accessoires: "accessories",

      Montres: "watches",
      Bijoux: "jewelry",
      Sacs: "bags",
      Chaussures: "shoes",
      Accessoires: "accessories",
    };

    const currentLanguage = i18n.language;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        let currentCategory = currentLanguage === "fr" ? frToEn[activeCategory] : activeCategory
        const verifiedProducts =
          response?.data?.data?.length > 0 &&
          response?.data?.data?.filter((item) => item.isVerified);

        setProducts(verifiedProducts);

        setFilterProducts(
          Array.isArray(verifiedProducts)
            ? verifiedProducts.filter(
              (item) =>
                item.category.toLowerCase() === currentCategory
            )
            : []
        );

        setActiveCategory(t(activeCategory));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to create a custom tab with an image
  const CustomTab = ({ label, image, value }) => {
    return (
      <Tab
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={image}
              alt={label}
              style={{ width: "20px", height: "20px", marginRight: "8px" }}
            />
            {label}
          </div>
        }
        value={value}
      />
    );
  };

  const handleCateogryFilter = (name) => {
    const lowerCaseName = name.toLowerCase();
    const currentLanguage = i18n.language;

    const frToEn = {
      montres: "watches",
      bijoux: "jewelry",
      sacs: "bags",
      chaussures: "shoes",
      accessoires: "accessories",
    };

    const selectedCategory =
      currentLanguage === "fr" && frToEn[lowerCaseName]
        ? frToEn[lowerCaseName]
        : lowerCaseName;

    setCategoryFilter(selectedCategory);
    setActiveCategory(name);
    applyFilters(genderValue, selectedCategory);
  };

  const applyFilters = (gender, category) => {
    if (!Array.isArray(products)) return;

    let filteredResults = products;

    if (gender) {
      filteredResults = filteredResults.filter(
        (item) => item?.gender?.toLowerCase() === gender?.toLowerCase()
      );
    }

    if (category && category !== "all") {
      filteredResults = filteredResults.filter((item) =>
        item.category.toLowerCase().includes(category.toLowerCase())
      );
    }

    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter((item) =>
        selectedBrands.includes(item.brand)
      );
    }

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        (item) =>
          item.title.toLowerCase().includes(searchTermLower) ||
          item.brand.toLowerCase().includes(searchTermLower) ||
          item.price.toString().includes(searchTerm)
      );
    }

    setFilterProducts(filteredResults);
  };

  const handleBrandFilter = () => {
    applyFilters(genderValue, categoryFilter);
  };

  useEffect(() => {
    if (products.length && !applied.current) {
      applyFilters(genderValue, "watches");
      applied.current = true;
    }
  }, [products]);

  useEffect(() => {
    handleBrandFilter();
  }, [selectedBrands, genderValue]);

  const goToProductDetails = async (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };



  return (
    <div className="home-container">
      <div className="main-Wrapper">
        <div className="searchFilterBlock">
          <ToastContainer />
          <div className="search-container">

            <input
              type="text"
              placeholder={t("SEARCH_PRODUCT")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate("/search-product")
                }
              }}
            />
            <div className="search-span-icon">
              <span
                className="FilterIcon"
                onClick={() => setShowFilterPopup(!showFilterPopup)}
              >
                <Filter />
              </span>
              <span
                className="SearchIcon"
              >
                <FaSearch onClick={() => navigate("/search-product")} className="icon" />
              </span>

            </div>


          </div>
          {selectedBrands.length > 0 ? (
            <span className="filter-count">{selectedBrands.length}</span>
          ) : (
            ""
          )}
          <FilterPopup
            isOpen={showFilterPopup}
            onClose={handleCloseBrandFilter}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />
        </div>

        <div className="category-buttons">
          <Box sx={{ width: "100%" }}>
            <TabContext value={genderValue}>
              <TabList
                onChange={handleGenderFilter}
                aria-label="lab API tabs example"
              >
                <Tab icon={<Female />} label={t("Female")} value="female" />
                <Tab icon={<Male />} label={t("Male")} value="male" />
                <Tab icon={<Enfants />} label={t("kids")} value="kids" />
              </TabList>
              {/* <TabPanel value="1"> */}
              <SimpleSlider />
              {/* </TabPanel> */}
              {/* <TabPanel className="tabDetails" value="2">
                <SimpleSlider />
              </TabPanel>
              <TabPanel className="tabDetails" value="3">
                <SimpleSlider />
              </TabPanel> */}
            </TabContext>
          </Box>
        </div>

        <div className="products-section">
          <div className="sectionHeader">
            <h3>{t("PRODUCTS")}</h3>
          </div>

          <div className="filterBtn">
            {categories?.map((category) => (
              <button
                key={category}
                className={activeCategory === category ? "active" : ""}
                onClick={() => {
                  handleCateogryFilter(category);
                  setActiveCategory(category);
                }}
              >
                {categoryIcons[category]}
                {category}
              </button>
            ))}
          </div>

          {filterProducts?.length > 0 ? (
            <div className="product-list productListBlock">
              {filterProducts?.map((product) => (
                <div className="productItem" key={product.id}>
                  <div className="productItem__img">
                    <img
                      src={
                        product &&
                        product?.productImage?.length > 0 &&
                        product?.productImage[0]
                      }
                      alt={product.title}
                      className="product-image"
                      onClick={() => goToProductDetails(product.id)}
                    />
                    {userId === product?.userId && (
                      <span
                        className="productItem__img-btn"
                        onClick={() => handleEditClick(product)}
                      >
                        <Edit className="editIcon" />
                      </span>
                    )}
                    {userId !== product?.userId &&
                      (userId &&
                        favoriteProducts?.some(
                          (item) =>
                            item.product.id === product.id &&
                            item.userId === userId
                        ) &&
                        product.isFavorite ? (
                        <span
                          className="productItem__img-btn"
                          onClick={() => toggleFavorite(product.id)}
                        >
                          <HeartFill className="icon" />
                        </span>
                      ) : (
                        <span
                          className="productItem__img-btn"
                          onClick={() => toggleFavorite(product.id)}
                        >
                          <Heart className="icon" />
                        </span>
                      ))}
                  </div>
                  <div
                    onClick={() => goToProductDetails(product.id)}
                    className="productItem__info"
                  >
                    <div className="titlePrice">
                      <h4>{product.title}</h4>
                      <h4>{product.price} MAD</h4>
                    </div>
                    {/* <p>{product.description}</p> */}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="noDataBlock">{t("NO_PRODUCTS_FOUND")}</p>
          )}
        </div>
      </div>
      <ConfirmDeleteModal
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        getProducts={getProducts}
      />
      <EditProductModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        product={selectedProduct}
        refreshProducts={getProducts}
        setGenderValue={setGenderValue}
      />
      {
        loading && <div className="loader-wrapper">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      }
    </div>
  );
};

export default Home;
