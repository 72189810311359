import React, { useEffect, useState } from "react";
import "./Payment.scss";
import CardSlider from "../CardSlider";
import BasicModal from "../BasicModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "./utils/Spinner";
import Header from "../Header";
import { calculateFees } from "./utils/FeeCalculator";
import PriceItem from "./utils/PriceItem";
import { toast, ToastContainer } from "react-toastify";
import CreditDebitModal from "./utils/CreditDebitModal";
import ScheduleModal from "../ScheduleTime/ScheduleModal";
import { useTranslation } from "react-i18next";

const Payment = () => {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;
  const { productId } = useParams();
  const [showCustomModel, setShowCustomModel] = useState(false);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState(null);
  const [value, setValue] = useState("1");
  const [isPremiumListing, setIsPremiumListing] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [completeDetails, setCompleteDetails] = useState(null);
  const [sellerId, setSellerId] = useState(null);

  const [editTimeSlot, setEditTimeSlot] = useState(false);

  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);

  const paymentOptions = [
    { label: t("DEBIT_CARD"), value: "debit_card" },
    { label: t("COD"), value: "cod" },
  ];

  useEffect(() => {
    getSoldProductByUserIdAndProductId();
  }, [userId]);

  useEffect(() => {
    if (product?.price) {
      const details = calculateFees(Number(product.price), isPremiumListing);
      setPriceDetails(details);
    }
  }, [product, isPremiumListing]);

  useEffect(() => {
    productId && getProductById(productId);
  }, [productId]);

  useEffect(() => {
    const body = document.body;

    if (isModalOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    // Clean up on component unmount
    return () => {
      body.style.overflow = "auto";
    };
  }, [showCustomModel, isPaymentModalOpen, isModalOpen]);

  if (!priceDetails) return null;

  async function getProductById(productId) {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );
      if (response?.status === 200) {
        const productData = response?.data?.data;
        setSellerId(productData?.userId);
        setProduct(productData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function getSoldProductByUserIdAndProductId() {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-sold-product/${productId}/${userId}`
      );
      if (response?.data?.data?.hasProduct) {
        setIsModalOpen(false);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const openModal = () => {
    setShowCustomModel(true);
  };

  const closeModal = () => {
    setShowCustomModel(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  }

  const updatePaymentStatus = async () => {
    const sendData = {
      paymentOption: selectedPaymentOption,
      paymentStatus: true,
      status: true,
    };
    try {
      const updateResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/update-sold-product/${productId}/${userId}/${sellerId}`,
        sendData
      );
      return updateResponse?.data?.data;
    } catch (error) {
      return error;
    }
  };

  const handlePayment = async () => {
    const updateResponseData = await updatePaymentStatus();

    if (selectedPaymentOption) {
      if (selectedPaymentOption === "debit_card") {
        openPaymentModal();
      } else {
        openModal();
      }
    } else {
      toast.error(t("SELECT_PAYMENT_METHOD"));
    }
  };

  const handleModalClose = () => {
    setCompleteDetails(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <Header />
      <div className="main-Wrapper">
        <div className="payment-container">
          <div className="sectionHeader">
            <h3>{t("SELECT_PAYMENT_METHOD")}</h3>
          </div>
          <div className="payment-methods">
            {paymentOptions.map((option) => (
              <button
                key={option.value}
                className={`payment-button ${
                  selectedPaymentOption === option.value ? "active" : ""
                }`}
                onClick={() => setSelectedPaymentOption(option.value)}
              >
                {option.label}
              </button>
            ))}
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => {
                  setEditTimeSlot(true);
                  setIsModalOpen(true);
                }}
                className="btn"
              >
                {t("TIME_SLOT")}
              </button>
            </div>
          </div>

          <div className="order-details">
            <div className="sectionHeader">
              <h3>{t("SAVED_CARDS")}</h3>
            </div>
            <p className="greencontent">{t("YOU_HAVE_TWO_ACTIVE_CARDS")}</p>

            <div className="CardSlider">
              <CardSlider />
            </div>
          </div>

          <div className="sectionHeader">
            <h3>{t("ORDER_INFORMATION")}</h3>
          </div>
          <div className="orderInfo">
            <div className="orderInfo__inner">
              <div className="orderInfo__image">
                <img src={product?.productImage} alt={product?.title} />
              </div>
              <div className="orderInfo__content">
                <div className="info">
                  <h4>{product?.title?.toUpperCase()}</h4>
                  <div
                    style={{
                      color: "gray",
                      display: "flex",
                      gap: "10px",
                      fontSize: "small",
                    }}
                  >
                    <span>{product?.brand?.toUpperCase()}</span>
                    <span>{product?.category?.toUpperCase()}</span>
                  </div>

                  <p>
                    Pochette Métis Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Quisquam distinctio quaerat est impedit
                    ratione repellendus eveniet, laudantium animi sequi nostrum
                    nesciunt sit vero quod inventore asperiores amet doloremque
                    eos cupiditate!
                    {product?.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="price-summary">
              <PriceItem label={t("SUBTOTAL")} amount={priceDetails.price} />
              <PriceItem label="Commission" amount={priceDetails.commission} />
              <PriceItem
                label={t("BUYER_PROTECTION_FEE")}
                amount={priceDetails.buyerProtectionFee}
              />
              <PriceItem
                label={t("SERVICE_FEES")}
                amount={priceDetails.serviceFee}
              />
              <PriceItem
                label={t("DELIVERY_COSTS")}
                amount={priceDetails.deliveryFee}
              />
              {isPremiumListing && (
                <PriceItem
                  label={t("PREMIUS_LISTING_FEES")}
                  amount={priceDetails.premiumListingFee}
                />
              )}
              <div className="price-item total">
                <span>Total</span>
                <span>{priceDetails.total.toFixed(2)} MAD</span>
              </div>
              <div className="text-center">
                <button className="btn" onClick={() => handlePayment()}>
                  {t("PAY_NOW")}
                </button>
              </div>
            </div>
          </div>

          {/* Conditionally render the modal */}
          {showCustomModel && (
            <BasicModal
              open={showCustomModel}
              onClose={closeModal}
              title={t("PAYMENT_SUCCESS")}
              description={
                "Merci pour votre achat sur Hmiiza ! Vous recevrez sous moins de 24 heures une notification du transporteur vous indiquant la date etl'heure de votre livraison. Restez à l'écoute !"
              }
            />
          )}
          <CreditDebitModal
            isOpen={isPaymentModalOpen}
            onClose={closePaymentModal}
            openModal={openModal}
          />
          <div>
            <ScheduleModal
              isOpen={isModalOpen}
              onClose={() => handleModalClose(false)}
              userId={userId}
              productId={productId}
              sellerId={sellerId}
              setEditTimeSlot={setEditTimeSlot}
              editTimeSlot={editTimeSlot}
              priceDetails={priceDetails}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Payment;
