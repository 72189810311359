import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Auth Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [userData, setUserData] = useState(() => JSON.parse(localStorage.getItem('userData')) || null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [isAdminLogin, setIsAdminLogin] = useState(userData?.is_admin || false);

  // Sync localStorage updates with state
  const syncTokenAndUserData = () => {
    const storedToken = localStorage.getItem('token');
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    setToken(storedToken);
    setUserData(storedUserData);
    setIsAuthenticated(!!storedToken);
    setIsAdminLogin(storedUserData?.is_admin || false);
  };

  useEffect(() => {
    syncTokenAndUserData();
  }, []);

  // Update state when token changes externally (e.g., during social login)
  useEffect(() => {
    const handleStorageChange = () => {
      syncTokenAndUserData();
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const login = (token, userData) => {
    // Save token and user data to localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify(userData));
    syncTokenAndUserData();
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.setItem("loginViaSocial", false)
    syncTokenAndUserData();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAdminLogin, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for accessing AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
