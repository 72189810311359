import { combineReducers } from "redux";
import counterReducer from "./counterReducer";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import favoriteReducer from "./favoritesReducer";

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  cart: cartReducer,
  favorite: favoriteReducer,
});

export default rootReducer;
