import React from "react";
import Slider from "react-slick";
import Slide1 from "../assets/images/slide1.png";

import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Optional: Import slick theme styles
import { useTranslation } from "react-i18next";

export default function SimpleSlider() {
  const {t} = useTranslation();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
      <div>
        <div className="categorySlider">
          <div className="categorySlider__img">
            <img src={Slide1} alt="" />
          </div>
          <div className="categorySlider__content">
            <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
            <button className="btn">{t("EXPLORE_NOW")}</button>
          </div>
        </div>
      </div>
    </Slider>
  );
}
