// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  backdrop-filter: blur(2px);
}

.loader {
  width: 50px;
  height: 50px;
  border: 3px solid black;
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

p {
  margin-top: 10px;
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,0BAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,6BAAA;EACA,kBAAA;EACA,kCAAA;AACJ;;AAEE;EACE,gBAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAGE;EACE;IACE,uBAAA;EAAJ;EAEE;IACE,yBAAA;EAAJ;AACF","sourcesContent":[".loader-wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.5); // Semi-transparent dark background\n    z-index: 9999;\n    backdrop-filter: blur(2px); // Apply blur effect to the background\n  }\n  \n  .loader {\n    width: 50px;\n    height: 50px;\n    border: 3px solid black; // Light outer ring\n    border-top: 3px solid #ffffff; // White top ring\n    border-radius: 50%;\n    animation: spin 1s linear infinite; // Rotating animation\n  }\n  \n  p {\n    margin-top: 10px;\n    font-size: 16px;\n    color: black; // Text color\n    font-weight: bold;\n    text-align: center;\n  }\n  \n  // Keyframes for rotation animation\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
