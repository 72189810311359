//dependenices
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
//components
import { SignupSchema } from "../Validation/validationSchemas";
import {
  signupUser,
  signupUserWithSocial,
} from "../store/actions/signupActions";
//img / svg
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as Email } from "../assets/svg/Email.svg";
import { ReactComponent as Lock } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../assets/svg/EyeShow.svg";

import { ReactComponent as User } from "../assets/svg/User.svg";
import { ReactComponent as GoogleIcon } from "../assets/svg/googleIcon.svg";
import { ReactComponent as FbIcon } from "../assets/svg/fbIcon.svg";
//styles
import "./Signup.scss";

const Signup = () => {
  const { t } = useTranslation();
  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      nickname: "",
      email: "",
      password: "",
    },
    validationSchema: SignupSchema(t),
    onSubmit: (values) => handleSignup(values),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSignup = async (values) => {
    try {
      setLoading(true);
      const result = await dispatch(signupUser(values));
      if (result?.data?.status) {
        toast.success(t("SIGN_UP_SUCCESS"));

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.error(result?.payload);
      }
    } catch (error) {
      toast.error(t("SIGN_UP_FAILED"));
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = async (data) => {
    try {
      // Prepare user data for the backend
      const token = data.access_token;
      localStorage.setItem("token", token);

      const userInfoResponse = await fetch(
        `${process.env.REACT_APP_GOOGLE_API}?access_token=${token}`
      );

      if (!userInfoResponse.ok) {
        toast.error(t("FAILED_TO_FETCH_USER_INFO"));
      }

      const userInfo = await userInfoResponse.json();

      if (!userInfo.email) {
        toast.error(t("EMAIL_NOT_AVAILABLE_FOR_GOOGLE_lOGIN"));
      }

      const userData = {
        name: userInfo.name,
        email: userInfo.email,
        image: userInfo.picture,
      };
      handleSocialLogin(userData);
    } catch (error) {
      console.error("Google login error:", error);
      toast.error(t("GOOGLE_LOGIN_ERROR"));
    }
  };

  const onLoginStart = useCallback(() => { }, []);

  const onLogoutSuccess = useCallback(() => { }, []);

  const handleSocialLogin = async (data) => {
    let object = {
      username: data.name,
      nickname: data.name,
      email: data.email,
      image: data.image,
    };
    const result = await dispatch(signupUserWithSocial(object));
    if (result.type === "SIGNUP_FAILURE") {
      toast.error(t("ALREADY_REGISTERED_SOCIAL"));
    }
    if (result?.data?.status) {
      toast.success(t("SIGN_UP_SUCCESS_WITH_SOICAL"));

      localStorage.setItem("token", result?.data?.data?.token);
      localStorage.setItem(
        "userData",
        JSON.stringify(result?.data.data?.authData)
      );

      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
  };

  return (
    <div className="Login">
      <div className="Login__inner">
        <a href="#" className="themeBrand">
          <Trendies />
        </a>
        <h2 className="Login__title">{t("SIGN_UP")}</h2>

        <form onSubmit={formik.handleSubmit} className="Login__form">
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("USER_NAME")}
              className="input-with-icon"
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="error-message">{formik.errors.username}</div>
            ) : null}
          </div>
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="nickname"
              name="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("NICK_NAME")}
              className="input-with-icon"
            />
            {formik.touched.nickname && formik.errors.nickname ? (
              <div className="error-message">{formik.errors.nickname}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Email className="icon" />
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("ENTER_EMAIL")}
              className="input-with-icon"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Lock className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("ENTER_PASSWORD")}
              className="input-with-icon"
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              {showPassword ? <EyeShow /> : <EyeHide />}
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="login-btn">
            <button type="submit" className="btn">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div>Loading</div>
                  <div class="loading-dots">
                    <span class="loading">.</span>
                    <span class="loading">.</span>
                    <span class="loading">.</span>
                  </div>
                </div>
              ) : (
                t("CREATE_AN_ACCOUNT")
              )}
            </button>
          </div>
        </form>
        <div className="content-text">
          {t("BY_LOGGING_YOU_AGREE_TO")}
          <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
          <span> {t("TERMS_OF_USE")}</span>
        </div>

        <div className="continue-text">
          <span> {t("OR_CONTINUE_WITH")}</span>
        </div>

        <div className="social-icons">
          <LoginSocialGoogle
            isOnlyGetToken
            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
            scope="profile email"
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => handleGoogleLogin(data)}
            onReject={(err) => {
              // toast.error('GOOGLE_LOGIN_ERROR')
            }}
          >
            <button className="social-button">
              <GoogleIcon />
            </button>
          </LoginSocialGoogle>
          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
            onResolve={({ provider, data }) => {
              data.image = data.picture?.data?.url;
              handleSocialLogin(data);
            }}
            onReject={(err) => {
              console.error(err);
            }}
          >
            <FbIcon />
          </LoginSocialFacebook>
        </div>

        <p className="signup-link">
          {t("HAVE_AN_ACCOUNT")} ?{" "}
          <Link to="/login">
            {t("SIGN_IN")} {t("HERE")}
          </Link>
        </p>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Signup;
