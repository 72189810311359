import React, { useEffect, useState } from "react";
import {
  FaShoppingCart,
  FaTruck,
  FaCalendarAlt,
  FaFileUpload,
  FaArrowDown,
  FaArrowRight,
  FaBox,
} from "react-icons/fa";
import { MdAddAPhoto, MdSwipeRight } from "react-icons/md";
import { IoLogInSharp } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import "./SellerFlow.scss";
import { t } from "i18next";
import { TbTruckDelivery } from "react-icons/tb";
import { FaClipboardList } from "react-icons/fa";
import { ImPointRight } from "react-icons/im";
import { GoClockFill } from "react-icons/go";
import { GrSchedule } from "react-icons/gr";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiCardPickup } from "react-icons/gi";
import { CiViewList } from "react-icons/ci";

const SellerFlow = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    }

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    };
  }, [showModal]);

  const steps = [
    {
      icon: <IoLogInSharp size={35} />,
      label: t("RegisterAccount"),
    },
    {
      icon: <FaShoppingCart size={35} />,
      label: t("CREATE_LIST_SCHEDULE"),
      // subSteps: [
      //   { icon: <FaFileUpload size={20} />, label: t("create") },
      //   { icon: <MdOutlineProductionQuantityLimits size={20} />, label: t("product") },
      //   { icon: <GiCardPickup size={20} />, label: t("pickup") },
      // ],
    },
    {
      icon: <FaCalendarAlt size={35} />,
      label: t("APPROVE_TIMESLOT_LISTING"),
      // subSteps: [
      //   { icon: <CiViewList size={20} />, label: t("listing") },
      //   { icon: <GoClockFill size={20} />, label: t("timeslot") },
      // ],
    },
    {
      icon: <FaTruck size={30} />,
      label: t('PICKUP_SELLER'),
    },
    {
      icon: <MdAddAPhoto size={35} />,
      label: t('AUTHENTICATE_PICTURE'),
      // subSteps: [
      //   { icon: <ImPointRight size={20} />, label: t("authenticate") },
      //   { icon: <MdAddAPhoto size={20} />, label: t("picture") },
      //   { icon: <FaFileUpload size={20} />, label: t("post") },
      // ],
    },
  ];

  return (
    <div className="seller-flow-container">
      <p className="open-modal-button" onClick={() => setShowModal(true)}>
        {t("SHOW_SELLER_FLOW")}
      </p>

      {showModal && (
        <div
          className="modal-overlay"
          onClick={() => setShowModal(false)}
          onTouchMove={(e) => e.preventDefault()}
        >
          <div
            className="seller-flow-modal-content"
            onClick={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.preventDefault()}
          >
            <h2 className="modal-title">{t("Seller_Flow")}</h2>
            <button className="close-modal-button" onClick={() => setShowModal(false)}>
              ✖
            </button>
            <div className={`flow-structure ${isMobile ? "vertical" : "horizontal"}`}>
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <div className="flow-step">
                    <div className="flow-icon">{step.icon}</div>
                    <div className="flow-label">{step.label}</div>
                    <div className="flow-description">{step.description}</div>
                    {step.subSteps && (
                      <div className="sub-steps">
                        {step.subSteps.map((subStep, subIndex) => (
                          <div key={subIndex} className="sub-step-container">
                            <div className="sub-step">
                              <div className="sub-step-icon">{subStep.icon}</div>
                              <div className="sub-step-label">{subStep.label}</div>
                            </div>
                            {subIndex < step.subSteps.length - 1 && (
                              <div className="sub-step-arrow">
                                <FaArrowRight size={12} />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div className="flow-arrow">
                      {isMobile ? <FaArrowDown size={20} /> : <FaArrowRight size={20} />}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerFlow;