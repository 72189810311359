import axios from "axios";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const signupRequest = () => ({
  type: SIGNUP_REQUEST,
});

export const signupSuccess = (message) => ({
  type: SIGNUP_SUCCESS,
  payload: message,
});

export const signupFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

// Async Thunk Action for Signup
export const signupUser = (signupData) => {
  return async (dispatch) => {
    dispatch(signupRequest());

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/signup`,
        signupData
      );
      const message = response.data.message; // Adjust based on your response structure

      dispatch(signupSuccess(message));

      return response;
    } catch (error) {
      console.error("Signup failed:", error);
      const errorMessage = error.response?.data.message || "Signup failed";
      dispatch(signupFailure(errorMessage));
      return { type: SIGNUP_FAILURE, payload: errorMessage };
    }
  };
};


export const signupUserWithSocial = (signupData) => {
  return async (dispatch) => {
    dispatch(signupRequest());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/signupwithsocial`,
        signupData
      );
      const message = response.data.message; // Adjust based on your response structure

      dispatch(signupSuccess(message));

      return response;
    } catch (error) {
      console.error("Signup failed:", error);
      const errorMessage = error.response?.data.message || "Signup failed";
      dispatch(signupFailure(errorMessage));
      return { type: SIGNUP_FAILURE, payload: errorMessage };
    }
  };
};
