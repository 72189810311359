import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./BidModal.scss";

const BidModal = ({ isOpen, onClose, currentPrice, onSubmitBid, loading }) => {
  const { t } = useTranslation();
  const [bidAmount, setBidAmount] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const bid = parseFloat(bidAmount);

    if (bid <= 0) {
      setError(t("ENTER_VALID_BID_AMOUNT"));
      return;
    }

    onSubmitBid(bid);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>

        <h2 className="modal-title">{t("PLACE_YOUR_OFFER")}</h2>

        <div className="current-price">
          <span>{t("CURRENT_PRICE")}</span>
          <span className="price">{currentPrice} MAD</span>
        </div>

        <form onSubmit={handleSubmit} className="bid-form">
          <div className="input-group">
            <label htmlFor="bid">{t("YOUR_OFFER_AMONT")}</label>
            <div className="input-wrapper">
              {/* <span className="currency">MAD</span> */}
              <input
                type="number"
                id="bid"
                value={bidAmount}
                onChange={(e) => setBidAmount(e.target.value)}
                placeholder={t("ENTER_YOUR_BID")}
                required
              />
            </div>
            {error && <span className="error-message">{error}</span>}
          </div>

          <div className="bid-info">
            <p>* {t("ENTER_AMOUNT_WITHOUT_SPACE_OR_COMMA")}</p>
            <p>* {t("MAXIMUM_BID_WILL_BE_THREE_TIMES")}</p>
          </div>
          <div style={{ textAlign: "left" }}>
            <button type="submit" className="submit-button">
              {loading ? t("LOADING") : t("SUBMIT_OFFER")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BidModal;
