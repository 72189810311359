export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART_COUNT = "CLEAR_CART_COUNT";

export const INCREMENT_CART_COUNT = "INCREMENT_CART_COUNT";
export const DECREMENT_CART_COUNT = "DECREMENT_CART_COUNT";

export const clearCart = (count) => ({
  type: CLEAR_CART_COUNT,
  payload: count,
});

// Action to increment cart count
export const incrementCartCount = (quantity = 1) => ({
  type: INCREMENT_CART_COUNT,
  payload: quantity,
});

export const decrementCartCount = (count) => ({
  type: DECREMENT_CART_COUNT,
  payload: count,
});
