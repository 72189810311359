// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal-content {
  background: white;
  border-radius: 10px;
  max-width: 600px;
  height: 250px;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}
.delete-modal-content h2 {
  margin: 0 !important;
}

.modal-actions {
  display: flex;
  gap: 20px;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/utils/ConfirmDeleteProduct/ConfirmDeleteModal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,oBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.delete-modal-content {\n  background: white;\n  border-radius: 10px;\n  max-width: 600px;\n  height: 250px;\n  width: 100%;\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  h2 {\n    margin: 0 !important;\n  }\n}\n\n.modal-actions {\n  display: flex;\n  gap: 20px;\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
