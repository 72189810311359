import React from "react";
import { brandOptions } from "../../common/brand";
import "./FilterPopup.scss";
import { useTranslation } from "react-i18next";

const FilterPopup = ({ isOpen, onClose, selectedBrands, setSelectedBrands }) => {
    const { t } = useTranslation();
    if (!isOpen) return null;

  const handleBrandChange = (brandValue) => {
    if (selectedBrands.includes(brandValue)) {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== brandValue));
    } else {
      setSelectedBrands([...selectedBrands, brandValue]);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h4>{t("FILTER_BY_BRAND")}</h4>
        <button className="close-button" onClick={onClose}>
          ×
        </button>        <ul>
          {brandOptions?.map((brand) => (
            <li key={brand.value}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedBrands?.includes(brand.value)}
                  onChange={() => handleBrandChange(brand.value)}
                />
                {brand.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterPopup;
