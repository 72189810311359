import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import Slide1 from "../assets/images/slide1.png";
import "./FavoriteProducts.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [productsInCart, setProductsInCart] = useState([]);

  // Sample favorite products data
  const favoriteProducts = [
    { id: 1, title: "Product A", category: "electronics" },
    { id: 2, title: "Product B", category: "fashion" },
    { id: 3, title: "Product C", category: "home" },
    { id: 4, title: "Product D", category: "electronics" },
    { id: 5, title: "Product E", category: "fashion" },
  ];

  // Filtered products based on search and selected filter
  const filteredProducts = favoriteProducts.filter((product) => {
    const matchesSearch = product.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "all" || product.category === filter;
    return matchesSearch && matchesFilter;
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchProductsInCart = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        setProductsInCart(response.data.data);
      } else {
        setProductsInCart([]);
      }
    } catch (error) {
      console.error("Error fetching favorite products:", error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      if (productId !== "") {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cart/${productId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200) {
          toast.success(t(response.data.message));
          fetchProductsInCart();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductsInCart();
  }, []);

  return (
    <div className="main-Wrapper">
      <ToastContainer />
      <div className="sectionHeader">
        <h3>{t("PRODUCTS_IN_CART")}</h3>
      </div>
      <div className="favorite-products-container">
        <div className="search-container">
          <Search className="icon" />
          <input
            type="text"
            placeholder={t("SEARCH_PRODUCT")}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="FilterIcon">
            <Filter />
          </span>
        </div>

        <div className="product-list">
          {productsInCart?.length > 0 ? (
            productsInCart.map(({ product }) => (
              <>
                <div className="productItem" key={product.id}>
                  <div className="productItem__img">
                    <img
                      src={product.productImage[0]}
                      alt={product.title}
                      className="product-image"
                    />
                    <HeartFill className="icon" />
                  </div>
                  <div className="titlePrice">
                    <h4>{product.title}</h4>
                    <h4>{product.price} MAD </h4>
                  </div>
                  <p style={{ fontSize: 15 }}>{product.description}</p>
                  <div className="btnPart seller-button">
                    <button
                      className="btn"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      {t("VIEW_DETAILS")}
                    </button>
                    <button
                      className="btn"
                      onClick={() => handleRemoveFromCart(product.id)}
                    >
                      {t("REMOVE_FROM_CART")}
                    </button>
                  </div>
                </div>
              </>
            ))
          ) : (
            <p>{t("NO_PRODUCTS_FOUND")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartPage;
