import React from "react";
import Slider from "react-slick";
import Card1 from "../assets/images/card1.png";

function CardSlider() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
      <div>
        <div className="CardSlider__inner">
          <img src={Card1} alt="" />
        </div>
      </div>
    </Slider>
  );
}

export default CardSlider;
