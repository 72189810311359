import * as Yup from "yup";

export const loginValidationSchema = (t) =>
  Yup.object().shape({
    password: Yup.string()
      .min(6, t("PASSWORD_ATLEAST_SIX_CHAR"))
      .required(t("PASSWORD_REQUIRED")),
    nickname: Yup.string().required(t("NICKNAME_REQUIRED")),
  });

export const SignupSchema = (t) =>
  Yup.object().shape({
    username: Yup.string()
      .min(3, t("USER_NAME_ATLEATST_3_CHAR"))
      .max(20, t("USER_NAME_NOT_MORE_THAN_20_CHAR"))
      .required(t("USER_NAME_REQUIRED")),

    nickname: Yup.string().required(t("NICK_NAME_REQUIRED")),

    email: Yup.string()
      .email(t("INVAILD_EMAIL_FORMAT"))
      .required(t("EMAIL_REQUIRED")),

    password: Yup.string()
      .min(6, t("PASSWORD_ATLEAST_SIX_CHAR"))
      .required(t("PASSWORD_REQUIRED")),
  });

export const SellerProfileValidationSchema = (t) =>
  Yup.object().shape({
    fullName: Yup.string().required(t("FULL_NAME_REQUIRED")),

    emailAddress: Yup.string()
      // .email(t("INVALID_EMAIL_FORMAT"))
      .required(t("EMAIL_REQUIRED")),

    phoneNumber: Yup.string()
      // .matches(/^[0-9]+$/, t("PHONE_NUMBER_INVALID"))
      .required(t("PHONE_NUMBER_REQUIRED")),

    password: Yup.string()
      .min(6, t("PASSWORD_ATLEAST_SIX_CHAR"))
      .required(t("PASSWORD_REQUIRED")),

    governmentId: Yup.mixed().required(t("GOVERNMENT_ID_REQUIRED")),

    dateOfBirth: Yup.date().required(t("BIRTH_DATE_REQUIRED")),
    // .max(new Date(), t("BIRTH_DATE_PAST")),

    residentialAddress: Yup.string().required(
      t("RESIDENTIAL_ADDRESS_REQUIRED")
    ),

    showroomAddress: Yup.string().required(t("SHOW_ROOM_ADDRESS_REQUIRED")),

    sellerType: Yup.string().required(t("SELLER_TYPE_REQUIRED")),

    accountHolderName: Yup.string().required(t("ACCOUNT_HOLDER_NAME_REQUIRED")),

    bankName: Yup.string().required(t("BANK_NAME_REQUIRED")),

    IBAN: Yup.string()
      // .matches(/^([A-Z]{2}[0-9]{2})(?=\w{11,34}$)/, t("IBAN_INVALID"))
      .required(t("IBAN_REQUIRED")),

    // taxIdentificationNumber: Yup.string().when("sellerType", {
    //   is: "Business",
    //   then: Yup.string().required(t("TAX_ID_REQUIRED")),
    //   otherwise: Yup.string().notRequired(),
    // }),

    // taxIdentificationNumber: Yup.string().required(t("TAX_ID_REQUIRED")),

    // companyName: Yup.string().required(t("COMPANY_NAME_REQUIRED")),

    // websiteOrSocialMedia: Yup.string().url(t("WEBSITE_SOCIALMEDIA")),

    taxIdentificationNumber: Yup.string().when(
      "sellerType",
      (sellerType, schema) =>
        sellerType === "Business"
          ? schema.required(t("TAX_ID_REQUIRED"))
          : schema.notRequired()
    ),

    companyName: Yup.string().when("sellerType", (sellerType, schema) =>
      sellerType === "Business"
        ? schema.required(t("COMPANY_NAME_REQUIRED"))
        : schema.notRequired()
    ),

    websiteOrSocialMedia: Yup.string().when(
      "sellerType",
      (sellerType, schema) =>
        sellerType === "Business"
          ? schema.url(t("WEBSITE_SOCIALMEDIA"))
          : schema.notRequired()
    ),

    productCategories: Yup.array().min(1, t("PRODUCT_CATEGORY_REQUIRED")),

    termsAccepted: Yup.boolean().oneOf([true], t("TERMS_ACCEPTANCE_REQUIRED")),

    privacyPolicyAccepted: Yup.boolean().oneOf(
      [true],
      t("PRIVACY_POLICY_ACCEPTANCE_REQUIRED")
    ),

    previousSalesExperience: Yup.boolean().oneOf(
      [true],
      t("PREVIOUS_SALES_EXPERIENCE_REQUIRED")
    ),

    authenticationProcess: Yup.string().required(
      t("AUTHENTICATION_PROCESS_REQUIRED")
    ),

    // profilePhoto: Yup.mixed().notRequired(),
  });
