import React, { useEffect, useState, useTransition } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AuthResponse.scss";
import { useTranslation } from "react-i18next";

// Email Verification Component
const VerifyEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUserEmail = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/verify/${token}`
        );
        console.log("response", response);
        setMessage(response.data.msg);

        setTimeout(() => {
          navigate("/login");
        }, 3000); // Redirect to login after 3 seconds
      } catch (error) {
        setMessage(error.response?.data?.message || "Verification failed");
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    };
    verifyUserEmail();
  }, [token, navigate]);

  return (
    <div className="auth-response">
      {loading ? (
        <div className="loading-container">
          <div className="loading-message">
            <span className="loading-title">{t("VERIFY_ACCOUNT")}</span>
            <div className="loading-dots">
              <span className="loading">.</span>
              <span className="loading">.</span>
              <span className="loading">.</span>
            </div>
          </div>
        </div>
      ) : (
        <p className="response-message">{message}</p>
      )}
    </div>
  );
};

// Account Decline Component
const DeclineAccount = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const declineUserAccount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/decline/${token}`
        );
        setMessage(response.data.msg);
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 3000); // Redirect to login after 3 seconds
      } catch (error) {
        setMessage(error.response?.data?.msg || "Decline process failed");
        setLoading(false);
      }
    };
    declineUserAccount();
  }, [token, navigate]);

  return (
    <div className="auth-response">
      {loading ? <p>Loading...</p> : <p>{message}</p>}
    </div>
  );
};

export { VerifyEmail, DeclineAccount };
