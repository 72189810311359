import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { ReactComponent as Watch } from "../assets/svg/watch.svg";
import { ReactComponent as Jewelry } from "../assets/svg/jewelry.svg";
import { ReactComponent as Bag } from "../assets/svg/bag.svg";
import { ReactComponent as Shoes } from "../assets/svg/shoes.svg";
import { ReactComponent as Accessories } from "../assets/svg/accessories.svg";

import { ReactComponent as Gucci } from "../assets/svg/gucci.svg";
import { ReactComponent as LV } from "../assets/svg/lv.svg";
import { ReactComponent as Prada } from "../assets/svg/prada.svg";
import { ReactComponent as Hermes } from "../assets/svg/hermes.svg";
import { ReactComponent as Dior } from "../assets/svg/dior.svg";
import { ReactComponent as Female } from "../assets/svg/Female.svg";
import { ReactComponent as Male } from "../assets/svg/Male.svg";
import { ReactComponent as Enfants } from "../assets/svg/Enfants.svg";

import { ReactComponent as UploadIcon } from "../assets/svg/UploadIcon.svg";
import { ReactComponent as DismissCircle } from "../assets/svg/DismissCircle.svg";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddProduct.scss";
import i18n from "../i18n";
import InvoiceBreakdown from "./SellerProfile/InvoiceBreakdoen";
import { calculateFees } from "./PurchaseProduct/utils/FeeCalculator";

const AddProduct = () => {

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    category: null,
    brand: null,
    title: "",
    price: "",
    description: "",
    pictureOfItem: [],
    document: "",
    serialNumber: `SN-${Date.now()}`, // Unique serial number
    gender: null,
  });

  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  const currentLanguage = i18n.language;
  const [authImage, setAuthImage] = useState(false);
  const [fileItems, setFileItems] = useState([]);
  const [showFileItems, setShowFileItems] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let fileInputRef = useRef(null);
  let imageInputRef = useRef(null);

  const categoryOptions = [
    { value: "watches", label: t("Watches"), icon: <Watch /> },
    { value: "jewelry", label: t("Jewelry"), icon: <Jewelry /> },
    { value: "bags", label: t("Bags"), icon: <Bag /> },
    { value: "shoes ", label: t("Shoes"), icon: <Shoes /> },
    { value: "accessories", label: t("Accessories"), icon: <Accessories /> },
  ];

  const brandOptions = [
    { value: "gucci", label: "Gucci", icon: <Gucci /> },
    { value: "lv", label: "LV", icon: <LV /> },
    { value: "prada", label: "Prada", icon: <Prada /> },
    { value: "hermes ", label: "Hermes", icon: <Hermes /> },
    { value: "dior", label: "Dior", icon: <Dior /> },
  ];
  const genderOption = [
    { value: "female", label: t("Female"), icon: <Female /> },
    { value: "male", label: t("Male"), icon: <Male /> },
    { value: "kids", label: t("Kids"), icon: <Enfants /> },
  ];

  // Format the option label to include the icon
  const formatOptionLabel = ({ label, icon }) => (
    <div className="MenuIcon">
      {icon}
      {label}
    </div>
  );

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption,
    });
    setErrors({ ...errors, category: "" });
  };

  const handleChangeBrand = (selectedOption) => {
    setFormData({
      ...formData,
      brand: selectedOption,
    });
    setErrors({ ...errors, brand: "" });
  };
  const handleChangeGender = (selectedOption) => {
    setFormData({
      ...formData,
      gender: selectedOption,
    });
    setErrors({ ...errors, gender: "" });
  };

  const handleInputChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handlePriceInput = (e) => {
    const { value } = e.target;
    const numericValue = parseInt(value, 10);

    if (/^\d*$/.test(value)) {
      if (numericValue >= 1000 || value === "") {
        setFormData({ ...formData, price: value });
        setErrors({ ...errors, price: "" });
      } else {
        setFormData({ ...formData, price: value });
        setErrors({ ...errors, price: t("PRICE_VALID_ERROR") });
      }
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    if (files.length) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const validImages = files.filter((file) =>
        validTypes.includes(file.type)
      );

      if (validImages.length) {
        const newPreviews = validImages.map((file) =>
          URL.createObjectURL(file)
        );

        setFormData({
          ...formData,
          pictureOfItem: [...formData.pictureOfItem, ...validImages],
        });
        setErrors({ ...errors, pictureOfItem: "" });
        setFileItems((prev) => [...prev, ...newPreviews]);
        setShowFileItems(true);
      } else {
        // toast.error(t("PLEASE_UPLOAD_VALID_IMAGE"));
        setErrors({ ...errors, pictureOfItem: t("PLEASE_UPLOAD_VALID_IMAGE") });
      }
    }
  };

  const handleVerificationImageChange = (e) => {
    const file = e.target.files[0];

    // Check for file type and size for verification image
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        setVerificationError(
          `Please upload a valid verification image file (jpeg, png, gif).`
        );
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        // 2MB size limit
        setVerificationError("Verification image size must be less than 2MB.");
        return;
      }

      setVerificationError("");
      setErrors({ ...errors, document: "" });
      setFormData({
        ...formData,
        document: file,
      });
    }
  };

  const handleDocumentUpload = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB max size
      const minSize = 10 * 1024; // 10KB min size

      if (file.size < minSize) {
        setErrors({ document: t("FILE_SIZE_MIN_ERROR") });
        return;
      } else if (file.size > maxSize) {
        setErrors({ document: t("FILE_SIZE_MAX_ERROR") });
        return;
      }

      if (file.type !== "application/pdf") {
        setErrors({ document: t("FILE_VALID_ERROR") });
        return;
      }

      setFormData({ ...formData, document: file });
      setAuthImage(true);

      setErrors({ ...errors, document: "" });
    }
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.category) {
      newErrors.category = t("PLEASE_SELECT_A_CATEGORY");
      // toast.error(t("PLEASE_SELECT_A_CATEGORY"));
    }
    if (!formData.brand) {
      newErrors.brand = t("PLEASE_SELECT_A_BRAND");
      // toast.error(t("PLEASE_SELECT_A_BRAND"));
    }
    if (!formData.gender) {
      newErrors.gender = t("PLEASE_SELECT_A_GENDER");
      // toast.error(t("PLEASE_SELECT_A_GENDER"));
    }
    if (!formData.title) {
      newErrors.title = t("PLEASE_ENTER_A_TITLE");
      // toast.error(t("PLEASE_ENTER_A_TITLE"));
    }
    if (!formData.price || isNaN(formData.price)) {
      newErrors.price = t("PLEASE_ENTER_A_VALID_PRICE");
      // toast.error(t("PLEASE_ENTER_A_VALID_PRICE"));
    }
    if (formData.price < 1000) {
      newErrors.price = t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000");
      // toast.error(t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000"));
    }
    if (!formData.description) {
      newErrors.description = t("PLEASE_ENTER_A_DESCRIPTION");
      // toast.error(t("PLEASE_ENTER_A_DESCRIPTION"));
    }
    if (formData.pictureOfItem?.length === 0) {
      newErrors.pictureOfItem = t("PLEASE_UPLOAD_A_PRODUCT_IMAGE");
      // toast.error(t("PLEASE_UPLOAD_A_PRODUCT_IMAGE"));
    }
    // if (!formData.document) {
    //   newErrors.document = t("PLEASE_UPLOAD_A_VERIFICATION_IMAGE");
    //   toast.error(t("PLEASE_UPLOAD_A_VERIFICATION_IMAGE"));
    // }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    handleButtonClick()
    if (validateForm()) {
      const formDatas = new FormData();

      formDatas.append("category", formData.category?.value || "");
      formDatas.append("brand", formData.brand?.value || "");
      formDatas.append("title", formData.title || "");
      formDatas.append("price", formData.price || 0);
      formDatas.append("description", formData.description || "");
      formDatas.append("serialNumber", formData.serialNumber || "");
      formDatas.append("gender", formData.gender?.value || "");

      if (formData?.document) {
        formDatas?.append("document", formData.document);
      }

      formData?.pictureOfItem?.forEach((image) => {
        formDatas?.append(`productImage`, image);
      });

      try {
        setIsLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/products`,
          formDatas,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.status === 200) {
          toast.success(t("PRODUCT_ADD_SUCCESS"));

          setFormData({
            category: null,
            brand: null,
            title: "",
            price: "",
            description: "",
            pictureOfItem: [],
            document: "",
            serialNumber: `SN-${Date.now()}`,
            gender: "",
          });
          setFileItems([]);
          setAuthImage(false);
          setShowFileItems(false);
          navigate("/")
        }
      } catch (error) {
        console.error(error);
        toast.error(t("PRODUCT_ADD_FAIL"));
      } finally {
        setIsLoading(false)
      }
    }
  };

  useEffect(() => {
    if (isButtonClicked) {
      if (currentLanguage === i18n.language) {
        validateForm();
      }
    }
  }, [i18n.language, isButtonClicked]);

  const [sellerPriceDetails, setSellerPriceDetails] = useState(null)
  const isPremiumListing = false

  useEffect(() => {
    if (formData?.price) {
      const details = calculateFees(Number(formData?.price), isPremiumListing, true);
      setSellerPriceDetails(details);
    }
  }, [formData?.price, isPremiumListing]);

  return (
    <>
      <div className="main-Wrapper">
        <div className="add-product-container">
          <ToastContainer />
          <div className="sectionHeader">
            <h3>{t("ADD_A_PRODUCT_FORM")}</h3>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/home")}
            >
              {t("BACK")}
            </button>
          </div>
          <form onSubmit={handleSubmit} className="productForm">
            <div className="productForm_container">
              <div className="productForm__inner seller-add-payment">
                <div className="form-group">
                  <Select
                    className="customSelect"
                    name="category"
                    options={categoryOptions}
                    value={formData.category}
                    onChange={handleChange}
                    placeholder={t("SELECT_A_CATEGORY")}
                  />
                  {errors.category && <p className="error">{errors.category}</p>}
                </div>

                <div className="form-group">
                  <Select
                    className="customSelect"
                    name="brand"
                    options={brandOptions}
                    value={formData.brand}
                    onChange={handleChangeBrand}
                    placeholder={t("SELECT_A_BRAND")}
                  />
                  {errors.brand && <p className="error">{errors.brand}</p>}
                </div>

                <div className="form-group">
                  <Select
                    className="customSelect"
                    name="gender"
                    options={genderOption}
                    value={formData.gender}
                    onChange={handleChangeGender}
                    placeholder={t("SELECT_A_GENDER")}
                  />
                  {errors.gender && <p className="error">{errors.gender}</p>}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="title"
                    placeholder={t("ENTER_TITLE")}
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                  {errors.title && <p className="error">{errors.title}</p>}
                </div>

                <div className="form-group add-product-description">
                  <textarea
                    name="description"
                    placeholder={t("ENTER_DESCRIPTION")}
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                  {errors.description && (
                    <p className="error">{errors.description}</p>
                  )}
                </div>

                <div className="ImageUpload">
                  <div className="inputUpload">
                    <input
                      type="file"
                      name="image"
                      accept=".pdf,.doc,.docx"
                      onChange={handleDocumentUpload}
                      ref={(input) => (fileInputRef = input)}
                    />
                    <UploadIcon />
                    <p>{t("PHOTOS_OF_AUTHENTICATION_DOCUMENTS")}</p>
                    {errors.document && (
                      <p className="error">{errors.document}</p>
                    )}
                  </div>

                  {authImage && (
                    <div className="imgPreview">
                      <div className="imgPreview__inner">
                        <embed
                          style={{ maxHeight: "fit-ontent" }}
                          src={URL.createObjectURL(formData.document)}
                          type="application/pdf"
                        />
                        <button
                          className="remove-image"
                          onClick={() => {
                            setFormData({ ...formData, document: "" });
                            setAuthImage(false);

                            if (fileInputRef) {
                              fileInputRef.value = "";
                            }
                          }}
                        >
                          <DismissCircle />
                        </button>
                      </div>
                      <div className="imgPreview__content">
                        <p>{t("PHOTOS_OF_AUTHENTICATION_DOCUMENTS")}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="ImageUpload">
                  <div className="inputUpload">
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={(input) => (imageInputRef = input)}
                    />
                    <UploadIcon />
                    <p>{t("PHOTOS_OF_PRODUCT")}</p>
                    {errors.pictureOfItem && (
                      <p className="error">{errors.pictureOfItem}</p>
                    )}
                  </div>

                  {showFileItems && (
                    <>
                      {fileItems?.map((fileUrl, index) => (
                        <div className="imgPreview" key={index}>
                          <div className="imgPreview__inner">
                            <img src={fileUrl} alt={t("UPLOADED_IMAGE")} />
                            <button
                              className="remove-image"
                              onClick={() => {
                                setFileItems(
                                  fileItems.filter((_, i) => i !== index)
                                );
                                setFormData({
                                  ...formData,
                                  pictureOfItem: formData.pictureOfItem.filter(
                                    (_, i) => i !== index
                                  ),
                                });

                                if (imageInputRef) {
                                  imageInputRef.value = "";
                                }
                              }}
                            >
                              <DismissCircle />
                            </button>
                          </div>
                          <div className="imgPreview__content">
                            <p>{`Image ${index + 1}`}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="form-group" >
                <div className="innerInputBox">
                  <div className="">
                    <input
                      style={{ border: "none", height: "5px" }}
                      type="text"
                      name="price"
                      placeholder={t("ENTER_PRICE")}
                      value={formData.price}
                      onChange={handlePriceInput}
                    />
                  </div>
                  <div className="inputRight">
                    <p>MAD</p>
                    <input
                      type="text"
                      name="price"
                      placeholder={t("ENTER_PRICE")}
                      value={"1000"}
                      disabled
                    />
                  </div>
                </div>
                {errors.price && <p className="error">{t(errors.price)}</p>}
                <div>
                  {<InvoiceBreakdown sellerPriceDetails={sellerPriceDetails} />}
                </div>
              </div>
            </div>
            <div className="btnPart">
              <button type="submit" className="btn">
                {
                  isLoading ? "Loading..." : t("ADD_PRODUCT")
                }

              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
