import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import "./FavoriteProducts.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { decrementCartCount } from "../store/actions/cartAction";
import { useDispatch } from "react-redux";
import FilterPopup from "./FilterPopup/FilterPopup";

const CartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [productsInCart, setProductsInCart] = useState([]);
  const [originalProductsInCart, setOriginalProductsInCart] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchProductsInCart = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        const data = response.data.data;
        setProductsInCart(data);
        setOriginalProductsInCart(data);
      }
    } catch (error) {
      // toast.error(t("ERROR_FETCHING_CART_PRODUCTS"));
      console.error("Error fetching cart products:", error);
    }finally {
      setLoading(false)
    }
  };

  const applyFilters = () => {
    if (!Array.isArray(originalProductsInCart)) return;

    let filteredResults = originalProductsInCart;

    // Filter by selected brands
    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter(({ product }) =>
        selectedBrands.includes(product.brand)
      );
    }

    // Filter by search term
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        ({ product }) =>
          product.title.toLowerCase().includes(searchTermLower) ||
          product.brand.toLowerCase().includes(searchTermLower) ||
          product.price.toString().includes(searchTerm)
      );
    }

    setProductsInCart(filteredResults);
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      if (productId !== "") {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cart/${productId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200) {
          // toast.success(t(response.data.message));
          dispatch(decrementCartCount(1));
          fetchProductsInCart();
        }
      }
    } catch (error) {
      toast.error(t("ERROR_IN_REMOVING_FROM_CART"));
      console.error(error);
    }
  };

  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  useEffect(() => {
    fetchProductsInCart();
  }, []);

  // useEffect(() => {
  //   if (searchTerm === "" && selectedBrands.length === 0) {
  //     fetchProductsInCart();
  //   } else {
  //     applyFilters();
  //   }
  // }, [selectedBrands, searchTerm]);

  useEffect(() => {
    applyFilters();
  }, [selectedBrands, searchTerm]);

  return (
    <div className="main-Wrapper">
      <ToastContainer />
      <div className="sectionHeader">
        <h3>{t("PRODUCTS_IN_CART")}</h3>
      </div>
      <div className="favorite-products-container">
        <div className="searchFilterBlock">
          <div className="search-container">
            <Search className="icon" />
            <input
              type="text"
              placeholder={t("SEARCH_PRODUCT")}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span
              className="FilterIcon"
              onClick={() => setShowFilterPopup(!showFilterPopup)}
            >
              <Filter />
            </span>
          </div>
          {selectedBrands.length > 0 ? <span className="filter-count">{selectedBrands.length}</span> : ""}

          <FilterPopup
            isOpen={showFilterPopup}
            onClose={handleCloseBrandFilter}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />
        </div>


        {productsInCart?.length > 0 ? (
          <div className="product-list"> {
            productsInCart.map(({ product }) => (
              <>
                <div className="productItem" key={product.id}>
                  <div className="productItem__img">
                    <img
                      src={product.productImage[0]}
                      alt={product.title}
                      className="product-image"
                    />
                    {/* {product.isFavorite ? (
                      <span
                        className="productItem__img-btn"
                      >
                        <HeartFill className="icon" />
                      </span>
                    ) : (
                      <span
                        className="productItem__img-btn"
                      >
                        <Heart className="icon" />
                      </span>
                    )} */}
                  </div>
                  <div className="titlePrice">
                    <h4>{product.title}</h4>
                    <h4>{product.price} MAD </h4>
                  </div>
                  {/* <p style={{ fontSize: 15 }}>{product.description}</p> */}
                  <div className="btnPart seller-button">
                    <button
                      className="btn"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      {t("VIEW_DETAILS")}
                    </button>
                    <button
                      className="btn"
                      onClick={() => handleRemoveFromCart(product.id)}
                    >
                      {t("REMOVE_FROM_CART")}
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          <p className="noDataBlock">{t("NO_PRODUCTS_FOUND")}</p>
        )}


      </div>
      {
        loading && <div className="loader-wrapper">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      }

    </div>
  );
};

export default CartPage;
