import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useUserSync } from "./userSync";

const container = document.getElementById("root");
const root = createRoot(container);
// const BASE_URL = process.env.REACT_GOOGLE_AUTH_KEY;
// console.log("BASE_URL", BASE_URL);

const AppContent = () => {

  useUserSync();
  return <App />;
};

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider
      clientId={
        "464569510162-shmd3u5j6j4ugderq03in4a4tfspqv72.apps.googleusercontent.com"
      }
    >
      <AppContent />
    </GoogleOAuthProvider>
  </Provider>
);
