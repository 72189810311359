import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addFavorite, clearFavorite, renderFavorite } from "../store/actions/favoritesAction";
import FilterPopup from "./FilterPopup/FilterPopup";

const FavoriteProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [searchTerm, setSearchTerm] = useState("");
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [originalFavoriteProducts, setOriginalFavoriteProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const fetchFavoriteProducts = async () => {
    try {
      setLoading(true)
      const userId = parseInt(userData.id);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        const data = response.data.data;
        setOriginalFavoriteProducts(data);
        setFavoriteProducts(data);
        dispatch(renderFavorite(data?.length));
      } else {
        setOriginalFavoriteProducts([]);
        setFavoriteProducts([]);
      }
    } catch (error) {
      toast.error(t("ERROR_FETCHING_FAVORITE_PRODUCTS"));
      console.error("Error fetching favorite products:", error);
      setOriginalFavoriteProducts([]);
      setFavoriteProducts([]);
    } finally {
      setLoading(false)
    }
  };

  const handleRemoveFavorite = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/${productId}/favorite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(t(response.data.message));
        dispatch(clearFavorite(1));
      }
      fetchFavoriteProducts();
    } catch (error) {
      toast.error(t("ERROR_IN_REMOVING_FROM_FAVORITES"));
      console.error("Error removing favorite:", error);
    }
  };

  const applyFilters = () => {
    if (!Array.isArray(favoriteProducts)) return;

    let filteredResults = [...originalFavoriteProducts];

    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter((item) =>
        selectedBrands.includes(item.product.brand)
      );
    }

    if (searchTerm !== "") {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        (item) =>
          item.product.title.toLowerCase().includes(searchTermLower) ||
          item.product.brand.toLowerCase().includes(searchTermLower) ||
          item.product.price.toString().includes(searchTerm)
      );
    }

    setFavoriteProducts(filteredResults);
  };

  useEffect(() => {
    fetchFavoriteProducts();
  }, []);

  useEffect(() => {
    if (searchTerm === "" && selectedBrands.length === 0) {
      setFavoriteProducts(originalFavoriteProducts);
    } else {
      applyFilters();
    }
  }, [selectedBrands, searchTerm]);

  return (
    <div className="main-Wrapper">
      <ToastContainer />
      <div className="sectionHeader">
        <h3>{t("FAVORITE_PRODUCTS")}</h3>
      </div>

      <div className="favorite-products-container">
        <div className="searchFilterBlock">
          <div className="search-container">
            <Search className="icon" />
            <input
              type="text"
              placeholder={t("SEARCH_PRODUCT")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span
              className="FilterIcon"
              onClick={() => setShowFilterPopup(!showFilterPopup)}
            >
              <Filter />
            </span>
          </div>
          {selectedBrands.length > 0 ? <span className="filter-count">{selectedBrands.length}</span> : ""}

          <FilterPopup
            isOpen={showFilterPopup}
            onClose={handleCloseBrandFilter}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
          />
        </div>

        {favoriteProducts?.length > 0 ? (
          <div className="product-list">{
            favoriteProducts.map(({ product }) => (
              <div className="productItem" key={product.id}>
                <div className="productItem__img">
                  <img
                    src={product.productImage?.[0]}
                    alt={product.title}
                    className="product-image"
                  // onClick={() => navigate(`/product/${product.id}`)}
                  />
                  <span
                    className="productItem__img-btn"
                  >
                    <HeartFill
                      className="icon"
                      onClick={() => handleRemoveFavorite(product.id)}
                    />
                  </span>
                </div>
                <div className="titlePrice">
                  <h4>{product.title}</h4>
                  <h4>{product.price} MAD</h4>
                </div>
                {/* <p>{product.description}</p> */}
                <button
                  className="btn"
                  onClick={() => navigate(`/product/${product.id}`)}
                >
                  {t("VIEW_DETAILS")}
                </button>
              </div>
            ))}</div>
        ) : (
          <p className="noDataBlock">{t("NO_FAVORITE_PRODUCT_FOUND")}</p>
        )}

      </div>
      {
        loading && <div className="loader-wrapper">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      }

    </div>
  );
};

export default FavoriteProducts;
