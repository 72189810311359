import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./buyerChatInterface.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import { useTranslation } from "react-i18next";

const socket = io(process.env.REACT_APP_API_URL);

const BuyerChatInterface = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [productId, setProductId] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOffer = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/buyer-chat/${token}`
        );

        console.log("response", response);
        const { productId, receiverId, sellerId } = response?.data?.data;
        setReceiverId(sellerId);
        setSenderId(receiverId);
        setProductId(productId);

        // Set productId, receiverId, sellerId in state or session storage
        sessionStorage.setItem("productId", productId);
        sessionStorage.setItem("receiverId", receiverId);
        sessionStorage.setItem("sellerId", sellerId);

        // setMessage("Offer Accepted! Redirecting...");
        setLoading(false);
      } catch (error) {
        // setMessage(error.response?.data?.message || `Offer acceptance failed`);
        setLoading(false);
      }
    };

    handleOffer();
  }, [token, navigate]);

  // Join room only when both sender and receiver are selected
  useEffect(() => {
    if (senderId && receiverId) {
      socket.emit("joinRoom", { userId: senderId, receiverId });
      socket.emit("getHistory", { senderId, receiverId, productId });

      // Set up listener for new messages and message history
      socket.on("messageHistory", (history) => {
        setMessages(history);
      });

      socket.on("newMessage", (msg) => {
        console.log("Received newMessage:", msg); // Debugging message
        setMessages((prevMessages) => [...prevMessages, msg]);
      });

      // Cleanup listeners on component unmount or receiver change
      return () => {
        socket.off("messageHistory");
        socket.off("newMessage");
      };
    }
  }, [senderId, receiverId]);

  const handleUserClick = (id) => {
    setReceiverId(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input && senderId && receiverId) {
      const message = { senderId, receiverId, content: input, productId };

      // Emit the message to the server
      socket.emit("chatMessage", message);

      // Optimistically update messages to show instantly
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          senderId,
          receiverId,
          content: input,
          productId,
          timestamp: new Date(),
        },
      ]);

      setInput("");
    }
  };

  const messageEndRef = useRef(null);
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="buyer-chat-app">
      <Header />
      <div className="heading">{t("BUYER_CHAT_INTERFACE")}</div>

      <div className="chat-body">
        <div className="messages">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${
                msg.senderId === senderId ? "outgoing" : "incoming"
              }`}
            >
              <span className="content">{msg.content}</span>
            </div>
          ))}
          <div ref={messageEndRef} />
        </div>

        <form className="chat-input" onSubmit={handleSubmit}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={t("TYPE_MESSAGE_HERE")}
            className="message-input"
          />
          <button type="submit" className="send-btn">
            {t("SEND")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BuyerChatInterface;
