import React, { useState, useEffect } from "react";
import { FiArrowLeft, FiBell } from "react-icons/fi";
import "./ScheduleTime.scss";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/DateFormator";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

const ScheduleTime = ({ isOpen, onClose, onSave }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const timeSlots = {
    morning: ["8H-10H", "10H-12H"],
    afternoon: ["12H-14H", "14H-16H"],
    evening: ["16H-18H", "18H-20H"],
  };

  // Single selection state with complete delivery information
  const [selectedDelivery, setSelectedDelivery] = useState({
    day: "",
    month: "",
    year: "",
    time: "",
  });

  const [days, setDays] = useState([]);

  useEffect(() => {
    const today = new Date();
    const generatedDays = Array.from({ length: 7 }, (_, i) => {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      const formattedDate = formatDate(day, currentLanguage);
      return {
        fullDisplay: formattedDate.fullDisplay,
        day: formattedDate.day,
        month: formattedDate.month,
        year: formattedDate.year,
        dayName: formattedDate.dayName,
      };
    });
    setDays(generatedDays);
  }, [currentLanguage]);

  const handleSlotSelect = (dayInfo, time) => {
    setSelectedDelivery({
      day: dayInfo.day,
      month: dayInfo.month,
      year: dayInfo.year,
      time: time,
    });
  };

  const isSlotSelected = (dayInfo, time) => {
    return (
      selectedDelivery.day === dayInfo.day &&
      selectedDelivery.month === dayInfo.month &&
      selectedDelivery.year === dayInfo.year &&
      selectedDelivery.time === time
    );
  };

  const handleSave = async () => {
    if (!selectedDelivery.time) {
      return toast.error("Please select a delivery TimeSlot");
    }
    onSave(selectedDelivery);
  };

  if (!isOpen) return null;

  return (
    <div className="schedule-modal-overlay">
      <div className="schedule-modal-container">
        <header className="schedule-modal-header">
          {/* <FiArrowLeft className="icon" onClick={onClose} /> */}
          <h2>{t("MY_AVAILABILITY")}</h2>
          <IoClose
            onClick={(e) => {
              e.preventDefault();
              onClose(false);
            }}
            style={{ cursor: "pointer", fontSize: "40px" }}
          />

          {/* <FiBell className="icon" /> */}
        </header>

        <p className="schedule-modal-description">
          {t("INDICATE_AVAILABILITY_FOR_DELIVERY")}
        </p>

        <div className="schedule-modal-time-periods">
          <div></div>
          <div className="period-label">{t("MORNING")}</div>
          <div className="period-label">{t("AFTERNOON")}</div>
          <div className="period-label">{t("EVENING")}</div>
        </div>

        {days.map((dayInfo) => (
          <div key={dayInfo.fullDisplay} className="schedule-modal-day-row">
            <div className="day-label">{dayInfo.fullDisplay}</div>

            {/* Morning slots */}
            <div className="time-slot-group morning">
               <div className="time-slot-group__name">{t("MORNING")}</div>
              {timeSlots.morning.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                >
                  {slot}
                  </button>
              ))}
            </div>

            {/* Afternoon slots */}
            <div className="time-slot-group afternoon">
              <div className="time-slot-group__name">{t("AFTERNOON")}</div>
              {timeSlots.afternoon.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                >
                  {slot}
                </button>
              ))}
            </div>

            {/* Evening slots */}
            <div className="time-slot-group evening">
              <div className="time-slot-group__name">{t("EVENING")}</div>
              {timeSlots.evening.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                >
                  {slot}
                </button>
              ))}
            </div>
          </div>
        ))}

        <button
          className="schedule-modal-save-button"
          onClick={() => handleSave()}
          // disabled={!selectedDelivery.time}
        >
          {t("SAVE")}
        </button>
      </div>
    </div>
  );
};

export default ScheduleTime;