import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./BidModal.scss";
import { toast } from "react-toastify";

const BidModal = ({ isOpen, onClose, currentPrice, onSubmitBid, loading }) => {
  const { t } = useTranslation();
  const [bidAmount, setBidAmount] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = async (e) => {
    e.preventDefault()
    const value = e.target.value;
    setBidAmount(value);
    if (/^\d*\.?\d*$/.test(value)) {
      setError("");
    } else {

      setError(t("PLEASE_ENTER_A_VALID_NUMBER"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (error !== "") {
      toast.error(error)
      return;
    }

    const bid = parseFloat(bidAmount);
    if (isNaN(bid) || bid <= 0) {
      // toast.error(t("ENTER_VALID_BID_AMOUNT"))
      setError(t("ENTER_VALID_BID_AMOUNT"));
      return;
    }

    setError("");
    await onSubmitBid(bid);
    setBidAmount("");

  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2 className="modal-title">{t("PLACE_YOUR_OFFER")}</h2>
        <div className="current-price">
          <span>{t("CURRENT_PRICE")}</span>
          <span className="price">{currentPrice} MAD</span>
        </div>
        <form onSubmit={handleSubmit} className="bid-form">
          <div className="input-group">
            <label htmlFor="bid">{t("YOUR_OFFER_AMOUNT")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                id="bid"
                value={bidAmount}
                onChange={handleInputChange}
                placeholder={t("ENTER_YOUR_BID")}
              />
            </div>
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="bid-info">
            <p>* {t("ENTER_AMOUNT_WITHOUT_SPACE_OR_COMMA")}</p>
            <p>* {t("MAXIMUM_BID_WILL_BE_THREE_TIMES")}</p>
          </div>
          <div style={{ textAlign: "left" }}>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? t("LOADING") : t("SUBMIT_OFFER")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BidModal;
