// components/PriceItem.js

import React from "react";

const PriceItem = ({ label, amount, isPercentage=false }) => (
  <div className="price-item">
    <span>{label}</span>
    <div>
      <span className="price-item-amount">{`${amount.toFixed(0)}${isPercentage ?  "%" : ""}`} MAD</span>
      <h5 className="price-item-label">{`(${label})`}</h5>
    </div>
  </div>
);

export default PriceItem;
