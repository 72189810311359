import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./chat.scss";
import axios from "axios";
import Header from "../Header";
import ProductDetailsModel from "./ProductDetails/ProductDetailsModel";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useSensitivePatterns } from "./ChatUtils/Constants";
import { ReactComponent as DismissCircle } from "../../assets/svg/DismissCircle.svg";
import { ReactComponent as Dot } from "../../assets/svg/dots-three.svg";
import { ReactComponent as Cross } from "../../assets/svg/cross.svg";
import { ReactComponent as Send } from "../../assets/svg/Send.svg";
import { ReactComponent as Attach } from "../../assets/svg/Attach.svg";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const ChatApp = () => {
  const { t } = useTranslation();
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const senderId = userDetails?.id;
  const sensitivePatterns = useSensitivePatterns();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [receiverId, setReceiverId] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [currentPerson, setCurrentPerson] = useState("seller");
  const [activeChat, setActiveChat] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentsUrl, setDocumentsUrl] = useState(null);
  const [
    currentProductSellerBuyerDetails,
    setCurrentProductSellerBuyerDetails,
  ] = useState(null);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOffer = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-user-offer-list/${senderId}`
      );
      const responseReceiverData = response?.data?.data?.receiverChat;
      const responseSellerData = response?.data?.data?.sellerChat;
      setReceivers(responseReceiverData || []);
      setSellers(responseSellerData || []);
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetChat = () => {
    setMessages([]);
    setProductId(null);
    setReceiverId("");
    setActiveChat(null);
    setSelectedFiles([]);
    // Disconnect from current room
    if (senderId && receiverId) {
      socket.emit("leaveRoom", { userId: senderId, receiverId });
    }
  };

  useEffect(() => {
    handleOffer();
  }, [senderId]);
  console.log({ senderId });

  useEffect(() => {
    if (senderId && receiverId) {
      // Leave previous room if any
      socket.emit("leaveRoom", { userId: senderId, receiverId });
      socket.emit("joinRoom", { userId: senderId, receiverId });
      socket.emit("getHistory", { senderId, receiverId, productId });
      const handleMessageHistory = (history) => {
        setMessages(history);
      };

      const handleNewMessage = (msg) => {
        setMessages((prevMessages) => [...prevMessages, msg]);
      };

      socket.on("messageHistory", handleMessageHistory);
      socket.on("newMessage", handleNewMessage);

      return () => {
        socket.emit("leaveRoom", { userId: senderId, receiverId });
        socket.off("messageHistory", handleMessageHistory);
        socket.off("newMessage", handleNewMessage);
      };
    }
  }, [senderId, receiverId, productId]);

  const handleUserClick = (id, pId) => {
    if (id !== receiverId || pId !== productId) {
      setMessages([]);
      setReceiverId(id);
      setProductId(pId);
      setActiveChat({ id, productId: pId });
    }
  };

  const validateMessage = (messageContent) => {
    for (let { pattern, message } of sensitivePatterns) {
      if (
        (typeof pattern === "string" &&
          messageContent.toLowerCase().includes(pattern.toLowerCase())) ||
        (pattern instanceof RegExp && pattern.test(messageContent))
      ) {
        return message;
      }
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    const validationError = validateMessage(trimmedInput);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    if ((trimmedInput || selectedFiles?.length) && senderId && receiverId) {
      const message = {
        senderId,
        receiverId,
        content: trimmedInput,
        files: documentsUrl,
        productId,
        timestamp: new Date(),
      };
      socket.emit("chatMessage", message);
      setMessages((prevMessages) => [...prevMessages, message]);
      setInput("");
      setSelectedFiles([]);
    }
    setDocumentsUrl(null)
  };

  

  const messageEndRef = useRef(null);
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  function getTimeFromTimestamp(timestamp) {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const handleFileChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files); // Convert the FileList to an array
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    if (files.length !== imageFiles.length) {
      toast.error("Only image files are allowed.");
      return
    }

    handleFileUpload(files); // Pass the array to handleFileUpload
    setSelectedFiles(files); // Store the files in state
  };

  const uploadFiles = async (files) => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        console.log("Uploaded file URLs:", response.data.fileUrls);
        const url = response.data.fileUrls[0]
        setDocumentsUrl(url)
        return url; // Return the uploaded file URLs
      } else {
        console.error("Failed to upload files:", response.data.message);
        return null;
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      return null;
    }
  };

  const handleFileUpload = async (files) => {
    const fileUrls = await uploadFiles(files);
    if (fileUrls) {
      console.log("Uploaded files:", fileUrls);
      // Perform additional actions with the uploaded file URLs
    }
  };


  return (
    <div className="">
      <Header />
      <div className="chat-app">
        <div className="sidebar">
          <div className="sidebar_header">
            <div
              onClick={() => {
                setCurrentPerson("seller");
                resetChat();
                setCurrentProductSellerBuyerDetails(null);
              }}
              className={`sidebar_seller sidebar_header_options_common ${currentPerson === "seller" ? "sidebar_active" : ""
                }`}
            >
              {t("SELLER")}
            </div>
            <div
              onClick={() => {
                setCurrentPerson("buyer");
                setCurrentProductSellerBuyerDetails(null);
                resetChat();
              }}
              className={`sidebar_buyer sidebar_header_options_common ${currentPerson === "buyer" ? "sidebar_active" : ""
                }`}
            >
              {t("BUYER")}
            </div>
          </div>

          {loading ? (
            <div className="loading">{t("LOADING")}</div>
          ) : (
            <div className="UserChatList">
              {currentPerson === "seller" ? (
                <>
                  {sellers && sellers.length > 0 ? (
                    <ul>
                      {sellers.map((user) => (
                        <li
                          key={`${user.productId}-${user.receiverId}`}
                          className={`userItem ${activeChat?.id === user.receiverId &&
                            activeChat?.productId === user.productId
                            ? "active"
                            : ""
                            }`}
                          onClick={() => {
                            handleUserClick(user.receiverId, user.productId);
                            setCurrentProductSellerBuyerDetails(user);
                          }}
                        >
                          <img
                            className="userItem__img"
                            src={user?.product?.productImage[0]}
                            alt="Product"
                          />
                          <div className="userItem__info">
                            <h6 className="userItem__name">
                              {user?.receiver?.username?.toUpperCase()}
                              {/* <span className="userItem__time">09:27 AM</span> */}
                            </h6>

                            <p className="userItem__text">
                              {user?.product?.title}
                              {/* {` (${user?.product?.title?.toUpperCase()})`}
                              <span>1</span> */}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="ErrorBlank">{t("NO_CHAT_FOUND")}</div>
                  )}
                </>
              ) : (
                <>
                  {receivers && receivers?.length > 0 ? (
                    <ul>
                      {receivers.map((user) => (
                        <li
                          key={`${user.productId}-${user.sellerId}`}
                          className={`userItem ${activeChat?.id === user.sellerId &&
                            activeChat?.productId === user.productId
                            ? "active"
                            : ""
                            }`}
                          onClick={() => {
                            handleUserClick(user.sellerId, user.productId);
                            setCurrentProductSellerBuyerDetails(user);
                          }}
                        >
                          <img
                            className="userItem__img"
                            src={user?.product?.productImage[0]}
                            alt="Product"
                          />
                          <div className="userItem__info">
                            <h6 className="userItem__name">
                              {user?.receiver?.username?.toUpperCase()}
                              {/* <span className="userItem__time">09:27 AM</span> */}
                            </h6>

                            <p className="userItem__text">
                              {user?.product?.title}
                              {/* {` (${user?.product?.title?.toUpperCase()})`}
                              <span>1</span> */}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="ErrorBlank">{t("NO_CHAT_FOUND")}</div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className={`chat-body ${productId ? "open-chat" : ""}`}>
          {currentProductSellerBuyerDetails && (
            <>
              <div className="chatInnerheader">
                <span className="closeBtn" onClick={() => setProductId(null)}>
                  <Cross />
                </span>
                <div className="chat-body-header main">
                  <div className="header-left" onClick={handleOpenModal}>
                    <img
                      className="chat-product-img"
                      src={
                        currentProductSellerBuyerDetails?.product
                          ?.productImage[0]
                      }
                      alt="Product"
                    />
                    <div className="chat-body-header__info">
                      <div className="chat-body-header__heading">
                        <span className="chat-body-header__title">
                          {currentProductSellerBuyerDetails?.product?.title}
                        </span>
                        <span className="chat-body-header__text">
                          {
                            currentProductSellerBuyerDetails?.product
                              ?.description
                          }
                        </span>
                      </div>
                      <span className="moreBtn">
                        <Dot />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="infoText">{t("VIEW_PRODUCT_DETAILS")}</div>
            </>
          )}
          {console.log("messages", messages)}
          <div className="messages">
            {/* {currentProductSellerBuyerDetails && (
              <p onClick={handleOpenModal} className="btn view-product-details">
                {t("VIEW_PRODUCT_DETAILS")}
              </p>
            )} */}
            {messages?.map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.senderId === senderId ? "outgoing" : "incoming"
                  }`}
              >
                {msg.content && <span className="content">{msg.content}</span>}

                {msg.files && typeof msg.files === "string" && (
                  <div className="file-preview">
                    {(() => {
                      try {
                        const fileUrl = new URL(msg.files); // Validate URL format
                        const extension = fileUrl.pathname.split(".").pop().toLowerCase(); // Extract the file extension

                        if (extension === "pdf") {
                          return (
                            <div className="file-embed-preview">
                              <div className="file-embed-preview__inner">
                                <embed
                                  src={msg.files}
                                  alt="PDF file"
                                  type="application/pdf"
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return <img src={msg.files} alt="ImageFile" />;
                        }
                      } catch (error) {
                        console.error("Invalid file URL:", error);
                        return <span>Invalid file format</span>;
                      }
                    })()}
                  </div>
                )}


                <div className="time">
                  {getTimeFromTimestamp(msg.timestamp)}
                </div>
              </div>
            ))}
            <div ref={messageEndRef} />
          </div>

          <form className="chat-input" onSubmit={handleSubmit}>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={t("TYPE_MESSAGE_HERE")}
              className="message-input"
              disabled={!receiverId}
            />
            <div className="addBtn">
              <input
                type="file"
                id="file-upload"
                multiple
                onChange={(e) => { handleFileChange(e); }}
                style={{ display: "none" }}
                disabled={!receiverId}
              />
              <label
                htmlFor="file-upload"
                className={`attachment-icon ${!receiverId && "disable-icon"}`}
              >
                <Attach />
              </label>
            </div>
            <button
              type="submit"
              className={`btn ${((!input.trim() && !selectedFiles?.length) || !receiverId) &&
                "disable-btn"
                }`}
              disabled={
                (!input.trim() && !selectedFiles?.length) || !receiverId
              }
            >
              <Send />
            </button>
          </form>

          {selectedFiles.length > 0 && (
            <div className="imgPreview" style={{justifyContent: "start"}}>
              {selectedFiles.map((preview, index) => (
                <>
                  <div className="imgPreview__inner">
                    {preview.type === "application/pdf" ? (
                      <embed
                        style={{ maxHeight: "fit-content" }}
                        src={URL.createObjectURL(preview)}
                        type="application/pdf"
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(preview)}
                        alt={t("UPLOADED_IMAGE")}
                      />
                    )}
                    <button
                      className="remove-image"
                      onClick={() => {
                        setSelectedFiles(
                          selectedFiles.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <DismissCircle />
                    </button>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <ProductDetailsModel
        currentProductSellerBuyerDetails={currentProductSellerBuyerDetails}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isChatDescriptions={true}
      />
      <ToastContainer />
    </div>
  );
};

export default ChatApp;
