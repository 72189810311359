import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as Shoues } from "../assets/svg/Shoues.svg";
import { ReactComponent as Jewelry } from "../assets/svg/jewelry.svg";
import { ReactComponent as Bag } from "../assets/svg/bag.svg";
import { ReactComponent as Accessories } from "../assets/svg/accessories.svg";
import { ReactComponent as Watch } from "../assets/svg/watch.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import { ReactComponent as Female } from "../assets/svg/Female.svg";
import { ReactComponent as Male } from "../assets/svg/Male.svg";
import { ReactComponent as Enfants } from "../assets/svg/Enfants.svg";
import { ReactComponent as Edit } from "../assets/svg/Edit.svg";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SimpleSlider from "./Slider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDeleteModal from "../utils/ConfirmDeleteProduct/ConfirmDeleteModal";
import EditProductModal from "../utils/EditProduct/EditProductModal";
import FilterPopup from "./FilterPopup/FilterPopup";
import axios from "axios";
import "./Home.scss";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [genderValue, setGenderValue] = React.useState("female");
  const [activeCategory, setActiveCategory] = useState(t("WATCHES"));
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [sliderVisible, setSliderVisible] = useState({
    men: false,
    women: false,
    kids: false,
  });
  const applied = React.useRef(false);
  const categoryIcons = {
    [t("WATCHES")]: <Watch />,
    [t("JEWELRY")]: <Jewelry />,
    [t("BAGS")]: <Bag />,
    [t("SHOES")]: <Shoues />,
    [t("ACCESSORIES")]: <Accessories />,
  };
  const categories = Object.keys(categoryIcons);

  const handleGenderFilter = (event, newValue) => {
    setGenderValue(newValue);
    applyFilters(newValue, activeCategory);
  };

  const handleCloseBrandFilter = () => {
    getProducts();
    setShowFilterPopup(!showFilterPopup);
    setSelectedBrands([]);
  };

  const handleSearchChange = () => {
    if (searchTerm === "") {
      handleCateogryFilter(activeCategory);
    } else {
      setActiveCategory("");
      setFilterProducts(
        products &&
          products?.length > 0 &&
          products?.filter((item) => {
            const searchTermLower = searchTerm.toLowerCase();

            // Match by title, brand, or price
            return (
              item.title.toLowerCase().includes(searchTermLower) ||
              item.brand.toLowerCase().includes(searchTermLower) ||
              item.price.toString().includes(searchTerm)
            );
          })
      );
    }
  };

  const toggleFavorite = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/${productId}/favorite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(t(response.data.message));
        const updateProductList = (prevProducts) =>
          prevProducts.map((product) =>
            product.id === productId
              ? { ...product, isFavorite: !product.isFavorite }
              : product
          );

        setProducts(updateProductList);
        setFilterProducts(updateProductList);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  useEffect(() => {
    handleSearchChange();
  }, [searchTerm]);

  const toggleSlider = (category) => {
    setSliderVisible((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const userId = userData.id;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products?id=${userId}`
      );
      if (response?.status === 200) {
        const verifiedProducts =
          response?.data?.data?.length > 0 &&
          response?.data?.data?.filter((item) => item.isVerified);
        setProducts(verifiedProducts);
        setFilterProducts(
          Array.isArray(verifiedProducts)
            ? verifiedProducts.filter(
                (item) =>
                  item.category.toLowerCase() === "watches" ||
                  item.category.toLowerCase() === "montres"
              )
            : []
        );

        setActiveCategory(t("WATCHES"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to create a custom tab with an image
  const CustomTab = ({ label, image, value }) => {
    return (
      <Tab
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={image}
              alt={label}
              style={{ width: "20px", height: "20px", marginRight: "8px" }}
            />
            {label}
          </div>
        }
        value={value}
      />
    );
  };

  const handleCateogryFilter = (name) => {
    const lowerCaseName = name.toLowerCase();
    const currentLanguage = i18n.language;

    const frToEn = {
      montres: "watches",
      bijoux: "jewelry",
      sacs: "bags",
      chaussures: "shoes",
      accessoires: "accessories",
    };

    const selectedCategory =
      currentLanguage === "fr" && frToEn[lowerCaseName]
        ? frToEn[lowerCaseName]
        : lowerCaseName;

    setCategoryFilter(selectedCategory);
    setActiveCategory(name);
    applyFilters(genderValue, selectedCategory);
  };

  const applyFilters = (gender, category) => {
    if (!Array.isArray(products)) return;

    let filteredResults = products;

    if (gender) {
      filteredResults = filteredResults.filter(
        (item) => item?.gender?.toLowerCase() === gender?.toLowerCase()
      );
    }

    if (category && category !== "all") {
      filteredResults = filteredResults.filter((item) =>
        item.category.toLowerCase().includes(category.toLowerCase())
      );
    }

    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter((item) =>
        selectedBrands.includes(item.brand)
      );
    }

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        (item) =>
          item.title.toLowerCase().includes(searchTermLower) ||
          item.brand.toLowerCase().includes(searchTermLower) ||
          item.price.toString().includes(searchTerm)
      );
    }

    setFilterProducts(filteredResults);
  };

  const handleBrandFilter = () => {
    applyFilters(genderValue, categoryFilter);
  };

  useEffect(() => {
    if (products.length && !applied.current) {
      applyFilters(genderValue, "watches");
      applied.current = true;
    }
  }, [products]);

  useEffect(() => {
    handleBrandFilter();
  }, [selectedBrands, genderValue]);

  const goToProductDetails = async (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };
  return (
    <div className="home-container">
      <div className="main-Wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <ToastContainer />
          <div className="search-container">
            <Search className="icon" />
            <input
              type="text"
              placeholder={t("SEARCH_PRODUCT")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span
              className="FilterIcon"
              onClick={() => setShowFilterPopup(!showFilterPopup)}
            >
              <Filter />
            </span>
          </div>
        </div>

        <div className="category-buttons">
          <Box sx={{ width: "100%" }}>
            <TabContext value={genderValue}>
              <TabList
                onChange={handleGenderFilter}
                aria-label="lab API tabs example"
              >
                <Tab icon={<Female />} label={t("WOMEN")} value="female" />
                <Tab icon={<Male />} label={t("MEN")} value="male" />
                <Tab icon={<Enfants />} label={t("CHILDREN")} value="kids" />
              </TabList>

              <TabPanel className="tabDetails" value="1">
                <SimpleSlider />
              </TabPanel>
              <TabPanel className="tabDetails" value="2">
                <SimpleSlider />
              </TabPanel>
              <TabPanel className="tabDetails" value="3">
                <SimpleSlider />
              </TabPanel>
            </TabContext>
          </Box>
        </div>

        <div className="products-section">
          <div className="sectionHeader">
            <h3>{t("PRODUCTS")}</h3>
          </div>

          <div className="filterBtn">
            {categories?.map((category) => (
              <button
                key={category}
                className={activeCategory === category ? "active" : ""}
                onClick={() => {
                  handleCateogryFilter(category);
                  setActiveCategory(category);
                }}
              >
                {categoryIcons[category]}
                {category}
              </button>
            ))}
          </div>
          <div className="product-list">
            {filterProducts?.length > 0 ? (
              filterProducts?.map((product) => (
                <div className="productItem" key={product.id}>
                  <div className="productItem__img">
                    <img
                      src={
                        product &&
                        product?.productImage?.length > 0 &&
                        product?.productImage[0]
                      }
                      alt={product.title}
                      className="product-image"
                      onClick={() => goToProductDetails(product.id)}
                    />
                    {userData.id === product?.userId && (
                      <>
                        <Edit
                          className="editIcon"
                          onClick={() => handleEditClick(product)}
                        />
                      </>
                    )}
                    {userData.id !== product?.userId &&
                      (product.isFavorite ? (
                        <HeartFill
                          className="icon"
                          onClick={() => toggleFavorite(product.id)}
                        />
                      ) : (
                        <Heart
                          className="icon"
                          onClick={() => toggleFavorite(product.id)}
                        />
                      ))}
                  </div>
                  <div className="titlePrice">
                    <h4>{product.title}</h4>
                    <h4>{product.price}</h4>
                  </div>
                  <p>{product.description}</p>
                </div>
              ))
            ) : (
              <p>{t("NO_PRODUCTS_FOUND")}</p>
            )}
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        getProducts={getProducts}
      />
      <EditProductModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        product={selectedProduct}
        refreshProducts={getProducts}
      />
      <FilterPopup
        isOpen={showFilterPopup}
        onClose={handleCloseBrandFilter}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
      />
    </div>
  );
};

export default Home;
