// utils/FeeCalculator.js

export const calculateFees = (price, isPremiumListing=false) => {
    let commissionRate = 0;
  
    // Determine commission rate based on price range
    if (price >= 1000 && price < 2000) {
      commissionRate = 0.10;
    } else if (price >= 2000 && price < 5000) {
      commissionRate = 0.15;
    } else if (price >= 5000 && price < 10000) {
      commissionRate = 0.20;
    } else if (price >= 10000 && price < 20000) {
      commissionRate = 0.25;
    } else if (price >= 20000) {
      commissionRate = 0.30;
    }
  
    // Calculate individual fees
    const commission = price * commissionRate;
    const buyerProtectionFee = 50;
    const serviceFee = price * 0.05;
    const deliveryFee = 115;
    const premiumListingFee = isPremiumListing ? 500 : 0;
  
    // Calculate total
    const total = price + commission + buyerProtectionFee + serviceFee + deliveryFee + premiumListingFee;
  
    return {
      price,
      commission,
      buyerProtectionFee,
      serviceFee,
      deliveryFee,
      premiumListingFee,
      total,
    };
  };
  