// OfferResponse.jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./OfferResponse.scss";
import { useTranslation } from "react-i18next";

const RejectOffer = () => {
  const {t} = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const endpoint = "offer-reject";

  useEffect(() => {
    const handleOffer = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${endpoint}/${token}`
        );

        setMessage(response.data.message);
        setLoading(false);

        // Redirect to home after 3 seconds
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      } catch (error) {
        setMessage(error.response?.data?.message || `Offer ${endpoint} failed`);
        setLoading(false);
      }
    };

    handleOffer();
  }, [token, navigate]);

  return (
    <div className="offer-response">
      {loading ? (
        <div className="loading-container">
          <div className="loading-message">
            <span className="loading-title">{endpoint} {t("OFFER")}</span>
            <div className="loading-dots">
              <span className="loading">.</span>
              <span className="loading">.</span>
              <span className="loading">.</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="response-container">
          <p className="response-message">{message}</p>
          <p className="redirect-message">
            {t("REDIRECTING_TO_HOME_PAGE_IN_FEW_SECONDS")}
          </p>
        </div>
      )}
    </div>
  );
};

export default RejectOffer;
