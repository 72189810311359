import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { isAuthenticated, isAdminLogin } = useAuth();

  if (adminOnly) {

    if (isAuthenticated && isAdminLogin) {
      return children;
    }
    return <Navigate to="/login" />;
  }

  if (isAuthenticated && isAdminLogin) {
    return <Navigate to="/admin" />;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
