
export const formatDate = (date, currentLanguage) => {
    const daysOfWeekFR = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const daysOfWeekEN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthsFR = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];
    const monthsEN = ['Jan', 'Fev', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const daysOfWeek = currentLanguage === 'fr' ? daysOfWeekFR : daysOfWeekEN;
    const months = currentLanguage === 'fr' ? monthsFR : monthsEN;
    return {
      dayName: daysOfWeek[date.getDay()],
      day: String(date.getDate()).padStart(2, '0'),
      month: months[date.getMonth()],
      year: date.getFullYear(),
      fullDisplay: `${daysOfWeek[date.getDay()]} - ${String(date.getDate()).padStart(2, '0')} ${months[date.getMonth()]}`
    };
  };