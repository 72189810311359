import { CLEAR_CART_COUNT, DECREMENT_CART_COUNT, INCREMENT_CART_COUNT } from "../actions/cartAction";


// Initial State
const initialState = {
    cartCount: 0,

};

// Reducer Function
const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case INCREMENT_CART_COUNT:
            return {
                ...state,
                cartCount: state.cartCount + action.payload,
            };
        case DECREMENT_CART_COUNT:
            return {
                ...state,
                cartCount: Math.max(state.cartCount - action.payload, 0),
            };
        case CLEAR_CART_COUNT:
            return {
                ...state,
                cartCount: 0,
            };
        default:
            return state;
    }
};

export default cartReducer;
