import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { ReactComponent as Filter } from "../assets/svg/filter.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const FavoriteProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [searchTerm, setSearchTerm] = useState("");
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const fetchFavoriteProducts = async () => {
    try {
      const userId = parseInt(userData.id);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        setFavoriteProducts(response.data.data);
      } else {
        setFavoriteProducts([]);
      }
    } catch (error) {
      console.error("Error fetching favorite products:", error);
      setFavoriteProducts([]);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/${productId}/favorite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(t(response.data.message));
      }
      fetchFavoriteProducts();
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  useEffect(() => {
    fetchFavoriteProducts();
  }, []);

  return (
    <div className="main-Wrapper">
      <ToastContainer />
      <div className="sectionHeader">
        <h3>{t("FAVORITE_PRODUCTS")}</h3>
      </div>

      <div className="favorite-products-container">
        <div className="search-container">
          <Search className="icon" />
          <input
            type="text"
            placeholder={t("SEARCH_PRODUCT")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="FilterIcon">
            <Filter />
          </span>
        </div>

        <div className="product-list">
          {favoriteProducts?.length > 0 ? (
            favoriteProducts.map((favProduct) => (
              <div className="productItem" key={favProduct.productId}>
                <div className="productItem__img">
                  <img
                    src={favProduct.product.productImage?.[0]}
                    alt={favProduct.title}
                    className="product-image"
                    onClick={() => navigate(`/product/${favProduct.productId}`)}
                  />
                  <HeartFill
                    className="icon"
                    onClick={() => handleRemoveFavorite(favProduct.productId)}
                  />
                </div>
                <div className="titlePrice">
                  <h4>{favProduct.title}</h4>
                  <h4>{favProduct.price} MAD</h4>
                </div>
                <p>{favProduct.description}</p>
                <button
                  className="btn"
                  onClick={() => navigate(`/product/${favProduct.productId}`)}
                >
                  {t("VIEW_DETAILS")}
                </button>
              </div>
            ))
          ) : (
            <p>{t("NO_FAVORITE_PRODUCT_FOUND")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FavoriteProducts;
