// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal-content {
  background: white;
  border-radius: 10px;
  max-width: 560px;
  width: calc(100% - 32px);
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
}
@media (max-width: 991px) {
  .delete-modal-content {
    padding: 30px;
  }
}
.delete-modal-content h2 {
  margin: 0 0 25px;
  font-size: 22px;
  line-height: 32px;
  color: #333;
  font-weight: 700;
}
@media (max-width: 991px) {
  .delete-modal-content h2 {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 20px;
  }
}
.delete-modal-content .modal-actions {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
}
.delete-modal-content .modal-actions button {
  min-width: 110px;
}`, "",{"version":3,"sources":["webpack://./src/utils/ConfirmDeleteProduct/ConfirmDeleteModal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAAE;EARF;IASI,aAAA;EAGF;AACF;AAFE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;AAIJ;AAHI;EANF;IAOI,eAAA;IACA,iBAAA;IACA,gBAAA;EAMJ;AACF;AAJE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,sBAAA;AAMJ;AALI;EACE,gBAAA;AAON","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.delete-modal-content {\n  background: white;\n  border-radius: 10px;\n  max-width: 560px;\n  width: calc(100% - 32px);\n  padding: 40px;\n  box-sizing: border-box;\n  text-align: left;\n  @media (max-width: 991px) {\n    padding: 30px;\n  }\n  h2 {\n    margin: 0 0 25px;\n    font-size: 22px;\n    line-height: 32px;\n    color: #333;\n    font-weight: 700;\n    @media (max-width: 991px) {\n      font-size: 18px;\n      line-height: 26px;\n      margin: 0 0 20px;\n    }\n  }\n  .modal-actions {\n    display: flex;\n    gap: 15px;\n    align-items: center;\n    justify-content: start;\n    button {\n      min-width: 110px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
