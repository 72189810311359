import { ReactComponent as Gucci } from "../assets/svg/gucci.svg";
import { ReactComponent as LV } from "../assets/svg/lv.svg";
import { ReactComponent as Prada } from "../assets/svg/prada.svg";
import { ReactComponent as Hermes } from "../assets/svg/hermes.svg";
import { ReactComponent as Dior } from "../assets/svg/dior.svg";

export const brandOptions = [
  { value: "gucci", label: "Gucci", icon: <Gucci /> },
  { value: "lv", label: "LV", icon: <LV /> },
  { value: "prada", label: "Prada", icon: <Prada /> },
  { value: "hermes ", label: "Hermes", icon: <Hermes /> },
  { value: "dior", label: "Dior", icon: <Dior /> },
];
