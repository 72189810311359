import React, { useEffect, useState } from "react";
import { ReactComponent as UserSmall } from "../../assets/svg/UserSmall.svg";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Save } from "../../assets/svg/save.svg";
import { ReactComponent as MailSmall } from "../../assets/svg/MailSmall.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import { ReactComponent as LockIcon } from "../../assets/svg/Lock.svg";
import partners from "../../assets/images/partners.jpg";
import partners2 from "../../assets/images/partners2.jpg";
import partners3 from "../../assets/images/partners3.jpg";
import partners4 from "../../assets/images/partners4.jpeg";
import partners5 from "../../assets/images/partners5.jpeg";
import partners6 from "../../assets/images/partners6.jpg";
import { MdMessage, MdAnnouncement } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDeleteModal from "../../utils/ConfirmDeleteProduct/ConfirmDeleteModal";
import EditProductModal from "../../utils/EditProduct/EditProductModal";
import SoldProductsList from "./SoldProductList";
import axios from "axios";
import "./ProfilePage.scss";
import ResetPassword from "../ResetPassword";
import { ReactComponent as EyeHide } from "../../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../../assets/svg/EyeShow.svg";
import SellerFlow from "../SellerFlow/SellerFlow";

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isLoginViaSocial = localStorage.getItem("loginViaSocial");
  const [soldProducts, setSoldProducts] = useState([]);
  const [genderValue, setGenderValue] = React.useState(t("female"));
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [userDetails, setUserDetails] = useState({
    username: userData?.username || userData?.isSocialName || "",
    nickname: userData?.nickname || "",
    email: userData?.email || "",
  });
  const initialUserDetail = {
    username: userData?.username || userData?.isSocialName || "",
    nickname: userData?.nickname || "",
    email: userData?.email || "",
  };

  const [isEditing, setIsEditing] = useState({
    username: false,
    nickname: false,
    email: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userPassword, setUserPassword] = useState(null);
  const [currentPasswordStatus, setCurrentPasswordStatus] = useState(null)
  const [allSellerProducts, setAllSellerProducts] = useState([])

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleEdit = async (field) => {
    if (isEditing[field]) {
      await handleEditProfile(field);
    }
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleEditProfile = async (field) => {
    const updatedValue = { [field]: userDetails[field] };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update-profile`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response);

      if (response.status === 201) {
        setUserDetails({
          ...initialUserDetail,
          userName: response?.data?.data?.user?.isSocialName,
        });
        return toast.error(
          t("UPDATE_PROFILE")
            .replace("option", response?.data?.data?.option)
            .replace("days", response?.data?.data?.days)
        );
      }

      if (response?.status === 200) {
        toast.success(t("PROFILE_UPDATED_SUCCESS"));
        const updatedUser = response.data?.data?.user;
        localStorage.setItem("userData", JSON.stringify(updatedUser));
      }
    } catch (error) {
      toast.error("Error updating profile!");
      console.error(`Error updating ${field}:`, error);
    }
  };

  const getProducts = async () => {
    getAllSellerProducts()
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        const unverifiedProducts =
          response?.data?.data?.length > 0 &&
          response?.data?.data.filter(
            (item) => !item.isVerified && userData.id === item?.userId
          );
        setProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllSellerProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        const unverifiedProducts =
          response?.data?.data?.length > 0 &&
          response?.data?.data.filter(
            (item) => item.isVerified && userData.id === item?.userId
          );
        setAllSellerProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const getSoldProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sold-product/${userData?.id}`
      );

      if (response?.status === 200) {
        const unverifiedProducts = response?.data?.data?.products;
        setSoldProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProducts();
    getSoldProducts();
    getAllSellerProducts()
    getUserPassword();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function getUserPassword() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/decrypt/${userData?.id}`
      );

      if (response?.status === 200) {
        const decryptedPassword = response?.data?.data?.decryptedPassword;
        setUserPassword(decryptedPassword);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="main-Wrapper">
        <ToastContainer
        />
        <div className="profile-section">
          {userData?.image ? (
            <img src={userData.image} alt="Profile" className="profile-pic" />
          ) : (
            <UserSmall className="profile-pic" />
          )}
          <h2>{userData?.username}</h2>
          {/* <p>{userData?.email}</p> */}
        </div>
        <div className="profileSectionBox">
          <div className="profileSectionBox__inner">
            <h3>{t("ACCOUNT_SETTINGS")}</h3>
            <div className="account-settings">
              <div className="account-detail">
                <UserSmall />
                <div>
                  {isEditing.username ? (
                    <>
                      <input
                        autoFocus={true}
                        type="text"
                        name="username"
                        value={userDetails.username}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            username: e.target.value,
                          })
                        }
                      />
                      <Save
                        onClick={() => toggleEdit("username")}
                        className="editIcon"
                      />
                    </>
                  ) : (
                    <>
                      <span>{userDetails.username}</span>
                      <Edit
                        onClick={() => toggleEdit("username")}
                        className="editIcon"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="account-detail">
                <MailSmall />
                <div>
                  {isEditing?.email ? (
                    <>
                      <input
                        autoFocus={true}
                        type="text"
                        name="email"
                        value={userDetails?.email}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          })
                        }
                      />
                      <Save
                        onClick={() => toggleEdit("email")}
                        className="editIcon"
                      />
                    </>
                  ) : (
                    <>
                      <span>{userDetails?.email}</span>
                      <Edit
                        onClick={() => toggleEdit("email")}
                        className="editIcon"
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="account-detail">
                <UserSmall />
                <div>
                  {isEditing.nickname ? (
                    <>
                      <input
                        autoFocus={true}
                        type="text"
                        name="nickname"
                        value={userDetails?.nickname}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            nickname: e.target.value,
                          })
                        }
                      />
                      <Save
                        onClick={() => toggleEdit("nickname")}
                        className="editIcon"
                      />
                    </>
                  ) : (
                    <>
                      <span>{userDetails?.nickname || t("NOT_CREATED")}</span>
                      <Edit
                        onClick={() => toggleEdit("nickname")}
                        className="editIcon"
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="account-detail">
                <LockIcon className="icon" />
                <div className="account-detail-user-password">
                  {userPassword ? (
                    <>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={userPassword}
                        required
                        placeholder="******"
                        className="input-with-icon"
                        disabled={true}
                      />
                      <span
                        className="EyeIcon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <EyeShow /> : <EyeHide />}
                      </span>
                      <Edit
                        onClick={() => { setCurrentPasswordStatus(userPassword ? "update_password" : "add_password"); setChangePassword(true) }}
                        className="editIcon"
                      />
                    </>
                  ) : (
                    <div className="account-detail-user-password">
                      <p
                        onClick={() => { setChangePassword(true) }}
                        className="add-password"
                      >
                        <p>{t("ADD_PASSWORD")}</p>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="btnPart seller-button">
                {!userData?.isSeller && !userData?.isSellerCreated && (
                  <button
                    className="btn"
                    onClick={() => navigate("/seller-profile")}
                  >
                    {t("CREATE_SELLER_PROFILE")}
                  </button>
                )}
                <button className="btn" onClick={() => handleLogout()}>
                  {t("LOGOUT")}
                </button>
              </div>
            </div>
          </div>

          <div className="profileSectionBox__inner">
            <h3>{t("YOURS_ADS")}</h3>
            <div className="account-settings">
              {products?.length > 0 ? (
                products.map((product) => (
                  <div className="account-detail product" key={product?.id}>
                    <div className="product-image-container">
                      <img
                        src={product?.productImage[0]}
                        alt="product_image"
                        className="product-image"
                      />
                    </div>
                    <div>
                      <span
                        className="product-title"
                        onClick={() => navigate(`/product/${product.id}`)}
                      >
                        {product.title}
                      </span>
                      <div className="product-action">
                        <Edit
                          className="editIcon"
                          onClick={() => handleEditProduct(product)}
                        />
                        <Delete
                          onClick={() => setShowConfirmDelete(product.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>{t("NO_PRODUCTS_FOUND")}</p>
              )}
            </div>
          </div>

          <div className="profileSectionBox__inner">
            <h3>{t("MY_PRODUCTS")}</h3>
            <div className="account-settings">
              {allSellerProducts?.length > 0 ? (
                allSellerProducts?.map((product) => (
                  <div className="account-detail product" key={product?.id}>
                    <div className="product-image-container">
                      <img
                        src={product?.productImage[0]}
                        alt="product_image"
                        className="product-image"
                      />
                    </div>
                    <div>
                      <span
                        className="product-title"
                        onClick={() => navigate(`/product/${product.id}`)}
                      >
                        {product.title}
                      </span>
                      <div className="product-action">
                        <Edit
                          className="editIcon"
                          onClick={() => handleEditProduct(product)}
                        />
                        <Delete
                          onClick={() => setShowConfirmDelete(product.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>{t("NO_PRODUCTS_FOUND")}</p>
              )}
            </div>
          </div>

          <div className="profileSection Box__inner">
            <h3>{t("PURCHASE_HISTORY")}</h3>
            <SoldProductsList soldProducts={soldProducts} t={t} />
          </div>

          <div className="profileSectionBox__inner">
            <h3>{t("SERVICE_CLIENT")}</h3>
            <div className="account-settings">
              <div
                className="account-detail"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/about")}
              >
                <MdAnnouncement />
                <span>{t("ABOUT_TRENDIES")}</span>
              </div>
              <div className="account-detail">
                <MdMessage />
                <span>{t("CHAT_WITH_A_SUPPORT_AGENT")}</span>
              </div>
              {/* <div className="account-detail">
                <MdPhone />
                <span>{t("CONTACT_CUSTOMER_SUPPORT_BY_PHONE")}</span>
              </div> */}
              <div className="account-detail">
                <MailSmall />
                <span>{t("CONTACT_CUSTOMER_SUPPORT_BY_EMAIL")}</span>
              </div>
            </div>
          </div>

          <div className="profileSectionBox__inner partnerBlock">
            <h3>{t("PARTNERS")}</h3>
            <div className="account-settings">
              <a
                href="https://legitapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners} className="img-responsive" />
                </div>
              </a>

              <a
                href="https://mounier-bouvard.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners2} className="img-responsive" />
                </div>
              </a>

              <a
                href="https://www.mydhli.com/global-en/home.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners3} className="img-responsive" />
                </div>
              </a>

              <a
                href="https://www.alyapay.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners5} className="img-responsive" />
                </div>
              </a>

              <a
                href="https://www.cmi.co.ma/fr/solutions-paiement-ecommerce"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners4} className="img-responsive" />
                </div>
              </a>

              <a
                href="https://www.azuelos.ma/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="account-detail"
                  style={{ cursor: "pointer" }}
                // onClick={() => navigate("/about")}
                >
                  <img src={partners6} className="img-responsive" />
                </div>
              </a>
            </div>
          </div>

          <div className="profileSectionBox__inner">
            <h3>{t("ITEMS_SOLD")}</h3>
            <div className="account-settings sold-product-card-setting">
              <p>{t("NO_ARTICLE")}</p>
            </div>
          </div>

          <div className="">
            <h3>{t("Seller_Flow")}</h3>
            <div className="">
              <SellerFlow />
            </div>
          </div>

        </div>
      </div>

      <ConfirmDeleteModal
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        getProducts={getProducts}
      />
      <EditProductModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        product={selectedProduct}
        refreshProducts={getProducts}
        setGenderValue={setGenderValue}
      />
      {changePassword && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              className="close-button"
              onClick={() => setChangePassword(false)}
            >
              &times;
            </button>
            <ResetPassword
              isChangePassword={true}
              currentPasswordStatus={currentPasswordStatus}
              userId={userData?.id}
              setChangePassword={setChangePassword}
              getUserPassword={getUserPassword}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
