import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import Slide1 from "../assets/images/slide1.png";
import { useTranslation } from "react-i18next";
import "./ProductPage.scss";
import axios from "axios";
import { categoryOptions } from "../common/Category";

const ProductPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [products, setProducts] = useState();
  const [filterProducts, setFilterProducts] = useState([]);
  const [filter, setFilter] = useState("All");

  const handleAddProductClick = () => {
    navigate("/add-product"); // Navigate to the add product page
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilter(value);
    if (value === "All") {
      setFilterProducts(products);
    } else
      setFilterProducts(products?.filter((item) => item.category == value));
  };

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        setProducts(response?.data?.data);
        setFilterProducts(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const goToProductDetails = async (productId) => {
    navigate(`/product/${productId}`);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      {/* <header className="header">
        <a href="#" className="themeBrand">
          <ThemeBrand />
        </a>
        <div className="header-icons">
          <button>
            <Globe />
          </button>
          <button>
            <UserProfile />
          </button>
          <button>
            <Menu />
          </button>
        </div>
      </header> */}
      <div className="main-Wrapper">
        <div className="product-page-container">
          <h2>{t("WELCOME_TO_THE_PRODUCT_PAGE")}</h2>
          <div className="sectionHeader">
            <div>
              <h3>{t("PRODUCTS")}</h3>
              <p>{t("HERE_YOU_WILL_FIND_A_VARIETY_OF_PRODUCTS")}</p>
            </div>
            <div className="filter-container">
              <div className="form-group">
                <label htmlFor="category-filter">
                  {t("FILTER_BY_CATEGORY")}:{" "}
                </label>
                <select
                  className="form-select"
                  id="category-filter"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  {/* <option value="All">{t("ALL_CATEGORIES")}</option>
                  <option value="Electronics">{t("ELECTRONICS")}</option>
                  <option value="Fashion">{t("FASHION")}</option>
                  <option value="Home">{t("HOME")}</option> */}
                  <option value="All">{t("ALL_CATEGORIES")}</option>
                  {categoryOptions.map((item) => (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  ))}
                </select>
              </div>
              <button onClick={handleAddProductClick} className="btn">
                {t("ADD_PRODUCT")}
              </button>
            </div>
          </div>
          <div className="sectionHeader">
            <h3>{t("PRODUCT_LIST")}:</h3>
          </div>

          {filterProducts?.length > 0 ? (
            <div className="product-list">
              {filterProducts?.map((product) => (
                <div
                  className="productItem"
                  key={product.id}
                  onClick={() => goToProductDetails(product.id)}
                >
                  <div className="productItem__img">
                    <img
                      src={
                        product &&
                        product?.productImage?.length > 0 &&
                        product?.productImage[0]
                      }
                      alt={product.title}
                      className="product-image"
                    />
                    <span
                      className="productItem__img-btn"
                    >
                      <HeartFill className="icon" />
                    </span>
                  </div>
                  <div className="titlePrice">
                    <h4>{product.title}</h4>
                    <h4>{product.price}</h4>
                  </div>
                  {/* <p>product category</p> */}
                  <div className="btnPart">
                    {/* <button className="btn">{t("MORE_DETAILS")}</button> */}
                    <button className="btn">More Details</button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="noDataBlock">{t("NO_PRODUCTS_FOUND_IN_THIS_CATEGORY")}</p>
          )}

        </div>
      </div>
    </>
  );
};

export default ProductPage;
