import * as React from "react";
import Modal from "@mui/material/Modal";
import Success from "../assets/svg/Success.svg";
import { ReactComponent as ModelClose } from "../assets/svg/ModelClose.svg";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BasicModal({
  open = false,
  onClose,
  title,
  description,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home")
    }, 2000)
  })

  return (
    <>
      {/* <button >Open modal</button> */}
      <Modal
        className="confirmationModel"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style} className="confirmationModel__inner">
            <button onClick={onClose} className="close-btn">
              <ModelClose />
            </button>
            <img src={Success} alt="" />
            <h4 id="modal-modal-title">{title}</h4>
            <p id="modal-modal-description">{description}</p>
          </Box>
        </>
      </Modal>
    </>
  );
}
