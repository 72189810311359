import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "./components/SignIn";
import Signup from "./components/SignUp";
import ProductPage from "./components/ProductPage";
import { AuthProvider } from "./AuthContext";
import AddProduct from "./components/AddProduct";
import FavoriteProducts from "./components/FavoriteProducts";
import Home from "./components/Home";
import "./App.scss";
import Header from "./components/Header";
import ProfilePage from "./components/profile/ProfilePage";
import MessagePage from "./components/MessagePage";
import Payment from "./components/PurchaseProduct/Payment";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ProductDetails from "./components/ProductDetails";
import {
  DeclineAccount,
  VerifyEmail,
} from "./components/auth/VerifyDeclineEmail";
import AcceptOffer from "./components/AcceptOffer";
import RejectOffer from "./components/RejectOffer";
import ChatInterface from "./components/Chat/ChatInterface";
import SellerProfileForm from "./components/SellerProfile/SellerProfileForm";
import AdminDashboard from "./components/Admin/Dashboard/AdminDashboard";
import OfferReceiverChat from "./components/ReceiverChat/OfferReceiverChat";
import BuyerChatInterface from "./components/Chat/BuyerChatInterface";
import NotificationPage from "./components/Notification/NotificationPage";
import CartPage from "./components/CartPage";
import PrivateRoute from "./components/PrivateRoute";
import About from "./components/About/About";
import SearchProduct from "./components/SearchProduct/SearchProduct";

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

// Separate out the content to use useLocation correctly inside Router
function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate("/home", { replace: true });
  //   }
  // }, [location.pathname, navigate]);

  const productPageRegex = /^\/product\/\d+$/;

  // Define the routes where the Header should be displayed
  const showHeaderOnRoutes = [
    "/home",
    "/favourites",
    "/cart",
    "/profile",
    "/messages",
    "/payment",
    "/products",
    "/add-product",
    "/product",
    "/seller-profile",
    "/about",
    "/search-product",
    "/",
  ];
  const shouldShowHeader =
    showHeaderOnRoutes.includes(location.pathname) ||
    productPageRegex.test(location.pathname);

  return (
    <>
      {/* Conditionally render the Header based on the current route */}
      {shouldShowHeader && <Header />}
      <Routes>
        <Route path="/login" element={<SignIn />} /> {/* Default route */}
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute adminOnly={true}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            // <PrivateRoute>
            <Home />
            // </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            // <PrivateRoute>
            <Home />
            // </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              <ProductPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <PrivateRoute>
              <ProductDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-product"
          element={
            <PrivateRoute>
              <AddProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/seller-profile"
          element={
            <PrivateRoute>
              <SellerProfileForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/favourites"
          element={
            <PrivateRoute>
              <FavoriteProducts />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="/about" element={<About />} />
        <Route
          path="/messages"
          element={
            <PrivateRoute>
              <MessagePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/:productId"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify/:token" element={<VerifyEmail />} />
        <Route path="/decline/:token" element={<DeclineAccount />} />{" "}
        <Route path="/offer-accept/:token" element={<AcceptOffer />} />{" "}
        <Route path="/offer-reject/:token" element={<RejectOffer />} />{" "}
        <Route
          path="/offer-accept-chat/:token"
          element={<OfferReceiverChat />}
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatInterface />
            </PrivateRoute>
          }
        />{" "}
        <Route path="/buyer-chat/:token" element={<BuyerChatInterface />} />{" "}
        <Route path="/notification" element={<NotificationPage />} />

        <Route path="/search-product" element={<SearchProduct />} />
      </Routes>
    </>
  );
}

export default App;
