import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductDetails.scss";
import { useTranslation } from "react-i18next";
import ChatWindow from "../utils/ChatWindow/ChatWindow";
import BidModal from "./BidModal";
import { toast, ToastContainer } from "react-toastify";

const ProductDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;
  const [product, setProduct] = useState({});
  const [productImage, setProductImage] = useState();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bidDetails, setBidDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const getProductById = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );
      if (response?.status === 200) {
        const productData = response?.data?.data;

        setBidDetails({
          productId: productData?.id,
          sellerId: productData?.userId,
          productPrice: Number(productData?.price),
          offerAmount: "",
          receiverId: id,
          offerCount: 0,
          offerLimit: 10,
        });

        setProduct(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProductImage = async (image) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/uploads/${image}`
      );
      if (response?.status === 200) {
        setProductImage(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    id && getProductById(id);
  }, [id]);

  useEffect(() => {
    if (product && product?.pictureOfItem?.length > 0)
      getProductImage(product?.pictureOfItem[0]);
  }, [product]);

  if (!product) {
    return <div>{t("LOADING_PRODUCT_DETAILS")}...</div>;
  }

  const handleBidSubmit = (bidAmount) => {
    const createBidData = {
      ...bidDetails,
      offerAmount: bidAmount,
      receiverId: userId,
    };

    const getProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/offer`,
          createBidData
        );
        if (response?.data?.status) {
          toast.success(t("OFFER_CREATED"));
        }
      } catch (error) {
        console.error(error?.response?.data?.data?.message);
        toast.error(error?.response?.data?.data?.message);
      } finally {
        setLoading(false);
        setIsModalOpen(false);
      }
    };
    getProducts();
  };

  const handleAddToCart = async (productId) => {
    const formData = new FormData();
    formData.append("productId", productId || 0);
    formData.append("quantity", quantity || 1);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/add-cart`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        toast.success(t(response.data.message));
      }
    } catch (error) {
      console.error("Error fetching favorite products:", error);
      // setFavoriteProducts([]);
    }
  };

  return (
    <div className="main-Wrapper">
      <div className="productDetail">
        <div className="productDetail__inner">
          <div className="productDetail__img">
            <div className="productImg">
              <img
                src={
                  product && product?.productImage?.length > 0
                    ? product?.productImage[0]
                    : ""
                }
                alt="Product"
              />
            </div>

            <div className="productImgSmall">
              {product?.productImage?.slice(1).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product image ${index + 1}`}
                  className="product-thumbnail"
                />
              ))}
            </div>
          </div>

          <div className="product-info-section">
            <h2 className="product-title">{product?.title}</h2>
            <p className="product-category-brand">
              {t("CATEGORY")}: {product?.category} | {t("BRAND")}:{" "}
              {product?.brand}
            </p>

            <div className="product-description">
              <h4>{t("DESCRIPTION")}</h4>
              <p>{product?.description}</p>
            </div>

            <div className="product-price-section">
              <label className="price-label">{t("SPECIAL_PRICE")}</label>
              <p className="product-price">{product?.price} MAD </p>
            </div>
            {userData.id !== product?.userId && (
              <div className="btnPart">
                <button
                  className="btn add-to-cart-btn"
                  onClick={() => handleAddToCart(product.id)}
                >
                  {t("ADD_TO_CART")}
                </button>
                <button
                  onClick={() => navigate(`/payment/${id}`)}
                  className="btn buy-now-btn"
                >
                  {t("BUY_NOW")}
                </button>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="btn add-to-cart-btn"
                >
                  {t("MAKE_AN_OFFER")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isChatOpen && <ChatWindow onClose={handleToggleChat} />}
      <BidModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentPrice={product?.price}
        onSubmitBid={handleBidSubmit}
        setBidDetails={setBidDetails}
        bidDetails={bidDetails}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
};

export default ProductDetails;
