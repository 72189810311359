// src/components/Header.js
import React, { useEffect, useState } from "react";
import "./Header.scss";

import { RiLoginBoxLine } from "react-icons/ri";
import { ReactComponent as ThemeBrand } from "../assets/svg/ThemeBrand.svg";
import { ReactComponent as Globe } from "../assets/svg/Globe.svg";
import { ReactComponent as User } from "../assets/svg/User.svg";
import { ReactComponent as Menu } from "../assets/svg/Menu.svg";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Siderbar";
import Select from "react-select";
import i18n from "i18next";
import { ReactComponent as Home } from "../assets/svg/Home.svg";
import { ReactComponent as Add } from "../assets/svg/Add.svg";
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as Bag } from "../assets/svg/Shopping Bag.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { ReactComponent as Chat } from "../assets/svg/chat.svg";
import { IoBagHandle, IoNotifications } from "react-icons/io5";
import { GoBell } from "react-icons/go";
import { Box, Dialog, DialogTitle, IconButton, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NotificationPanel from "./Notification/NotificationPanel";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementCartCount,
  incrementCartCount,
  clearCart,
} from "../store/actions/cartAction";
import { useAuth } from "../AuthContext";
import { renderFavorite } from "../store/actions/favoritesAction";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const cartCount = useSelector((state) => state.cart.cartCount);
  const favoriteCount = useSelector((state) => state.favorite.favoriteCount);
  const { logout } = useAuth();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;
  const [isNotification, setIsNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [languageSelect, setLanguageSelect] = useState(false);
  const [value, setValue] = React.useState("1");
  const [activeButton, setActiveButton] = useState("");
  const [isBidCreated, setIsBidCreated] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const languageDataOption = [
    { label: "Français", value: "fr", code: "FR" },
    { label: "English", value: "en", code: "EN" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageDataOption.find((lang) => lang.value === "fr")
  );

  const [isOpen, setIsOpen] = useState(false);
  const handleLanguageClick = () => {
    setIsOpen(!isOpen); // Toggle the dropdown directly
  };

  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      i18n.changeLanguage(selectedOption.value);
      setSelectedLanguage(selectedOption);
      setIsOpen(false);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleOffer = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/check-bid-exists/${userId}`
      );
      const isBidExist = response?.data?.data?.exists;
      setIsBidCreated(isBidExist);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      handleOffer(userId);
    }

  }, []);

  const hanldeLanguageClick = () => {
    setLanguageSelect(!languageSelect);
  };

  async function getNotificationByUserId() {
    try {
      const responseNotification = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/${userId}`
      );
      const notificationData = responseNotification?.data?.data;
      if (responseNotification?.data?.status) {
        setNotifications(notificationData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getCartCountByUserId() {
    try {
      dispatch(clearCart());
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status) {
        if (response?.data?.data.length > 0) {
          let cartCount = response?.data?.data?.length;
          dispatch(incrementCartCount(cartCount));
        } else {
          dispatch(clearCart());
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const fetchFavoriteProducts = async () => {
    try {
      const userId = parseInt(userData.id);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        const data = response.data.data;

        dispatch(renderFavorite(data?.length));
      }
    } catch (error) {
      // toast.error(t("ERROR_FETCHING_FAVORITE_PRODUCTS"));
      console.error("Error fetching favorite products:", error);

    }
  };

  useEffect(() => {
    if (userId) {
      getNotificationByUserId();
      getCartCountByUserId();
      fetchFavoriteProducts()
    }
  }, []);

  return (
    <>
      <header className="header">
        <Link to="/home" className="themeBrand">
          <Trendies />
        </Link>
        <div className="header-icons">
          {isBidCreated && (
            <button
              className="btn border-btn d-sm-none"
              onClick={() => {
                userId ? navigate("/chat") : navigate("/login");
              }}
            >
              <Chat />
            </button>
          )}

          <button
            className="btn border-btn d-sm-none"
            onClick={() => {
              userId
                ? userData?.isSeller && userData?.isSellerCreated
                  ? navigate("/add-product")
                  : !userData?.isSeller && userData?.isSellerCreated
                    ? toast.info(t("WAIT_FOR_SELLER_ACCOUNT_APPROVAL"))
                    : navigate("/profile")
                : navigate("/login");
            }}
          >
            <Add />
          </button>
          <button
            className="btn btn border-btn d-sm-none"
            style={{ position: "relative" }}
            onClick={() => {
              userId ? navigate("/favourites") : navigate("/login");
            }}
          >
            {favoriteCount > 0 ? <HeartFill /> : <Heart />}
            {favoriteCount > 0 && (
              <span className="notification-count">{favoriteCount}</span>
            )}
          </button>
          <button
            className="btn btn border-btn d-sm-none"
            style={{ position: "relative" }}
            onClick={() => {
              userId ? navigate("/cart") : navigate("/login");
            }}
          >
            {cartCount > 0 ? <IoBagHandle /> : <Bag />}
            {cartCount > 0 && (
              <span className="notification-count">{cartCount}</span>
            )}

            {/* {cartItems > 0 && (
              <span className="notification-count">{cartItems}</span>
            )} */}
          </button>
          <button className="btn btn-border" onClick={handleLanguageClick}>
            <Globe />
          </button>

          {isOpen && (
            <div className="select-dropdown">
              <Select
                value={selectedLanguage}
                options={languageDataOption}
                onChange={handleLanguageChange}
                placeholder="Select Language"
                // Customizing how options are rendered
                getOptionLabel={(option) => (
                  <div className="flex items-center">
                    <span className="mr-2">{option.code}</span>
                    <span>{option.label}</span>
                  </div>
                )}
                // Customizing how the selected value is displayed
                formatOptionLabel={(option) => (
                  <div className="flex items-center">
                    {/* <span className="mr-2">{option.code}</span> */}
                    <span>{option.label}</span>
                  </div>
                )}
                autoFocus
                onBlur={() => setIsOpen(false)}
              />
            </div>
          )}

          <button
            className="btn btn border-btn notification-btn"
            onClick={() => {
              userId ? setIsNotification(true) : navigate("/login");
            }}
          >
            {/* <Link to="/notification"> */}
            {notifications?.length > 0 ? <IoNotifications /> : <GoBell />}
            {notifications?.length > 0 && (
              <span className="notification-count">{notifications.length}</span>
            )}
            {/* </Link> */}
          </button>
          {userId ? (
            <button
              className="profileBtn"
              onClick={() => {
                navigate("/profile");
              }}
            >
              {userData?.image ? (
                <img src={userData?.image} className="profile-img" alt="user" />
              ) : (
                <User className="icon" />
              )}
            </button>
          ) : (
            <button
              onClick={() => {
                logout(); navigate("/login");
              }}
              className="btn btn border-btn notification-btn"
            >
              <RiLoginBoxLine style={{ fontSize: "20px" }} />
            </button>
          )}
          {/* ) 
          : (
            <button
              className="btn btn border-btn notification-btn"
              onClick={() => {
                navigate("/login");
              }}
            >
              <RiLoginBoxLine style={{ fontSize: "20px" }} />
            </button>
          )
          } */}

          {/* <button className="btn btn border-btn" onClick={toggleDrawer(true)}>
          <Menu />
        </button> */}
        </div>
        <Sidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />

        {isNotification && (
          <Dialog
            open={isNotification}
            keepMounted
            className="notification-dialog"
            onClose={() => setIsNotification(false)}
            aria-describedby="notification-dialog-description"
            PaperProps={{
              style: {
                margin: "0",
                // marginTop: "80px",
                maxWidth: "450px",
                width: "90%",
                // height: "600px",
                position: "fixed",
                top: "50px",
                right: "30px",
                overflow: "hidden",
                padding: "0",
                borderRadius: "12px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <DialogTitle style={{ padding: "0", marginBottom: "8px" }}>
              <div className="dialog-header">
                <h2
                  style={{
                    margin: "0",
                    fontSize: "18px",
                    padding: "0",
                    fontWeight: "bold",
                  }}
                >
                  Notifications
                </h2>
                <IconButton
                  className="close-btn"
                  onClick={() => setIsNotification(false)}
                >
                  X
                </IconButton>
              </div>
              <Box className="Tabbing">
                <TabContext value={value}>
                  <TabList
                    className="Tabbing__btn"
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label={t("VIEW_ALL")} value="1" />
                    <Tab label={t("MENTIONS")} value="2" />
                  </TabList>
                  <NotificationPanel
                    notifications={notifications}
                    getNotificationByUserId={getNotificationByUserId}
                    setIsNotification={setIsNotification}
                  />
                  <TabPanel value="2">{t("MENTIONS")}</TabPanel>
                </TabContext>
              </Box>
            </DialogTitle>
          </Dialog>
        )}
      </header>
      <div className="bottomMobileIcons">
        <div className="bottomMobileIcons__inner">
          <button
            className={`btn btn border-btn ${activeButton === "home" ? "active" : ""
              }`}
            onClick={() => {
              setActiveButton("home");
              navigate("/home");
            }}
          >
            <Home />
          </button>
          {isBidCreated && (
            <button
              className={`btn btn border-btn ${activeButton === "chat" ? "active" : ""
                }`}
              onClick={() => {
                setActiveButton("chat");
                userId ? navigate("/chat") : navigate("/login");
              }}
            >
              <Chat />
            </button>
          )}
          <button
            className={`btn btn border-btn ${activeButton === "add-product" ? "active" : ""
              }`}
            onClick={() => {
              setActiveButton("add-product");
              userId
                ? userData?.isSeller && userData?.isSellerCreated
                  ? navigate("/add-product")
                  : !userData?.isSeller && userData?.isSellerCreated
                    ? toast.info(t("WAIT_FOR_SELLER_ACCOUNT_APPROVAL"))
                    : navigate("/profile")
                : navigate("/login");
            }}
          >
            <Add />
          </button>
          <button
            className={`btn btn border-btn ${activeButton === "favourites" ? "active" : ""
              }`}
            style={{ position: "relative" }}
            onClick={() => {
              setActiveButton("favourites");
              userId ? navigate("/favourites") : navigate("/login");
            }}
          >
            {favoriteCount > 0 ? <HeartFill /> : <Heart />}
            {favoriteCount > 0 && (
              <span className="notification-count">{favoriteCount}</span>
            )}
          </button>
          <button
            className={`btn btn border-btn ${activeButton === "cart" ? "active" : ""
              }`}
            style={{ position: "relative" }}
            onClick={() => {
              setActiveButton("cart");
              userId ? navigate("/cart") : navigate("/login");
            }}
          >
            {cartCount > 0 ? <IoBagHandle /> : <Bag />}

            {cartCount > 0 && (
              <span className="notification-count">{cartCount}</span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
