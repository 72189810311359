// userSync.js
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Custom hook to handle user data synchronization
export const useUserSync = () => {
  const dispatch = useDispatch();

  const syncUserData = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = localStorage.getItem("token");
      if (userData) {
        const parsedUserData = JSON.parse(userData);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get-user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const freshUserData = response?.data?.data;

        if (response.status === 200) {
          // Update localStorage with fresh data
          localStorage.setItem("userData", JSON.stringify(freshUserData));

          // Update Redux store
          dispatch({
            type: "UPDATE_USER",
            payload: freshUserData,
          });
        } else {
          // Handle error case - could be expired token or invalid user
          localStorage.removeItem("userData");
          dispatch({ type: "LOGOUT" });
        }
      }
    } catch (error) {
      console.error("Error syncing user data:", error);
      // Handle error appropriately
    }
  };

  // Sync on mount and window focus
  useEffect(() => {
    syncUserData();

    // Add focus event listener
    const handleFocus = () => {
      syncUserData();
    };

    window.addEventListener("focus", handleFocus);

    // Cleanup
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return { syncUserData };
};
