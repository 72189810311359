import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HeartFill } from "../../assets/svg/Heartfill.svg";
import { ToastContainer, toast } from "react-toastify";

const SearchProduct = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchProduct, setSearchProduct] = useState([]);

    useEffect(() => {
        try {
            const storedData = localStorage.getItem("searchProduct");
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                console.log("parsedData", parsedData);
                if (Array.isArray(parsedData)) {
                    setSearchProduct(parsedData);
                } else {
                    console.warn("Invalid data format in localStorage for searchProduct");
                    toast.warn(t("INVALID_PRODUCT_DATA"));
                }
            }
        } catch (error) {
            console.error("Error parsing searchProduct from localStorage:", error);
            toast.error(t("ERROR_LOADING_PRODUCTS"));
        }
    }, []);

    useEffect(() => {
        console.log("Updated searchProduct state:", searchProduct);
    }, [searchProduct]);

    return (
        <div className="main-Wrapper">
            <ToastContainer />
            <div className="sectionHeader">
                <h3>{t("SEARCH_PRODUCTS")}</h3>
            </div>

            <div className="favorite-products-container">
                {searchProduct.length > 0 ? (
                    <div className="product-list">
                        {searchProduct.map((product, index) => {

                            return (
                                <div className="productItem" key={product.id || index}>
                                    <div
                                        onClick={() => navigate(`/product/${product.id}`)}
                                        className="productItem__img"
                                    >
                                        <img
                                            src={product.productImage?.[0]}
                                            alt={product.title || t("NO_TITLE")}
                                            className="product-image"
                                        />
                                        {/* <span className="productItem__img-btn">
                                            <HeartFill className="icon" />
                                        </span> */}
                                    </div>
                                    <div className="titlePrice">
                                        <h4>{product.title || t("NO_TITLE")}</h4>
                                        <h4>{product.price ? `${product.price} MAD` : t("NO_PRICE")}</h4>
                                    </div>
                                    <button
                                        className="btn"
                                        onClick={() => navigate(`/product/${product.id}`)}
                                    >
                                        {t("VIEW_DETAILS")}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p className="noDataBlock">{t("NO_PRODUCT_FOUND")}</p>
                )}
            </div>
        </div>
    );
};

export default SearchProduct;
