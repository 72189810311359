import axios from "axios";
import React, { useEffect, useState } from "react";
import Check from "../../../assets/images/Check.png";
import Close from "../../../assets/images/Close.png";
import Info from "../../../assets/images/Info.png";
import Approve from "../../../assets/images/Approve.png";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Users = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUser();
  }, []);

  const handleApprove = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/update-seller-status/${id}`
      );
      if (response?.status === 200) {
        getAllUser();
      }
    } catch (error) {
      toast.error("Error in update seller status!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllUser = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-all-user`
      );
      if (response?.status === 200) {
        const allUsers = response?.data?.data?.filter((user) => !user?.is_admin);
        setUsers(allUsers);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="users-table">
      <h2>{t("USER_LIST")}</h2>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("NAME")}</th>
              <th>{t("EMAIL")}</th>
              <th>{t("ACCOUNT_STATUS")}</th>
              <th>{t("SELLER_STATUS")}</th>
              <th>{t("ACTION")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td
                  className={
                    user.isVerified ? "active_product" : "inactive_product"
                  }
                >
                  {user.isVerified ? "Active" : "Inactive"}
                </td>
                <td
                  className={
                    user.isSeller ? "active_product" : "inactive_product"
                  }
                >
                  {!user.isSellerCreated && !user.isSeller ? (
                    t("NOT_CREATED")
                  ) : user.isSeller ? (
                    "Active"
                  ) : (
                    <p style={{ color: "#feb906" }}>{t("CREATED")}</p>
                  )}
                </td>
                <td>
                  <div
                    className={`actionBtn ${user?.isSeller ? "Approved" : "Pending"
                      }`}
                    disabled={user.isSeller}
                  >
                    {!user?.isVerified ? (
                      <>
                        <button className="infoBtn">
                          <img src={Info} alt="" />
                        </button>
                        <div className="infoText">
                          {t("PLEASE_VERIFY_ACCOUNT")}
                        </div>
                      </>
                    ) : !user?.isSellerCreated ? (
                      <>
                        <button className="infoBtn">
                          <img src={Info} alt="" />
                        </button>
                        <div className="infoText">
                          {t("SELLER_ACCOUNT_NOT_CREATED")}
                        </div>
                      </>
                    ) : user?.isSeller ? (
                      <>
                        <button className="infoBtn">
                          <img src={Approve} alt="" />
                        </button>
                        <div className="infoText">{t("SELLER_ACTIVE")}</div>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <button
                            className="infoBtn"
                            onClick={() => handleApprove(user?.id)}
                          >
                            {/* Accept */}
                            <img src={Check} alt="" />
                          </button>
                          <div className="infoText">{t("ACCEPT_SELLER")}</div>
                        </div>

                        <div>
                          <button className="infoBtn">
                            <img src={Close} alt="" />

                            {/* Reject */}
                          </button>
                          <div className="infoText">{t("REJECT_SELLER")}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
