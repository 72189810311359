import React from "react";
import "./SoldProductList.scss";
import { Link } from "react-router-dom";

const SoldProductsList = ({ soldProducts, t }) => {
  return (
    <div className="sold-products-container">
      {soldProducts && soldProducts.length > 0 ? (
        <div className="product-grid">
          {soldProducts.map((item) => (
            <div key={item.id} className="sold-product-card">
              <Link
                style={{ textDecoration: "none" }}
                to={`/payment/${item?.productId}`}
              >
                <div className="product-header">
                  <div className="product-image">
                    <img
                      src={item.product?.productImage?.[0]}
                      alt={item.product?.title}
                    />
                  </div>
                  <div className="price-status">
                    <p className="total-price">
                      {item.amountCalculated?.total.toFixed(2)} MAD
                    </p>
                    <span
                      className={`payment-status ${
                        item.paymentStatus ? "paid" : "pending"
                      }`}
                    >
                      {item.paymentStatus ? t("PAID") : t("PENDING")}
                    </span>
                  </div>
                </div>
                <div className="product-details">
                  <h3>{item.product?.title}</h3>
                  <p>
                    {item.product?.brand} | {item.product?.category}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>{t("NO_PURCHASE_HISTORY")}</p>
      )}
    </div>
  );
};

export default SoldProductsList;
