// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword {
  max-width: 450px;
  margin: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ForgotPassword__inner {
  padding: 30px 20px;
  box-sizing: border-box;
  width: 100%;
}
.ForgotPassword__title {
  font-size: 30px;
  line-height: 36px;
  color: #333333;
  text-align: center;
  margin: 54px 0 40px;
  font-weight: 700;
}
.ForgotPassword__form {
  padding: 20px;
}
.ForgotPassword__form .form-group svg {
  height: 30px;
  width: 30px;
}
.ForgotPassword__form .form-group input {
  border: none !important;
  border-bottom: 1px solid #333333 !important;
  border-radius: 0;
}
.ForgotPassword__footer {
  margin-top: 0;
}
.ForgotPassword__footer p {
  margin: 40px 0 0;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #333333;
}
.ForgotPassword__footer p a {
  display: inline-block;
  color: #333333;
  font-weight: 600;
  text-decoration: none;
}
.ForgotPassword__footer p a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/ForgotPassword.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,kBAAA;EACA,sBAAA;EACA,WAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;AAAM;EACE,YAAA;EACA,WAAA;AAER;AAAM;EACE,uBAAA;EACA,2CAAA;EACA,gBAAA;AAER;AAEE;EACE,aAAA;AAAJ;AACI;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AACN;AAAM;EACE,qBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;AAER;AADQ;EACE,0BAAA;AAGV","sourcesContent":[".ForgotPassword {\n  max-width: 450px;\n  margin: auto;\n  min-height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &__inner {\n    padding: 30px 20px;\n    box-sizing: border-box;\n    width: 100%;\n  }\n  &__title {\n    font-size: 30px;\n    line-height: 36px;\n    color: #333333;\n    text-align: center;\n    margin: 54px 0 40px;\n    font-weight: 700;\n  }\n  &__form {\n    padding: 20px;\n    .form-group {\n      svg {\n        height: 30px;\n        width: 30px;\n      }\n      input {\n        border: none !important;\n        border-bottom: 1px solid #333333 !important;\n        border-radius: 0;\n      }\n    }\n  }\n  &__footer {\n    margin-top: 0;\n    p {\n      margin: 40px 0 0;\n      text-align: center;\n      font-size: 14px;\n      line-height: 17px;\n      font-weight: 400;\n      color: #333333;\n      a {\n        display: inline-block;\n        color: #333333;\n        font-weight: 600;\n        text-decoration: none;\n        &:hover {\n          text-decoration: underline;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
