import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "./EditProductModal.scss";
import { useTranslation } from "react-i18next";
import { brandOptions } from "../../common/brand";
import { categoryOptions } from "../../common/Category";
import { ReactComponent as DismissCircle } from "../../assets/svg/DismissCircle.svg";
import { ReactComponent as UploadIcon } from "../../assets/svg/UploadIcon.svg";
import { toast } from "react-toastify";
import { genderOption } from "../../common/Gender";

const EditProductModal = ({ isOpen, onClose, product, refreshProducts, setGenderValue }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [formData, setFormData] = useState({
    title: product?.title || "",
    description: product?.description || "",
    price: product?.price || 0,
    brand: product?.brand || "",
    gender: product?.gender || "",
    category: product?.category || "",
    productImage: product?.productImage || [],
  });

  useEffect(() => {
    if (isOpen && product) {
      setFormData({
        title: product.title,
        description: product.description,
        price: product.price,
        brand: product.brand,
        gender: product.gender,
        category: product.category,
        productImage: product.productImage || [],
      });
    }
  }, [isOpen, product]);

  const handleInputChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const { name, files } = e.target;

    if (!files || files.length === 0) {
      toast.error(t("NO_FILES_SELECTED"));
      return;
    }

    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    const validImages = Array.from(files).filter((file) =>
      validTypes.includes(file.type)
    );

    if (validImages.length > 0) {
      const newPreviews = validImages.map((file) =>
        URL.createObjectURL(file)
      );

      setFormData((prevState) => ({
        ...prevState,
        productImage: [...prevState.productImage, ...validImages],
      }));
    } else {
      toast.error(t("PLEASE_UPLOAD_VALID_IMAGE"));
    }
  };


  const handleRemoveImage = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      productImage: prevState.productImage.filter((_, i) => i !== index),
    }));
  };

  const handleEditProduct = async () => {

    if (!formData.title) {
      toast.error(t("PLEASE_ENTER_A_TITLE"));
      return;
    }
    if (!formData.description) {
      toast.error(t("PLEASE_ENTER_A_DESCRIPTION"));
      return;
    }
    if (!formData.price || isNaN(formData.price)) {
      toast.error(t("PLEASE_ENTER_A_VALID_PRICE"));
      return;
    }
    if (formData.price < 1000) {
      toast.error(t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000"));
      return;
    }
    if (!formData.brand) {
      toast.error(t("PLEASE_SELECT_A_BRAND"));
      return;
    }
    if (!formData.gender) {
      toast.error(t("PLEASE_SELECT_A_GENDER"));
      return;
    }
    if (!formData.category) {
      toast.error(t("PLEASE_SELECT_A_CATEGORY"));
      return;
    }
    if (!formData.productImage || formData.productImage.length === 0) {
      toast.error(t("PLEASE_UPLOAD_AT_LEAST_ONE_IMAGE"));
      return;
    }

    try {
      const EditedData = new FormData();
      formData?.productImage.forEach((ele) => {
        if (typeof ele === "string" && ele.startsWith("http")) {
          EditedData.append("oldImage", ele);
        }
      });

      EditedData.append("title", formData.title);
      EditedData.append("description", formData.description);
      EditedData.append("price", formData.price);
      EditedData.append("brand", formData.brand);
      EditedData.append("gender", formData.gender);
      EditedData.append("category", formData.category);

      formData.productImage.forEach((file) => {
        EditedData.append("productImage", file);
      });

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/products/${product.id}`,
        EditedData
      );

      if (response.status === 200) {
        toast.success(t("PRODUCT_UPDATE_SUCCESS"));
        setGenderValue(formData?.gender)
        refreshProducts();
        onClose();
      }
    } catch (error) {
      toast.error(t("ERROR_IN_EDIT_PRODUCT"));
      console.error("Error editing product:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modalcontent" onClick={(e) => e.stopPropagation()}>
        <h2>{t("EDIT_PRODUCT")}</h2>
        <div className="form-row">
          <div className="form-group">
            <label>{t("TITLE")}</label>
            <input
              type="text"
              name="title"
              placeholder={t("ENTER_TITLE")}
              value={formData.title}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>{t("PRICE")}</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>{t("DESCRIPTION")}</label>
          <textarea
            name="description"
            placeholder={t("ENTER_DESCRIPTION")}
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-row-three">
          <div className="form-group">
            <label>{t("BRAND")}</label>
            <Select
              className="customSelect"
              name="brand"
              options={brandOptions}
              value={brandOptions.find(
                (option) => option.value === formData.brand
              )}
              onChange={(selectedOption) =>
                setFormData({ ...formData, brand: selectedOption.value })
              }
              placeholder={t("SELECT_A_BRAND")}
            />
          </div>

          <div className="form-group">
            <label>{t("CATEGORY")}</label>
            <Select
              className="customSelect"
              name="category"
              options={categoryOptions.map((option) => ({
                value: t(option.value), // Translate the value
                label: t(option.label), // Translate the label
              }))}
              value={{
                value: t(formData.category), // Translate the current value
                label: t(
                  categoryOptions.find((option) => option.value === formData.category)?.label
                ),
              }}
              onChange={(selectedOption) =>
                setFormData({ ...formData, category: selectedOption.value })
              }
              placeholder={t("SELECT_A_CATEGORY")}
            />

          </div>

          <div className="form-group">
            <label>{t("GENDER")}</label>

            <Select
              className="customSelect"
              name="gender"
              options={genderOption.map((option) => ({
                value: (option.value),
                label: t(option.label),
              }))}
              value={{
                value: (formData.gender),
                label: t(
                  genderOption.find((option) => option.value === formData.gender)?.label
                ),

              }}
              onChange={(selectedOption) => setFormData({ ...formData, gender: selectedOption.value })
              }
              placeholder={t("SELECT_A_GENDER")}
            />
          </div>
        </div>

        <div className="ImageUpload file-preview">
          <div className="inputUpload">
            <input
              type="file"
              name="images"
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />
            <UploadIcon />
            <p>{t("PHOTOS_OF_PRODUCT")}</p>
          </div>
          <div className="uploadedImg">
            {formData?.productImage?.length > 0 &&
              formData.productImage?.map((fileUrl, index) => (
                <div className="imgPreview" key={index}>
                  <div className="imgPreview__inner">
                    <img
                      src={
                        typeof fileUrl === "string"
                          ? fileUrl
                          : URL.createObjectURL(fileUrl)
                      }
                      alt={t("UPLOADED_IMAGE")}
                    />
                    <button
                      className="remove-image"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DismissCircle />
                    </button>
                  </div>
                  <div className="imgPreview__content">
                    <p>{`Image ${index + 1}`}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="modal-actions btnPart">
          <button className="btn" onClick={handleEditProduct}>
            {t("SAVE")}
          </button>
          <button className="btn" onClick={() => {
            onClose();
            refreshProducts();
          }}>
            {t("CLOSE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProductModal;
