import React from 'react';
import { IoDownloadOutline } from "react-icons/io5";
import './ViewProductImageModal.scss';
import { useTranslation } from 'react-i18next';

const ViewProductImageModal = ({ 
  isOpen, 
  onClose, 
  images, 
  selectedImageIndex = 0 
}) => {
  const {t} = useTranslation();
  if (!isOpen) return null;

  const handleDownload = async (imageUrl, index) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      const extension = imageUrl.split('.').pop().toLowerCase() || 'jpg';
      link.download = `product-image-${index + 1}.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <div className="view-product-modal">
      <div 
        className="modal-backdrop"
        onClick={onClose}
      />
      <div className="modal-content">
        <button
          className="close-button"
          onClick={onClose}
        >
          ✕
        </button>
        
        <div className="image-gallery-container">
          <h2 className="gallery-title">{t("PRODUCT_IMAGE")}</h2>
          <div className="image-gallery">
            {images?.map((image, index) => (
              <div 
                key={index} 
                className={`gallery-item ${selectedImageIndex === index ? 'selected' : ''}`}
              >
                <div className="image-wrapper">
                  <img
                    src={image}
                    alt={`Product ${index + 1}`}
                  />
                  <button
                    onClick={() => handleDownload(image, index)}
                    className="download-button"
                    title="Download image"
                  >
                    <IoDownloadOutline size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductImageModal;