import React, { useEffect, useState } from "react";
import "./NotificationPage.scss";
import Header from "../Header";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id: userId } = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    getNotificationByUserId();
  }, []);

  async function getNotificationByUserId() {
    try {
      setLoading(true);
      const responseNotification = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/${userId}`
      );
      const notificationData = responseNotification?.data?.data;
      if (responseNotification?.data?.status) {
        setNotifications(notificationData);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleAction = (action) => {
    console.log(`Action triggered: ${action}`);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <div>
      <Header />
      <div className="notification-page">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={`notification data-export ${notification.type}`}
          >
            <p>{notification.message}</p>
            {notification.description && (
              <span>{notification.description}</span>
            )}
            {notification.createdAt && (
              <span className="created-at">
                {formatDate(notification.createdAt)}
              </span>
            )}
            {notification.actions &&
              notification.actions.map((action, idx) => (
                <button key={idx} onClick={() => handleAction(action.action)}>
                  {action.label}
                </button>
              ))}
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default NotificationPage;
