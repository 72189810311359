//dependenies
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
//components
import { useAuth } from "../AuthContext";
import { loginValidationSchema } from "../Validation/validationSchemas";
import { loginUser } from "../store/actions/authActions";
import { signupUserWithSocial } from "../store/actions/signupActions";
//img / svg
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as User } from "../assets/svg/User.svg";
import { ReactComponent as Lock } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../assets/svg/EyeShow.svg";

import { ReactComponent as GoogleIcon } from "../assets/svg/googleIcon.svg";
import { ReactComponent as FbIcon } from "../assets/svg/fbIcon.svg";
//styles
import "./SignIn.scss";
import "./Loader.scss";

const SignIn = () => {
  const { t } = useTranslation();
  const useContextValue = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState(false);

  useEffect(() => {
    try {
      const token = localStorage?.getItem("token") || null;
      if (token) {
        navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error parsing userData from localStorage:", error);
    }
  }, []);

  const handleLogin = async (values) => {
    localStorage.setItem("loginViaSocial", false);
    try {
      setLoading(true);
      let loginValues = { ...values };

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (loginValues?.nickname && emailPattern.test(loginValues.nickname)) {
        loginValues.email = loginValues.nickname;
        delete loginValues.nickname;
      }

      const result = await dispatch(loginUser(loginValues));

      if (result.type === "LOGIN_SUCCESS") {
        toast.success(t("LOGIN_SUCCESS"));
        const isAdmin = result?.payload?.authData?.is_admin;
        login(result?.payload?.token, result?.payload?.authData);

        if (isAdmin) {
          navigate("/admin");
        } else {
          navigate("/home");
        }
      } else {
        toast.error(t(result.payload.message));
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(t("LOGIN_FAILURE"));
    } finally {
      setLoading(false);
    }
  };

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      // email: "",
      password: "",
      nickname: "",
    },
    validationSchema: loginValidationSchema(t),
    onSubmit: (values) => handleLogin(values),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const REDIRECT_URI = window.location.href;

  const handleGoogleLogin = async (data) => {
    try {
      // Prepare user data for the backend
      setIsSocialLoading(true);
      const token = data.access_token;
      localStorage.setItem("token", token);

      // // Call the Google login/register API
      const userInfoResponse = await fetch(
        `${process.env.REACT_APP_GOOGLE_API}?access_token=${token}`
      );

      if (!userInfoResponse.ok) {
        toast.error(t("FAILED_TO_FETCH_USER_INFO"));
      }

      const userInfo = await userInfoResponse.json();

      if (!userInfo.email) {
        toast.error(t("EMAIL_NOT_AVAILABLE_FOR_GOOGLE_lOGIN"));
      }

      const userData = {
        name: userInfo.name,
        email: userInfo.email,
        image: userInfo.picture,
      };
      handleSocialLogin(userData);
    } catch (error) {
      console.error("Google login error:", error);
      toast.error(t("GOOGLE_LOGIN_ERROR"));
    } finally {
      setIsSocialLoading(false);
    }
  };

  const onLoginStart = useCallback(() => { }, []);

  const onLogoutSuccess = useCallback(() => { }, []);

  const handleSocialLogin = async (data) => {
    localStorage.setItem("loginViaSocial", true);
    let object = {
      username: data.name,
      nickname: data.name,
      email: data.email,
      image: data.image,
    };

    try {
      setIsSocialLoading(true);
      const result = await dispatch(signupUserWithSocial(object));
      if (result.type === "SIGNUP_FAILURE") {
        toast.error(t("ALREADY_REGISTERED_SOCIAL"));
      }
      if (result?.data?.status) {
        toast.success(t("SIGN_UP_SUCCESS_WITH_SOICAL"));

        localStorage.setItem("token", result?.data?.data?.token);

        localStorage.setItem(
          "userData",
          JSON.stringify(result?.data.data?.authData)
        );

        login(result?.data?.data?.token, result?.data.data?.authData);
        navigate("/home");
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setIsSocialLoading(false);
    }
  };

  return (
    <div className="Login">
      <div className="Login__inner">
        <Link to="/home" className="themeBrand">
          <Trendies />
        </Link>
        <h2 className="Login__title">{t("SIGN_IN")}</h2>
        <form onSubmit={formik.handleSubmit} className="Login__form">
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="nickname"
              name="nickname"
              placeholder={t("ENTER_NICKNAME")}
              value={formik.values.nickname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.nickname && formik.errors.nickname ? (
              <div className="error-message">{formik.errors.nickname}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Lock className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder={t("ENTER_PASSWORD")}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              {showPassword ? <EyeShow /> : <EyeHide />}
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null}
          </div>
          <Link className="forgotPass" to="/forgot-password">
            {t("FORGOT_PASSWORD")} ?{" "}
          </Link>
          <div className="login-btn">
            <button type="submit" className="btn">
              {loading ? "Loading..." : t("SIGN_IN")}
            </button>
          </div>
        </form>

        <div className="content-text">
          {t("BY_LOGGING_YOU_AGREE_TO")}
          <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
          <span> {t("TERMS_OF_USE")}</span>
        </div>

        <div className="continue-text">
          <span> {t("OR_CONTINUE_WITH")}</span>
        </div>

        <div className="social-icons">
          <LoginSocialGoogle
            isOnlyGetToken
            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
            scope="profile email"
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => handleGoogleLogin(data)}
            onReject={(err) => { }}
          >
            <button className="social-button">
              <GoogleIcon />
            </button>
          </LoginSocialGoogle>
          <LoginSocialFacebook
            // appId={process.env.REACT_APP_FB_APP_ID || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
            onResolve={({ provider, data }) => {
              data.image = data.picture?.data?.url;
              handleSocialLogin(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <FbIcon />
          </LoginSocialFacebook>
          {/* <LoginSocialInstagram
            isOnlyGetToken={true}
            client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ""}
            client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ""}
            scope="basic"
            redirect_uri={REDIRECT_URI}
            onLoginStart={() => console.log("Instagram Login Started")}
            onResolve={(data) => {
              console.log("onResolve:", data);
              handleInstagramLogin(data);
            }}
            onReject={(err) => {
              console.log("Instagram Login Rejected:", err);
            }}
          >
            <button className="social-button">
              <InstaIcon />
            </button>
          </LoginSocialInstagram> */}
        </div>

        <p className="signup-link">
          {t("DO_NOT_HAVE_AN_ACCOUNT")}?{" "}
          <Link to="/signup">
            {t("SIGN_UP")} {t("HERE")}
          </Link>
        </p>
      </div>
      <ToastContainer />
      {isSocialLoading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default SignIn;
