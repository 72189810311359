// InvoiceBreakdown.jsx
import React from "react";
import "./InvoiceBreakdown.scss";
import { t } from "i18next";

const InvoiceBreakdown = ({ sellerPriceDetails }) => {
  const sellerPrice = sellerPriceDetails?.price
  return (
    <div className="invoice-breakdown">
      <div className="row">
        <span className="label">{t("SUBTOTAL")}</span>
        <span className="value">{sellerPrice >= 1000 ? sellerPriceDetails?.price : 0} MAD</span>
      </div>
      <div className="row">
        <span className="label">
          Commission <span className="tooltip">?</span>
          <div className="tooltip-text">{sellerPriceDetails?.commissionRatePercentage || 0}% Commission</div>
        </span>
        <span className="value negative">{sellerPrice >= 1000 ? Math.round(sellerPriceDetails?.commission) : 0} MAD</span>
      </div>
      <div className="row">
        <span className="label">
          {t("SERVICE_FEES")} <span className="tooltip">?</span>
          <div className="tooltip-text"> 5% {t("SERVICE_FEES")}</div>
        </span>
        <span className="value negative">{sellerPrice >= 1000 ? Math.round(sellerPriceDetails?.serviceFee) : 0} MAD</span>
      </div>
      <div className="row">
        <span className="label">
          {t("BUYER_PROTECTION_FEE")} <span className="tooltip">?</span>
          <div className="tooltip-text">{t("BUYER_PROTECTION_FEE")}</div>
        </span>
        <span className="value negative">{sellerPrice >= 1000 ? "-50 MAD" : "0 MAD"}</span>
      </div>

      <div className="total-row">
        <span className="label">{t("YOU_GET")}</span>
        <span className="value negative">{sellerPrice >= 1000 ? Math.round(sellerPriceDetails?.total) : 0} MAD</span>
      </div>
    </div>
  );
};

export default InvoiceBreakdown;
