import React, { useEffect, useRef } from "react";
import "./ConfirmDeleteModal.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ConfirmDeleteModal = ({ open, setOpen, getProducts }) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen("");
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  if (!open) return null;

  const handleDeleteProduct = async () => {
    try {
      if (open !== "") {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/products/${open}`
        );
        if (response?.status === 200) {
          toast.success(t("DELETE_PRODUCT_SUCCESS"));
          getProducts();
          setOpen("");
        }
      }
    } catch (error) {
      toast.error(t("ERROR_IN_DELETE_PRODUCT"));
      console.error(error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="delete-modal-content" ref={modalRef}>
        <h2>{t("DELETE_CONFIRMATION")}</h2>
        <div className="modal-actions btnPart">
          <button
            className="btn"
            onClick={() => {
              handleDeleteProduct();
              setOpen("");
            }}
          >
            {t("YES")}
          </button>
          <button className="btn" onClick={() => setOpen("")}>
            {t("NO")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
