import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./TermsAndConditions.scss";
import { toast } from "react-toastify";

const TermsAndConditionsModal = ({ show, onHide, onProceed, termsAnsConditionLoading }) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
  };

  const handleProceed = () => {
    if (isAccepted) {
      onProceed();
      // onHide();
      setIsAccepted(false);
      return
    }
    toast.error(t("TERMS_AND_CONDITION_ERROR"))
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="tnd-modal-overlay">
      <div className="tnd-modal-content" ref={modalRef}>
        <div className="tnd-modal-header">
          <h2>{t("TERMS_AND_CONDITIONS")}</h2>
          <button
            className="tnd-close-button"
            onClick={() => {
              onHide();
              setIsAccepted(false);
            }}
          >
            &times;
          </button>
        </div>
        <div className="tnd-modal-body">
          <div>
            <h2>{t("TERMS_AND_CONDITIONS_CGV")}</h2>
            <p>
              <strong>{t("GENERAL_OVERVIEW")}</strong>
              {t("GENERAL_OVERVIEW_DESC")}
            </p>
            <h3>{t("KEY_HIGHLIGHTS")}</h3>
            <ul>
              <li>
                <p>
                  <strong>{t("KEY_HIGHLIGHTS_1")}</strong>
                  {t("KEY_HIGHLIGHTS_1_DESC")}
                </p>
              </li>
              <li>
                <p>
                  <strong>{t("KEY_HIGHLIGHTS_2")}</strong>
                  {t("KEY_HIGHLIGHTS_2_DESC")}
                </p>
              </li>
              <li>
                <p>
                  <strong>{t("KEY_HIGHLIGHTS_3")}</strong>
                  {t("KEY_HIGHLIGHTS_3_DESC")}
                </p>
              </li>
              <li>
                <p>
                  <strong>{t("KEY_HIGHLIGHTS_4")}</strong>
                  {t("KEY_HIGHLIGHTS_4_DESC")}
                </p>
              </li>
            </ul>

            <h3>{t("REFUNDS_AND_RETURNS")}</h3>
            <p>{t("REFUNDS_AND_RETURNS_DESC")}</p>

            <h2>{t("CONTACT_INFORMATION")}</h2>
            <h3>{t("TRENDIES_CUSTOMER_SERVICE")}</h3>
            <ul>
              <li>
                <p>
                  <strong>{t("PHONE")}: </strong>+212 6 78 73 69 38
                </p>
              </li>
              <li>
                <p>
                  <strong>{t("EMAIL")}: </strong>contact@trendiesmaroc.com
                </p>
              </li>
              <li>
                <p>
                  <strong>{t("WORKING_HOURS")}: </strong>
                  {t("WORKING_HOURS_DESC")}
                </p>
              </li>
            </ul>
            <p>{t("SUPPORT_CONTACT_INFORMATION")}</p>
          </div>
          <div className="tnd-modal-checkbox">
            <label>
              <input
                type="checkbox"
                checked={isAccepted}
                onChange={handleCheckboxChange}
              />
              {t("ACCEPT_TERMS_AND_CONDITION")}
            </label>
          </div>
        </div>
        <div className=" btnPart tnd-modal-footer">
          <button
            className="btn"
            onClick={() => {
              onHide();
              setIsAccepted(false);
            }}
          >
            {t("CLOSE")}
          </button>
          <button
            className="btn"
            onClick={handleProceed}

          >{
              termsAnsConditionLoading ? "Loading..." : t("PROCEED")
            }

          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
