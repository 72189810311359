import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductDetails.scss";
import { useTranslation } from "react-i18next";
import ChatWindow from "../utils/ChatWindow/ChatWindow";
import BidModal from "./BidModal";
import { toast, ToastContainer } from "react-toastify";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { incrementCartCount } from "../store/actions/cartAction";
import { ReactComponent as HeartFill } from "../assets/svg/Heartfill.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import { addFavorite, clearFavorite } from "../store/actions/favoritesAction";
const ProductDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;
  const [product, setProduct] = useState({});
  const [productImage, setProductImage] = useState();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bidDetails, setBidDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartAddedSuccess, setCartAddSuccess] = useState(false);
  const [mainImage, setMainImage] = useState(
    // Default to the first image
  );
  const [rating, setRating] = useState(0);
  const [alreadyCartItem, setAlreadyCartItem] = useState([])
  const [isProductFavorite, setIsProductFavorite] = useState(false);
  const [isFavouriteLoading, setIsFavouriteLoading] = useState(false);

  useEffect(() => {
    // Generate a random value between 0 and 5
    const randomRating = Math.random() * 5;
    setRating(randomRating.toFixed(1)); // Round to 1 decimal place
  }, []);

  const getProductById = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );
      if (response?.status === 200) {
        const productData = response?.data?.data;
        setBidDetails({
          productId: productData?.id,
          sellerId: productData?.userId,
          productPrice: Number(productData?.price),
          offerAmount: "",
          receiverId: id,
          offerCount: 0,
          offerLimit: 10,
        });

        setMainImage(productData.productImage[0])

        setProduct(response?.data?.data);
      }
    } catch (error) {
      toast.error(t("ERROR_IN_FETCHING_PRODUCT_DETAILS"));
      console.error(error);
    }
  };

  const fetchProductsInCart = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        const data = response.data.data;
        setAlreadyCartItem(data)
      }
    } catch (error) {
      // toast.error(t("ERROR_FETCHING_CART_PRODUCTS"));
      console.error("Error fetching cart products:", error);
    }
  };

  const getProductImage = async (image) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/uploads/${image}`
      );
      if (response?.status === 200) {
        setProductImage(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    id && getProductById(id);
    fetchProductsInCart()
    getFavoriteProductStatus(userId, id)

  }, [id]);

  useEffect(() => {
    if (product && product?.pictureOfItem?.length > 0)
      getProductImage(product?.pictureOfItem[0]);
  }, [product]);

  if (!product) {
    return <div>{t("LOADING_PRODUCT_DETAILS")}...</div>;
  }

  const handleBidSubmit = (bidAmount) => {
    const createBidData = {
      ...bidDetails,
      offerAmount: bidAmount,
      receiverId: userId,
    };

    const getProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/offer`,
          createBidData
        );
        if (response?.data?.status) {
          toast.success(t("OFFER_CREATED"));
        }
      } catch (error) {
        toast.error(t(error?.response?.data?.data?.message));
      } finally {
        setLoading(false);
        setIsModalOpen(false);
      }
    };
    bidDetails?.sellerId
      ? getProducts()
      : toast.error(t("ERROR_IN_CREATING_OFFER"));
  };

  const handleAddToCart = async (productId, text) => {
    if (userId) {
      if (text === "Go to cart" || text === "Aller au panier") {
        navigate(`/cart`);
      } else {
        const formData = new FormData();
        formData.append("productId", productId || 0);
        formData.append("quantity", quantity || 1);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/add-cart`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.status === 200) {
            setCartAddSuccess(true);
            dispatch(incrementCartCount(quantity));
            // toast.success(t(response.data.message));
          }
        } catch (error) {
          // toast.error(t("ERROR_IN_ADDING_TO_CART"));
          // console.error("Error fetching favorite products:", error);
          // setFavoriteProducts([]);
        }
      }
    }
    else {
      navigate('/login')
    }

  };

  const handleImageClick = (image) => {
    // setProduct({ ...product, productImage: [image] });
    setMainImage(image);
  }

  const toggleFavorite = async (productId) => {
    if (!userId) {
      navigate("/login");
    } else {
      try {
        setIsFavouriteLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/products/${productId}/favorite`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success(t(response.data.message));
          const status = await getFavoriteProductStatus(userId, id)
          if (!status) {
            dispatch(clearFavorite(1));
          } else {
            dispatch(addFavorite(1));
          }
        }
      } catch (error) {
        toast.error(t("ERROR_IN_TOGGING_FAVORITE"));
        console.error("Error toggling favorite:", error);
      }finally{
        setIsFavouriteLoading(false)
      }
    }
  };

  const getFavoriteProductStatus = async (userId, productId) => {
    if (!userId) {
      navigate("/login");
    } else {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}/${productId}`,
        );
        const status = response?.data?.data
        setIsProductFavorite(status)
        return status
      } catch (error) {
        toast.error(t("ERROR_IN_TOGGING_FAVORITE"));
        console.error("Error toggling favorite:", error);
      }
    }
  };



  return (
    <div className="main-Wrapper">
      <Container maxWidth="lg" disableGutters>
        <div className="productDetail">
          <div className="productDetail__inner">
            <div className="productDetail__img">
              <div className="productImg" style={{ position: "relative" }}>

                <img
                  src={
                    product && product?.productImage?.length > 0
                      ? mainImage
                      : ""
                  }
                  alt="Product"
                />
                {
                  userId !== product?.userId && (
                    <span
                      className="productItem__img-btn"
                      onClick={() => toggleFavorite(product.id)}
                    >
                      {isProductFavorite ? <HeartFill className="icon" /> : <Heart className="icon" />}

                    </span>
                  )
                }


              </div>

              <div className="productImgSmall">

                {product?.productImage
                  ?.filter((image) => image !== mainImage)
                  .map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Product_image ${index + 1}`}
                      onClick={() => handleImageClick(image)}
                      className="product-thumbnail"
                    />
                  ))}
              </div>
            </div>

            <div className="product-info-section">
              <h2 className="product-title">{product?.title}</h2>
              <div className="product-info-list">
                <p className="product-category-brand">
                  <span>{t("CATEGORY")}:</span> {product?.category}
                </p>
                <p className="product-category-brand">
                  <span>{t("BRAND")}:</span>
                  {product?.brand}
                </p>
              </div>
              <div className="product-price-section">
                <p className="product-price">{product?.price} MAD </p>
                {/* <label className="price-label">{t("SPECIAL_PRICE")}</label> */}
              </div>
              <div className="product-description">
                <h4>{t("DESCRIPTION")}</h4>
                <p>{product?.description}</p>
                {/* <div className="product_rating">
                  <div className="stars">
                    {[...Array(5)].map((_, index) => (
                      <span
                        key={index}
                        className="star"
                        style={{ color: index < rating ? '#FFD700' : '#ccc' }}
                        onClick={() => setRating(index + 1)} // Click to set rating
                      >
                        ★
                      </span>
                    ))}
                  </div>
                </div> */}
              </div>

              {userData?.id !== product?.userId && (
                <div className="btnPart">
                  <button
                    className="btn add-to-cart-btn"
                    onClick={(e) =>
                      handleAddToCart(product.id, e.target.textContent)
                    }
                  >
                    {/* {t("ADD_TO_CART")} */}

                    {cartAddedSuccess || (alreadyCartItem.some(cartItem => cartItem.userId === userId && alreadyCartItem.some(cartItem => cartItem?.productId === product?.id)))
                      ? t("GO_TO_CART")
                      : t("ADD_TO_CART")}
                  </button>
                  <button
                    onClick={() => userId ? navigate(`/payment/${id}`) : navigate('/login')}
                    className="btn buy-now-btn"
                  >
                    {t("BUY_NOW")}
                  </button>
                  <button
                    onClick={() => userId ? setIsModalOpen(true) : navigate('/login')}
                    className="btn add-to-cart-btn"
                  >
                    {t("MAKE_AN_OFFER")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isChatOpen && <ChatWindow onClose={handleToggleChat} />}
        <BidModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          currentPrice={product?.price}
          onSubmitBid={handleBidSubmit}
          setBidDetails={setBidDetails}
          bidDetails={bidDetails}
          loading={loading}
        />
        <ToastContainer />
      </Container>
      {
        isFavouriteLoading && <div className="loader-wrapper">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      }
    </div>
  );
};

export default ProductDetails;