import React from "react";
import "./MessagePage.scss";
import profileImage from "../assets/images/profile.png";
import { ReactComponent as Search } from "../assets/svg/Search.svg";
import { useNavigate } from "react-router-dom";

const MessagePage = () => {
  const navigate = useNavigate();
  const messages = [
    {
      id: 1,
      sender: "Fatima Bennani",
      time: "09:27 AM",
      preview: "Bonjour Fatima, j'ai vu votre...",
      profilePic: profileImage,
    },
  ];

  return (
    <div className="main-Wrapper">
      <div className="message-page">
        <div className="sectionHeader">
          <h3>Tous les messages (01)</h3>
          <div className="search-container">
            <Search className="icon" />
            <input type="text" placeholder="Cherche ici..." />
          </div>
        </div>

        <div className="messageList">
          {messages.map((message) => (
            <>
              <div className="messageItem" key={message.id}>
                <div className="messageItem__img">
                  <img
                    src={message.profilePic}
                    alt={message.sender}
                    className="profile-pic"
                  />
                </div>
                <div className="messageItem__content">
                  <div className="messageItem__header">
                    <h3>{message.sender}</h3>
                    <span className="time">{message.time}</span>
                  </div>
                  <div className="messageBox">
                    <p className="messagePreview">
                      {message.preview} Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Delectus nobis odio hic modi est! Porro
                      animi, et, aut qui ullam eos commodi nesciunt quisquam
                      nostrum corrupti nam unde expedita vero? Lorem ipsum dolor
                      sit amet consectetur adipisicing elit. In, dicta
                      doloremque cum ipsum dolores eos eius accusantium neque
                      facere laborum placeat qui debitis, tempore magni nobis
                      quasi reprehenderit eligendi quas.
                    </p>
                    <span className="messageCount">12</span>
                  </div>
                </div>
              </div>
              <div className="messageItem" key={message.id}>
                <div className="messageItem__img">
                  <img
                    src={message.profilePic}
                    alt={message.sender}
                    className="profile-pic"
                  />
                </div>
                <div className="messageItem__content">
                  <div className="messageItem__header">
                    <h3>{message.sender}</h3>
                    <span className="time">{message.time}</span>
                  </div>
                  <div className="messageBox">
                    <p className="messagePreview">
                      {message.preview} Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Delectus nobis odio hic modi est! Porro
                      animi, et, aut qui ullam eos commodi nesciunt quisquam
                      nostrum corrupti nam unde expedita vero? Lorem ipsum dolor
                      sit amet consectetur adipisicing elit. In, dicta
                      doloremque cum ipsum dolores eos eius accusantium neque
                      facere laborum placeat qui debitis, tempore magni nobis
                      quasi reprehenderit eligendi quas.
                    </p>
                    <span className="messageCount">12</span>
                  </div>
                </div>
              </div>
              <div className="messageItem" key={message.id}>
                <div className="messageItem__img">
                  <img
                    src={message.profilePic}
                    alt={message.sender}
                    className="profile-pic"
                  />
                </div>
                <div className="messageItem__content">
                  <div className="messageItem__header">
                    <h3>{message.sender}</h3>
                    <span className="time">{message.time}</span>
                  </div>
                  <div className="messageBox">
                    <p className="messagePreview">
                      {message.preview} Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Delectus nobis odio hic modi est! Porro
                      animi, et, aut qui ullam eos commodi nesciunt quisquam
                      nostrum corrupti nam unde expedita vero? Lorem ipsum dolor
                      sit amet consectetur adipisicing elit. In, dicta
                      doloremque cum ipsum dolores eos eius accusantium neque
                      facere laborum placeat qui debitis, tempore magni nobis
                      quasi reprehenderit eligendi quas.
                    </p>
                    <span className="messageCount">12</span>
                  </div>
                </div>
              </div>
            </>
          ))}

          <button className="btn" onClick={()=>{
                    navigate("/payment");
                }}>Buy Now</button>
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
