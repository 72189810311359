// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: black;
  border-radius: 50%;
  animation: spin 1s ease infinite;
}

p {
  font-weight: 600;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PurchaseProduct/utils/Spinner.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,uBAAA;EACA,kBAAA;EACA,gCAAA;AAAF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE;IACE,yBAAA;EACF;AACF","sourcesContent":["// LoadingSpinner.scss\n.spinner-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh; // Full viewport height for centering\n}\n\n.spinner {\n  width: 50px;\n  height: 50px;\n  border: 5px solid rgba(0, 0, 0, 0.1);\n  border-top-color: black; // Customize this color for the spinner\n  border-radius: 50%;\n  animation: spin 1s ease infinite;\n}\np{\n  font-weight: 600;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
