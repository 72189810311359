import React from "react";
import { Card, CardContent } from "@mui/material";
import {
  MdBed,
  MdBathtub,
  MdGarage,
  MdLocationOn,
  MdPhone,
  MdMessage,
} from "react-icons/md";
import "./ProductDetailsModel.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";

const ProductDetailsModel = ({
  isOpen,
  onClose,
  currentProductSellerBuyerDetails,
  isChatDescriptions,
}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  function calculatePriceDiscount(originalPrice, discountedPrice) {
    // Remove $ signs and convert to numbers
    const original = Number(originalPrice.replace("MAD ", ""));
    const discounted = Number(discountedPrice.replace("MAD ", ""));

    // Calculate the discount percentage
    const discountAmount = original - discounted;
    const discountPercentage = (discountAmount / original) * 100;

    return {
      originalPrice: original,
      discountedPrice: discounted,
      discountPercentage: discountPercentage.toFixed(2) + "%",
      savings: discountAmount.toFixed(2),
    };
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <Cross />
        </button>

        <div className="property-container productDetail-model">
          <div className="productDetail__inner">
            <div className="productDetail__img">
              <div className="productImg productImg-gallery">
                {currentProductSellerBuyerDetails?.product?.productImage &&
                  currentProductSellerBuyerDetails?.product?.productImage
                    .length > 0 &&
                  currentProductSellerBuyerDetails?.product?.productImage.map(
                    (image) => (
                      <div className="main-image">
                        <img src={image} alt="Main house view" />
                      </div>
                    )
                  )}
              </div>
            </div>

            <div className="product-info-section">
              <h2 className="product-title">{currentProductSellerBuyerDetails?.product?.title}</h2>
              <div className="product-info-list">
                <p className="product-category-brand">
                  <span>{t("CATEGORY")}:</span> {currentProductSellerBuyerDetails?.product?.category}
                </p>
                <p className="product-category-brand">
                  <span>{t("BRAND")}:</span>
                  {currentProductSellerBuyerDetails?.product?.brand}
                </p>
                <p className="product-category-brand">
                  <span>Date:</span>
                  {new Date(
                    currentProductSellerBuyerDetails?.product?.createdAt
                  )
                    ?.toLocaleString()
                    .toUpperCase()}
                </p>
              </div>
              <div className="product-price-section">
                <p className="product-price">{currentProductSellerBuyerDetails?.product?.price} MAD </p>
                {/* <label className="price-label">{t("SPECIAL_PRICE")}</label> */}
              </div>
              {/* <p className="price-subtitle">{t("BUY_WITH_THE_PRICE")}</p> */}
              <div className="market-price">
                {/* <span className="original-price">
                  {currentProductSellerBuyerDetails?.offerAmount} MAD
                </span> */}
                {/* {
                  !isChatDescriptions &&  <span className="discount">
                  {
                    calculatePriceDiscount(
                      currentProductSellerBuyerDetails?.product?.price,
                      currentProductSellerBuyerDetails?.offerAmount
                    ).discountPercentage
                  }{" "}
                  {t("CHEAPER")}
                </span>
                } */}
               
              </div>
              {/* Error fetching cart products */}


              {/* <div className="btnPart action-buttons">
                                        <button className="btn">
                                            <MdPhone />
                                            Call
                                        </button>
                                        <button onClick={() => onClose()} className="btn">
                                            <MdMessage />
                                            Message
                                        </button>
                                    </div>  */}
            </div>
          </div>
          {/* <div className="property-grid">
            <div className="main-content">
              <div className="image-gallery">
                {currentProductSellerBuyerDetails?.product?.productImage &&
                  currentProductSellerBuyerDetails?.product?.productImage
                    .length > 0 &&
                  currentProductSellerBuyerDetails?.product?.productImage.map(
                    (image) => (
                      <div className="main-image">
                        <img src={image} alt="Main house view" />
                      </div>
                    )
                  )}
              </div>

              <div className="property-details">
                <h1>{t("PRODUCT_DETAILS")}</h1>
                <div className="features">
                  <div className="feature">
                    <MdBed />
                    <span>
                      {currentProductSellerBuyerDetails?.product?.title.toUpperCase()}
                    </span>
                  </div>
                  <div className="feature">
                    <MdBathtub />
                    <span>
                      {currentProductSellerBuyerDetails?.product?.brand.toUpperCase()}
                    </span>
                  </div>
                  <div className="feature">
                    <MdGarage />
                    <span>
                      {currentProductSellerBuyerDetails?.product?.category.toUpperCase()}
                    </span>
                  </div>
                  <div className="feature">
                    <MdGarage />
                    <span>
                      {currentProductSellerBuyerDetails?.product?.description.toUpperCase()}
                    </span>
                  </div>
                  <div className="feature">
                    <MdLocationOn />
                    <span>
                      {new Date(
                        currentProductSellerBuyerDetails?.product?.createdAt
                      )
                        ?.toLocaleString()
                        .toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="sidebar">
              <Card>
                <CardContent>
                  <div className="price-section">
                    <h2 className="price">
                      {currentProductSellerBuyerDetails?.product?.price} MAD
                    </h2>
                    <p className="price-subtitle">{t("BUY_WITH_THE_PRICE")}</p>
                    <div className="market-price">
                      <span className="original-price">
                        {currentProductSellerBuyerDetails?.offerAmount} MAD
                      </span>
                      <span className="discount">
                        {
                          calculatePriceDiscount(
                            currentProductSellerBuyerDetails?.product?.price,
                            currentProductSellerBuyerDetails?.offerAmount
                          ).discountPercentage
                        }{" "}
                        {t("CHEAPER")}
                      </span>
                    </div>
                  </div>

                 
                </CardContent>
              </Card>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModel;
