import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ThemeBrand } from "../assets/svg/ThemeBrand.svg";
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as Email } from "../assets/svg/Email.svg";
import { ReactComponent as Lock } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import { ReactComponent as User } from "../assets/svg/User.svg";
import { ReactComponent as GoogleIcon } from "../assets/svg/googleIcon.svg";
import { ReactComponent as FbIcon } from "../assets/svg/fbIcon.svg";
import { ReactComponent as InstaIcon } from "../assets/svg/instaIcon.svg";
import { useFormik } from "formik";
import { SignupSchema } from "../Validation/validationSchemas";
import "./Signup.scss";
import { signupUser } from "../store/actions/signupActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSignup = async (values) => {
    try {
      setLoading(true);
      const result = await dispatch(signupUser(values));
      if (result?.data?.status) {
        toast.success(
          t("SIGN_UP_SUCCESS")
        );
        
      setTimeout(() => {
        navigate("/login");
      }, 1000);
      } else {
        toast.error(result?.payload); 
      }
    } catch (error) {
      toast.error(t("SIGN_UP_FAILED"));
    } finally {
      setLoading(false);
    }
  };

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      nickname: "",
      email: "",
      password: "",
    },
    validationSchema: SignupSchema(t),
    onSubmit: (values) => handleSignup(values),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="Login">
      <div className="Login__inner">
        <a href="#" className="themeBrand">
        <Trendies />
        </a>
        <h2 className="Login__title">{t("SIGN_UP")}</h2>

        <form onSubmit={formik.handleSubmit} className="Login__form">
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("USER_NAME")}
              className="input-with-icon"
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="error-message">{formik.errors.username}</div>
            ) : null}
          </div>
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="nickname"
              name="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("NICK_NAME")}
              className="input-with-icon"
            />
            {formik.touched.nickname && formik.errors.nickname ? (
              <div className="error-message">{formik.errors.nickname}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Email className="icon" />
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("ENTER_EMAIL")}
              className="input-with-icon"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Lock className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t("ENTER_PASSWORD")}
              className="input-with-icon"
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              <EyeHide />
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="login-btn">
            <button type="submit" className="btn">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div>Loading</div>
                  <div class="loading-dots">
                    <span class="loading">.</span>
                    <span class="loading">.</span>
                    <span class="loading">.</span>
                  </div>
                </div>
              ) : (
                t("CREATE_AN_ACCOUNT")
              )}
            </button>
          </div>
        </form>
        <div className="content-text">
          {t("BY_LOGGING_YOU_AGREE_TO")}
          <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
          <span> {t("TERMS_OF_USE")}</span>
        </div>

        <div className="continue-text">
          <span> {t("OR_CONTINUE_WITH")}</span>
        </div>

        <div className="social-icons">
          <button className="social-button">
            <GoogleIcon />
          </button>
          <button className="social-button">
            <FbIcon />
          </button>
          <button className="social-button">
            <InstaIcon />
          </button>
        </div>

        <p className="signup-link">
          {t("HAVE_AN_ACCOUNT")} ?{" "}
          <Link to="/login">
            {t("SIGN_IN")} {t("HERE")}
          </Link>
        </p>
        <ToastContainer />
      </div>
     
    </div>
  );
};

export default Signup;
