import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./About.scss";

function About() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <div className="about-page">
      <h2>{t("ABOUT_US")}</h2>
      <div>
        <h3>{t("WHO_ARE_WE")}</h3>
        <p>{t("WHO_ARE_WE_DESCRIPTION")}</p>
        <p>{t("WHO_ARE_WE_DESCRIPTION_2")}</p>
        <p>{t("WHO_ARE_WE_DESCRIPTION_3")}</p>
      </div>
      <div className="about-block">
        <h3>{t("OUR_COMMITMENT")}</h3>
        <p>{t("OUR_COMMITMENT_DESCRIPTION")}</p>
        <ul>
          <li>
            <p>
              <strong>{t("COMMITMENT_1")}</strong>
              {t("COMMITMENT_1_DESCRIPTION")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("COMMITMENT_2")}</strong>
              {t("COMMITMENT_2_DESCRIPTION")}
            </p>
          </li>
        </ul>
      </div>
      <p className="description">{t("WHO_ARE_WE_DESCRIPTION_4")}</p>
      <div>
        <h3>{t("TRENDIES_BENEFIT")}</h3>
        <ul>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_1")}</strong>
              {t("TRENDIES_BENEFIT_1_DESC")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_2")}</strong>
              {t("TRENDIES_BENEFIT_2_DESC")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_3")}</strong>
              {t("TRENDIES_BENEFIT_3_DESC")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_4")}</strong>
              {t("TRENDIES_BENEFIT_4_DESC")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_5")}</strong>
              {t("TRENDIES_BENEFIT_5_DESC")}
            </p>
          </li>
          <li>
            <p>
              <strong>{t("TRENDIES_BENEFIT_6")}</strong>
              {t("TRENDIES_BENEFIT_6_DESC")}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default About;
