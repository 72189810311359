import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./chat.scss";
import axios from "axios";
import Header from "../Header";
import ProductDetailsModel from "./ProductDetails/ProductDetailsModel";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { useSensitivePatterns } from "./ChatUtils/Constants";
import { ReactComponent as DismissCircle } from "../../assets/svg/DismissCircle.svg";

const socket = io(process.env.REACT_APP_API_URL);

const ChatApp = () => {
  const { t } = useTranslation();
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const senderId = userDetails?.id;
  const sensitivePatterns = useSensitivePatterns();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [receiverId, setReceiverId] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [currentPerson, setCurrentPerson] = useState("seller");
  const [activeChat, setActiveChat] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    currentProductSellerBuyerDetails,
    setCurrentProductSellerBuyerDetails,
  ] = useState(null);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOffer = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-user-offer-list/${senderId}`
      );
      const responseReceiverData = response?.data?.data?.receiverChat;
      const responseSellerData = response?.data?.data?.sellerChat;
      setReceivers(responseReceiverData || []);
      setSellers(responseSellerData || []);
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetChat = () => {
    setMessages([]);
    setProductId(null);
    setReceiverId("");
    setActiveChat(null);
    setSelectedFiles([]);
    // Disconnect from current room
    if (senderId && receiverId) {
      socket.emit("leaveRoom", { userId: senderId, receiverId });
    }
  };

  useEffect(() => {
    handleOffer();
  }, [senderId]);

  useEffect(() => {
    if (senderId && receiverId) {
      // Leave previous room if any
      socket.emit("leaveRoom", { userId: senderId, receiverId });
      socket.emit("joinRoom", { userId: senderId, receiverId });
      socket.emit("getHistory", { senderId, receiverId, productId });
      const handleMessageHistory = (history) => {
        setMessages(history);
      };

      const handleNewMessage = (msg) => {
        setMessages((prevMessages) => [...prevMessages, msg]);
      };

      socket.on("messageHistory", handleMessageHistory);
      socket.on("newMessage", handleNewMessage);

      return () => {
        socket.emit("leaveRoom", { userId: senderId, receiverId });
        socket.off("messageHistory", handleMessageHistory);
        socket.off("newMessage", handleNewMessage);
      };
    }
  }, [senderId, receiverId, productId]);

  const handleUserClick = (id, pId) => {
    if (id !== receiverId || pId !== productId) {
      setMessages([]);
      setReceiverId(id);
      setProductId(pId);
      setActiveChat({ id, productId: pId });
    }
  };

  const validateMessage = (messageContent) => {
    for (let { pattern, message } of sensitivePatterns) {
      if (
        (typeof pattern === "string" &&
          messageContent.toLowerCase().includes(pattern.toLowerCase())) ||
        (pattern instanceof RegExp && pattern.test(messageContent))
      ) {
        return message;
      }
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    const validationError = validateMessage(trimmedInput);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    if ((trimmedInput || selectedFiles?.length) && senderId && receiverId) {
      const message = {
        senderId,
        receiverId,
        content: trimmedInput,
        files: selectedFiles.map((file) => ({
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
        })),
        productId,
        timestamp: new Date(),
      };
      socket.emit("chatMessage", message);
      setMessages((prevMessages) => [...prevMessages, message]);
      setInput("");
      setSelectedFiles([]);
    }
  };

  const messageEndRef = useRef(null);
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  function getTimeFromTimestamp(timestamp) {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  return (
    <div className="">
      <Header />
      <div className="chat-app">
        <div className="sidebar">
          <div className="sidebar_header">
            <div
              onClick={() => {
                setCurrentPerson("seller");
                resetChat();
                setCurrentProductSellerBuyerDetails(null);
              }}
              className={`sidebar_seller sidebar_header_options_common ${
                currentPerson === "seller" ? "sidebar_active" : ""
              }`}
            >
              {t("SELLER")}
            </div>
            <div
              onClick={() => {
                setCurrentPerson("buyer");
                setCurrentProductSellerBuyerDetails(null);
                resetChat();
              }}
              className={`sidebar_buyer sidebar_header_options_common ${
                currentPerson === "buyer" ? "sidebar_active" : ""
              }`}
            >
              {t("BUYER")}
            </div>
          </div>

          {loading ? (
            <div className="loading">{t("LOADING")}</div>
          ) : (
            <div>
              {currentPerson === "seller" ? (
                <>
                  {sellers && sellers.length > 0 ? (
                    <ul>
                      {sellers.map((user) => (
                        <li
                          key={`${user.productId}-${user.receiverId}`}
                          className={`user-item ${
                            activeChat?.id === user.receiverId &&
                            activeChat?.productId === user.productId
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleUserClick(user.receiverId, user.productId);
                            setCurrentProductSellerBuyerDetails(user);
                          }}
                        >
                          <img
                            className="chat-product-img"
                            src={user?.product?.productImage[0]}
                            alt="Product"
                          />
                          {user?.receiver?.username?.toUpperCase()}
                          {` (${user?.product?.title?.toUpperCase()})`}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="ErrorBlank">{t("NO_CHAT_FOUND")}</div>
                  )}
                </>
              ) : (
                <>
                  {receivers && receivers?.length > 0 ? (
                    <ul>
                      {receivers.map((user) => (
                        <li
                          key={`${user.productId}-${user.sellerId}`}
                          className={`user-item ${
                            activeChat?.id === user.sellerId &&
                            activeChat?.productId === user.productId
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleUserClick(user.sellerId, user.productId);
                            setCurrentProductSellerBuyerDetails(user);
                          }}
                        >
                          <img
                            className="chat-product-img"
                            src={user?.product?.productImage[0]}
                            alt="Product"
                          />
                          {user?.seller?.username?.toUpperCase()}
                          {` (${user?.product?.title?.toUpperCase()})`}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="ErrorBlank">{t("NO_CHAT_FOUND")}</div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className="chat-body">
          {currentProductSellerBuyerDetails && (
            <>
              <div className="chat-body-header" onClick={handleOpenModal}>
                <img
                  className="chat-product-img"
                  src={
                    currentProductSellerBuyerDetails?.product?.productImage[0]
                  }
                  alt="Product"
                />
                <div className="text">
                  <span>
                    {currentProductSellerBuyerDetails?.product?.title?.toUpperCase()}
                  </span>
                  <span className="description">
                    {currentProductSellerBuyerDetails?.product?.description}
                  </span>
                </div>
              </div>
              <div className="infoText">{t("VIEW_PRODUCT_DETAILS")}</div>
            </>
          )}
          <div className="messages">
            {/* {currentProductSellerBuyerDetails && (
              <p onClick={handleOpenModal} className="btn view-product-details">
                {t("VIEW_PRODUCT_DETAILS")}
              </p>
            )} */}
            {messages?.map((msg, index) => (
              <div
                key={index}
                className={`message ${
                  msg.senderId === senderId ? "outgoing" : "incoming"
                }`}
              >
                {msg.content && <span className="content">{msg.content}</span>}
                {msg.files?.map((file, idx) => (
                  <div key={idx} className="file-preview">
                    {file.type === "application/pdf" ? (
                      <div className="file-embed-preview">
                        <div className="file-embed-preview__inner">
                          <embed
                            // style={{ maxHeight: "fit-content" }}
                            src={file.url}
                            alt={file.name}
                            type="application/pdf"
                          />
                        </div>
                      </div>
                    ) : (
                      <img src={file.url} alt={file.name} />
                    )}
                  </div>
                ))}
                <div className="time">
                  {getTimeFromTimestamp(msg.timestamp)}
                </div>
              </div>
            ))}
            <div ref={messageEndRef} />
          </div>

          <form className="chat-input" onSubmit={handleSubmit}>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={t("TYPE_MESSAGE_HERE")}
              className="message-input"
              disabled={!receiverId}
            />
            <input
              type="file"
              id="file-upload"
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }}
              disabled={!receiverId}
            />
            <label
              htmlFor="file-upload"
              className={`attachment-icon ${!receiverId && "disable-icon"}`}
            >
              📎
            </label>
            <button   
              type="submit"
              className={`btn ${
                ((!input.trim() && !selectedFiles?.length) || !receiverId) &&
                "disable-btn"
              }`}
              disabled={
                (!input.trim() && !selectedFiles?.length) || !receiverId
              }
            >
              {t("SEND")}
            </button>
          </form>

          {selectedFiles.length > 0 && (
            <div className="imgPreview">
              {selectedFiles.map((preview, index) => (
                <>
                  <div className="imgPreview__inner">
                    {preview.type === "application/pdf" ? (
                      <embed
                        style={{ maxHeight: "fit-content" }}
                        src={URL.createObjectURL(preview)}
                        type="application/pdf"
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(preview)}
                        alt={t("UPLOADED_IMAGE")}
                      />
                    )}
                    <button
                      className="remove-image"
                      onClick={() => {
                        setSelectedFiles(
                          selectedFiles.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <DismissCircle />
                    </button>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <ProductDetailsModel
        currentProductSellerBuyerDetails={currentProductSellerBuyerDetails}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <ToastContainer />
    </div>
  );
};

export default ChatApp;
