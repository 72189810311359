// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-placeholder {
  font-weight: 600 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SellerProfile/SellerProfileForm.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF","sourcesContent":[".date-placeholder {\n  font-weight: 600 !important;\n\n  // When the input has text\n//   .date-input:not(.empty) + & {\n//     top: -8px;\n//     font-size: 12px;\n//     color: #007bff;\n//   }\n\n//   // On focus\n//   .date-input:focus + & {\n//     top: -8px;\n//     font-size: 12px;\n//     color: #007bff;\n//   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
