import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiCalendar } from "react-icons/fi";
import ProductVerificationModal from "./ProductVerificationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProductTable.scss";
import ViewProductImageModal from "./ViewProductImageModal";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [timeSlot, setTimeSlot] = useState(null);
  const [hoveredProductId, setHoveredProductId] = useState(null);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        const verifiedProducts = Array.isArray(response.data.data)
          ? response.data.data.filter((item) => item.id)
          : [];
        setProducts(verifiedProducts);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
      toast.error("Failed to load products");
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const verifyProduct = () => {
    setModalOpen(true);
  };

  const handleAccept = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/verify-product/${currentProductId}`
      );
      if (response?.status === 200) {
        toast.success(t("PRODUCT_VERIFIED"));
        getProducts();
      } else {
        toast.error(t("PRODUCT_VERIFICATION_FAILED"));
      }
    } catch (error) {
      console.error("Error verifying product:", error);
      toast.error(t("PRODUCT_VERIFICATION_FAILED"));
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleReject = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsImageModalOpen(true);
  };

  document.querySelectorAll(".document-link").forEach((link) => {
    link.addEventListener("mouseover", () => {
      // Assuming each link has a `data-preview-url` attribute with the dynamic image URL
      const previewUrl = link.getAttribute("data-preview-url");
      link.style.setProperty("--document-preview-url", `url(${previewUrl})`);
    });
  });

  const getTimeSlotsByProductId = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product-timeslot/${productId}`
      );

      if (response.status === 200) {
        const timeSlotData = response?.data.data?.timeSlot;
        setTimeSlot(timeSlotData);
      } else {
        toast.error("Failed to load time slots");
      }
    } catch (error) {
      console.error("Error fetching time slots:", error);
      toast.error("Error fetching time slots");
    }
  };

  const handleHover = (productId) => {
    setHoveredProductId(productId);
    getTimeSlotsByProductId(productId);
  };

  return (
    <div className="users-table">
      <h2>{t("PRODUCT_LIST")}</h2>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("NAME")}</th>
              <th>{t("CATEGORY")}</th>
              <th>{t("BRAND")}</th>
              <th>{t("GENDER")}</th>
              <th>{t("PRICE")}</th>
              <th>{t("DOCUMENT")}</th>
              <th>{t("PRODUCT_IMAGE")}</th>
              <th>{t("STATUS")}</th>
              <th>{t("ACTION")}</th>
              {/* <th>Time slot</th> */}
            </tr>
          </thead>
          <tbody>
            {products?.length > 0 ? (
              products.map((product, index) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.title}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>{product.gender}</td>
                  <td>{product.price}</td>
                  <td className="document-cell">
                    <a
                      data-preview-url={product.document}
                      href={product.document}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="document-link"
                    >
                      {t("VIEW_DOCUMENT")}
                    </a>
                    <iframe
                      src={product.document}
                      className="document-preview"
                      title="PDF Preview"
                    ></iframe>
                  </td>

                  <td>
                    <div
                      className="image-preview"
                      onClick={() => handleImageClick(index)}
                    >
                      <div className="view-button">
                        <div className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </div>
                        <span>{t("VIEW_IMAGE")}</span>
                      </div>

                      {product.productImage[0] && (
                        <div className="hover-preview">
                          <img src={product.productImage[0]} alt="Preview" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td
                    className={`${product.isVerified ? "active_product" : "inactive_product"
                      }`}
                  >
                    {product.isVerified ? t("VERIFIED") : t("NEED_TO_VERIFY")}
                  </td>
                  <td>
                    <div className="product_action">
                      <GrView
                        className={`common_product ${product.isVerified
                          ? "verified_product"
                          : "not_verified_product"
                          }`}
                        onClick={() => {
                          if (!product.isVerified) {
                            setCurrentProductId(product?.id);
                            verifyProduct();
                          }
                        }}
                      />
                    </div>
                  </td>
                  {/* <td>
                  <div className="calendar-icon">
                    <FiCalendar
                      onClick={() => handleHover(product?.id)}
                      className="common_product"
                    />
                    {hoveredProductId === product.id && (
                      <div className="popover">
                        {timeSlot ? (
                          <div>
                            <p>
                              <strong>Date: </strong>
                              {`${timeSlot?.day} ${timeSlot?.month}, ${timeSlot?.year}`}
                            </p>
                            <p>
                              <strong>Time: </strong> {timeSlot?.time}
                            </p>
                          </div>
                        ) : (
                          <p>No slots available</p>
                        )}
                      </div>
                    )}
                  </div>
                </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">{t("NO_PRODUCTS_FOUND")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ProductVerificationModal
        isOpen={isModalOpen}
        onAccept={handleAccept}
        onReject={handleReject}
        onCancel={handleCancel}
      />

      {/* Image Modal */}
      <ViewProductImageModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        images={products[selectedImageIndex]?.productImage}
        selectedImageIndex={selectedImageIndex}
      />

      <ToastContainer />
    </div>
  );
};

export default Products;
