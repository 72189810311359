import axios from "axios";

// Action Types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// Synchronous Actions
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Async Thunk Action for Login
export const loginUser = (loginData) => {
  console.log("loginData", loginData);
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        loginData
      );
      const { authData, token } = response.data.data;

      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(authData));
      dispatch(loginSuccess(authData));

      return { type: LOGIN_SUCCESS, payload: response?.data.data };
    } catch (error) {
      console.log("error=======", error);
      dispatch(loginFailure(error.response?.data || "Login failed"));

      return { type: LOGIN_FAILURE, payload: error.response?.data };
    }
  };
};
