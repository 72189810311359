import React from "react";
import { ReactComponent as CloseBtn } from "../assets/svg/CloseBtn.svg";
import { ReactComponent as UserSidebar } from "../assets/svg/UserSidebar.svg";
import { ReactComponent as HeartSidebar } from "../assets/svg/HeartSidebar.svg";
import { ReactComponent as ShareSidebar } from "../assets/svg/ShareSidebar.svg";
import { ReactComponent as ChatSidebar } from "../assets/svg/ChatSidebar.svg";
import { ReactComponent as SettingsSidebar } from "../assets/svg/SettingsSidebar.svg";
import { ReactComponent as SignOutSidebar } from "../assets/svg/SignOutSidebar.svg";
import sliderImage1 from "../assets/images/sliderImage1.jpg";

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <Drawer
      className="sidebar"
      anchor="right" // Set drawer to open from the right side
      open={isOpen}
      onClose={toggleDrawer(false)} // Close drawer on outside click
    >
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <div className="Sidebarprofile">
          <IconButton className="closeBtn" onClick={toggleDrawer(false)}>
            <CloseBtn />
          </IconButton>
          <img src={sliderImage1} alt="Uploaded Image" />
          <h3>Good Morning, {userData?.username}</h3>
          <p>{userData?.email}</p>
        </div>
        <ul className="menuList">
          <li>
            <a href="/profile">
              <UserSidebar /> Profile
            </a>
          </li>
          <li>
            <a href="/favourites">
              <HeartSidebar /> Wishlist
            </a>
          </li>
          <li>
            <a href="">
              <ShareSidebar /> Share
            </a>
          </li>
          <li>
            <a href="">
              <ChatSidebar /> Feedback
            </a>
          </li>
          <li>
            <a href="">
              <SettingsSidebar /> Feedback
            </a>
          </li>
          <li onClick={() => handleLogout()}>
            <a href="">
              <SignOutSidebar /> Logout
            </a>
          </li>
        </ul>
        {/* <List>
          <ListItem button onClick={() => navigate("/home")}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => navigate("/profile")}>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => navigate("/settings")}>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={() => navigate("/logout")}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List> */}
      </div>
    </Drawer>
  );
};

export default Sidebar;
