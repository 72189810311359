import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';  // Import redux-thunk
import rootReducer from './reducers';

// Set up Redux DevTools and apply thunk middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))  // Apply the middleware
);

export default store;
