// components/PriceItem.js

import React from "react";

const PriceItem = ({ label, amount }) => (
  <div className="price-item">
    <span>{label}</span>
    <span>{amount.toFixed(2)} MAD</span>
  </div>
);

export default PriceItem;
