import React, { useState } from "react";
import Users from "../Users/Users";
import Products from "../Products/Products";
import { ReactComponent as ThemeBrand } from "../../../assets/svg/ThemeBrand.svg";
import { ReactComponent as Trendies } from "../../../assets/svg/trendies_logo.svg";
import { ReactComponent as Globe } from "../../../assets/svg/Globe.svg";
import { ReactComponent as Menu } from "../../../assets/svg/menu-new.svg";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";
import { RiLoginBoxLine } from "react-icons/ri";
import "./AdminDashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import i18n from "i18next";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState("users");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [languageSelect, setLanguageSelect] = useState(false);
  const languageDataOption = [
    { label: "FR", value: "fr" },
    { label: "EN", value: "en" },
  ];

  const hanldeLanguageClick = () => {
    setLanguageSelect(!languageSelect);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div
      className={`admin-dashboard ${isSidebarOpen ? "sidebar-closed" : "sidebar-open"
        }`}
    >
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "collapsed" : ""}`}>
        <div className="sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>{isSidebarOpen ? <Cross /> : <Menu />}</div>
        <a href="#" class="themeBrand">
          <Trendies />
        </a>

        <ul>
          <li className={activeView === "users" ? "active" : ""}>
            <Link onClick={() => setActiveView("users")} href="">
              {t("USERS")}
            </Link>
          </li>
          <li className={activeView === "products" ? "active" : ""}>
            <Link onClick={() => setActiveView("products")} href="">
              {t("PRODUCTS")}
            </Link>
          </li>
        </ul>
      </div>

      <div className="main-content">
        <div className="top-bar">
          <div className="border-btn languageBtn">
            <Globe onClick={hanldeLanguageClick} />

            {languageSelect && (
              <div className="select-dropdown">
                <Select
                  onChange={(selectedOption) => {
                    setLanguageSelect(!languageSelect);
                    i18n.changeLanguage(selectedOption?.value);
                  }}
                  options={languageDataOption}
                />
              </div>
            )}
          </div>
          <div className="border-btn languageBtn">
            <RiLoginBoxLine style={{ fontSize: "20px" }} onClick={handleLogout} />
          </div>
        </div>
        {activeView === "users" && <Users />}
        {activeView === "products" && <Products />}
      </div>
    </div>
  );
};

export default AdminDashboard;
