// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page {
  margin: 2rem 5rem;
}
@media only screen and (max-width: 575px) {
  .about-page {
    margin: 24px;
  }
}
.about-page p {
  font-weight: 400;
  line-height: 1.5;
}

.description {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/About/About.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EAFF;IAGI,YAAA;EAGF;AACF;AAFE;EACE,gBAAA;EACA,gBAAA;AAIJ;;AADA;EACE,mBAAA;AAIF","sourcesContent":[".about-page {\n  margin: 2rem 5rem;\n  @media only screen and (max-width: 575px) {\n    margin: 24px;\n  }\n  p {\n    font-weight: 400;\n    line-height: 1.5;\n  }\n}\n.description {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
