export const calculateFees = (price, isPremiumListing = false, isSeller) => {
  let commissionRate = 0;
  let commissionRatePercentage = 0;

  // Determine commission rate based on price range
  if (price >= 1000 && price < 2000) {
    commissionRate = 0.10;
    commissionRatePercentage = 10;
  } else if (price >= 2000 && price < 5000) {
    commissionRate = 0.15;
    commissionRatePercentage = 15;
  } else if (price >= 5000 && price < 10000) {
    commissionRate = 0.20;
    commissionRatePercentage = 20;
  } else if (price >= 10000 && price < 20000) {
    commissionRate = 0.25;
    commissionRatePercentage = 25;
  } else if (price >= 20000) {
    commissionRate = 0.30;
    commissionRatePercentage = 30;
  }

  // Fixed fees
  const buyerProtectionFee = -50;
  const buyerProtectionFeeExcludeMinus = 50;

  const deliveryFee = 115;
  const premiumListingFee = isPremiumListing ? 500 : 0;

  // Service fee rate and value
  const serviceFeeRate = 0.05;
  const serviceFeeRatePercentage = serviceFeeRate * 100;
  const serviceFee = -(price * serviceFeeRate);

  // Calculate commission
  const commission = -(price * commissionRate);

  // Calculate total
  // const total = price + commission + buyerProtectionFee + serviceFee + deliveryFee + premiumListingFee;
  let total = price + deliveryFee + premiumListingFee;

  if (isSeller) {
    total = price + commission + buyerProtectionFee + serviceFee  + premiumListingFee;
  } else {
    total = price + deliveryFee ;
  }

  return {
    price,
    commission,
    commissionRatePercentage,
    buyerProtectionFee,
    serviceFee,
    serviceFeeRatePercentage,
    deliveryFee,
    premiumListingFee,
    buyerProtectionFeeExcludeMinus,
    total,
  };
};
