import React, { useEffect, useState } from "react";
import { ReactComponent as UserSmall } from "../../assets/svg/UserSmall.svg";
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg";
import { ReactComponent as Save } from "../../assets/svg/save.svg";
import { ReactComponent as MailSmall } from "../../assets/svg/MailSmall.svg";
import { ReactComponent as LocationSmall } from "../../assets/svg/LocationSmall.svg";
import { ReactComponent as Notepad } from "../../assets/svg/Notepad.svg";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";
import { ReactComponent as Chat } from "../../assets/svg/chat.svg";
import { MdPhone, MdMessage } from "react-icons/md";
import sliderImage1 from "../../assets/images/sliderImage1.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDeleteModal from "../../utils/ConfirmDeleteProduct/ConfirmDeleteModal";
import EditProductModal from "../../utils/EditProduct/EditProductModal";
import axios from "axios";
import "./ProfilePage.scss";
import ScheduleModal from "../ScheduleTime/ScheduleModal";
import SoldProductsList from "./SoldProductList";

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [soldProducts, setSoldProducts] = useState([]);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [userDetails, setUserDetails] = useState({
    username: userData?.username || "",
    nickname: userData?.nickname || "",
    email: userData?.email || "",
    // location: userData?.location || "",
  });
  const initialUserDetail = {
    username: userData?.username || "",
    nickname: userData?.nickname || "",
    email: userData?.email || "",
    // location: userData?.location || "",
  };

  const [isEditing, setIsEditing] = useState({
    username: false,
    nickname: false,
    email: false,
    // location: false,
  });

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleEdit = async (field) => {
    if (isEditing[field]) {
      await handleEditProfile(field);
    }
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleEditProfile = async (field) => {
    const updatedValue = { [field]: userDetails[field] };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update-profile`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setUserDetails(initialUserDetail);
        return toast.error(
          t("UPDATE_PROFILE")
            .replace("option", response?.data?.data?.option)
            .replace("days", response?.data?.data?.days)
        );
      }

      if (response?.status === 200) {
        toast.success(t("PROFILE_UPDATED_SUCCESS"));
        const updatedUser = response.data?.data?.user;
        localStorage.setItem("userData", JSON.stringify(updatedUser));
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      if (response?.status === 200) {
        const unverifiedProducts =
          response?.data?.data?.length > 0 &&
          response?.data?.data.filter(
            (item) => !item.isVerified && userData.id === item?.userId
          );
        setProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const getSoldProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sold-product/${userData?.id}`
      );

      if (response?.status === 200) {
        const unverifiedProducts = response?.data?.data?.products;
        setSoldProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProducts();
    getSoldProducts();
  }, []);

  return (
    <>
      <div className="main-Wrapper">
        <ToastContainer />
        <div className="profile-section">
          <img src={sliderImage1} alt="Profile" className="profile-pic" />
          <h2>{userData?.username}</h2>
          <p>{userData?.email}</p>
        </div>
        <div className="profileSectionBox">
          <div className="profileSectionBox__inner">
            <h3>{t("ACCOUNT_SETTINGS")}</h3>
            <div className="account-settings">
              <div className="account-detail">
                <UserSmall />
                {isEditing.username ? (
                  <>
                    <input
                      type="text"
                      name="username"
                      value={userDetails.username}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          username: e.target.value,
                        })
                      }
                    />
                    <Save
                      onClick={() => toggleEdit("username")}
                      className="editIcon"
                    />
                  </>
                ) : (
                  <>
                    <span>{userDetails.username}</span>
                    <Edit
                      onClick={() => toggleEdit("username")}
                      className="editIcon"
                    />
                  </>
                )}
              </div>
              <div className="account-detail">
                <MailSmall />
                {isEditing.email ? (
                  <>
                    <input
                      type="text"
                      name="email"
                      value={userDetails.email}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          email: e.target.value,
                        })
                      }
                    />
                    <Save
                      onClick={() => toggleEdit("email")}
                      className="editIcon"
                    />
                  </>
                ) : (
                  <>
                    <span>{userDetails.email}</span>
                    <Edit
                      onClick={() => toggleEdit("email")}
                      className="editIcon"
                    />
                  </>
                )}
              </div>

              <div className="account-detail">
                <UserSmall />
                {isEditing.nickname ? (
                  <>
                    <input
                      type="text"
                      name="nickname"
                      value={userDetails.nickname}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          nickname: e.target.value,
                        })
                      }
                    />
                    <Save
                      onClick={() => toggleEdit("nickname")}
                      className="editIcon"
                    />
                  </>
                ) : (
                  <>
                    <span>{userDetails.nickname}</span>
                    <Edit
                      onClick={() => toggleEdit("nickname")}
                      className="editIcon"
                    />
                  </>
                )}
              </div>
              <div className="btnPart seller-button">
                {!userData?.isSeller && !userData?.isSellerCreated && (
                  <button
                    className="btn"
                    onClick={() => navigate("/seller-profile")}
                  >
                    {t("CREATE_SELLER_PROFILE")}
                  </button>
                )}
                <button className="btn" onClick={() => handleLogout()}>
                  {t("LOGOUT")}
                </button>
              </div>
            </div>
          </div>
          <div className="profileSectionBox__inner">
            <h3>{t("YOURS_ADS")}</h3>
            <div className="account-settings">
              {products?.length > 0 ? (
                products.map((product) => (
                  <div className="account-detail product" key={product?.id}>
                    <Notepad />
                    <span onClick={() => navigate(`/product/${product.id}`)}>
                      {product.title}
                    </span>
                    <div className="product-action">
                      <Edit
                        className="editIcon"
                        onClick={() => handleEditProduct(product)}
                      />
                      <Delete
                        onClick={() => setShowConfirmDelete(product.id)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>{t("NO_PRODUCTS_FOUND")}</p>
              )}
            </div>
          </div>
          <div className="profileSection Box__inner">
            <h3>{t("PURCHASE_HISTORY")}</h3>
            <SoldProductsList soldProducts={soldProducts} t={t} />
          </div>
          <div className="profileSectionBox__inner">
            <h3>{t("ITEMS_SOLD")}</h3>
            <div className="account-settings sold-product-card-setting">
              <p>{t("NO_ARTICLE")}</p>
            </div>
          </div>
          <div className="profileSectionBox__inner">
            <h3>{t("SERVICE_CLIENT")}</h3>
            <div className="account-settings">
              <div className="account-detail">
                <MdMessage />
                <span>{t("CHAT_WITH_A_SUPPORT_AGENT")}</span>
              </div>
              <div className="account-detail">
                <MdPhone />
                <span>{t("CONTACT_CUSTOMER_SUPPORT_BY_PHONE")}</span>
              </div>
              <div className="account-detail">
                <MailSmall />
                <span>{t("CONTACT_CUSTOMER_SUPPORT_BY_EMAIL")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        getProducts={getProducts}
      />
      <EditProductModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        product={selectedProduct}
        refreshProducts={getProducts}
      />
    </>
  );
};

export default ProfilePage;
