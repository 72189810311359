// src/pages/ResetPassword.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LockIcon } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../assets/svg/EyeShow.svg";

import "./ResetPassword.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

const ResetPassword = ({
  isChangePassword,
  userId,
  setChangePassword,
  getUserPassword,
  currentPasswordStatus
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password) {
      toast.error(t("CURRENT_PASSWORD_REQUIRED"));
      return;
    }

    if (!confirmPassword) {
      toast.error(t("CONFIRM_PASSWORD_REQUIRED"));
      return;
    }

    if (password !== confirmPassword) {
      toast.error(t("Password_Not_Match"));
      return;
    }

    if (password.length < 6) {
      toast.error(t("PASSWORD_ATLEAST_SIX_CHAR"));
      return
    }

    if (isChangePassword) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/change-user-password`,
          { changedPassword: password, userId }
        );

        if (response.status == 200) {
          await getUserPassword();
          setLoading(true)
          toast.success(t("PASSWORD_SET"), {
            autoClose: 800,
            onClose: () => setChangePassword(false)
          });
        }
      } catch (error) {
        console.error("Change password error:", error);
        toast.error(
          error.response?.data?.message || "Change failed. Try again."
        );
      } finally {
        setLoading(false);
      }

    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/forget-user-password`,
          { token, newPassword: password },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 200) {
          toast.success(response.data.message || "Password reset successful!");
          navigate("/login"); // Redirect to login page
        }
      } catch (error) {
        console.error("Reset password error:", error);
        toast.error(error.response?.data?.message || "Reset failed. Try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={`${!isChangePassword && "ResetPassword"}`}>
      <div className="ResetPassword__inner">
        <h2 className="ResetPassword__title">
          {isChangePassword
            ? currentPasswordStatus === "update_password" ? t("Update_Password") : t("Add_Password")
            : t("Reset_Password")}
        </h2>

        <form onSubmit={handleSubmit} className="ResetPassword__form">
          <div className="form-group">
            <LockIcon className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}

              placeholder={t("New_Password")}
              className="input-with-icon"
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              {showPassword ? <EyeShow /> : <EyeHide />}
            </span>
          </div>

          <div className="form-group">
            <LockIcon className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}

              placeholder={t("Confirm_Password")}
              className="input-with-icon"
            />
          </div>

          <div className="ResetPassword__btn">
            <button type="submit" className="btn w-100" disabled={loading}>
              {loading
                ? "Resetting..."
                : isChangePassword
                  ? currentPasswordStatus === "update_password" ? t("Update_Password") : t("Add_Password")
                  : t("Reset_Password")}
            </button>
          </div>
          {!isChangePassword && (
            <div className="ResetPassword__footer">
              <p>
                Know your password? <Link to="/login">Log in here</Link>
              </p>
            </div>
          )}
        </form>
      </div>

      <ToastContainer
      />
    </div>
  );
};

export default ResetPassword;
