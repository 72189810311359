export const ADD_FAVORITE = "ADD_FAVORITE";
export const CLEAR_FAVORITE_COUNT = "CLEAR_FAVORITE_COUNT";
export const RENDER_FAVORITE_COUNT = "RENDER_FAVORITE_COUNT"

export const clearFavorite = (count) => ({
  type: CLEAR_FAVORITE_COUNT,
  payload: count,
});

export const addFavorite = (quantity) => ({
  type: ADD_FAVORITE,
  payload: quantity,
})

export const renderFavorite = (quantity) => ({
  type: RENDER_FAVORITE_COUNT,
  payload: quantity,
})