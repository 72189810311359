import { ReactComponent as Watch } from "../assets/svg/watch.svg";
import { ReactComponent as Jewelry } from "../assets/svg/jewelry.svg";
import { ReactComponent as Bag } from "../assets/svg/bag.svg";
import { ReactComponent as Shoes } from "../assets/svg/shoes.svg";
import { ReactComponent as Accessories } from "../assets/svg/accessories.svg";

export const categoryOptions = [
  { value: "watches", label: "Watches", icon: <Watch /> },
  { value: "jewelry", label: "Jewelry", icon: <Jewelry /> },
  { value: "bags", label: "Bags", icon: <Bag /> },
  { value: "shoes ", label: "Shoes", icon: <Shoes /> },
  { value: "accessories", label: "Accessories", icon: <Accessories /> },
];
