import { useTranslation } from "react-i18next";

export const useSensitivePatterns = () => {
  const { t } = useTranslation();

  return [
    // Email Patterns
    {
      pattern: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i,
      message: t("EMAIL_ADDRESS_SENSITIVE_MESSAGE"),
    },
    {
      // Catch email addresses with common variations
      pattern: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      message: t("ALTERNATE_EMAIL_ADDRESS_SENSITIVE_MESSAGE"),
    },

    // Phone Number Patterns
    {
      // Basic 10-digit phone numbers
      pattern: /\b\d{10}\b/,
      message: t("BASIC_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      // International format with country codes
      pattern:
        /\b(\+?\d{1,3}[-.\s]?)?(\d{2,3}[-.\s]?)?\d{3}[-.\s]?\d{3,4}[-.\s]?\d{4}\b/,
      message: t("INTERNATIONAL_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      // Phone numbers with various separators
      pattern: /(\d{3}[-.)]\s*\d{3}[-.)]\s*\d{4})/,
      message: t("SEPARATOR_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      // Hidden phone numbers in text
      pattern: /(?:\D*\d){10,}(?=.*(?:\D*\d){10})/,
      message: t("HIDDEN_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },

    // Address Patterns
    {
      // Street address pattern
      pattern:
        /\d+\s+([a-zA-Z]+\s*)+(?:street|st|avenue|ave|road|rd|highway|hwy|square|sq|trail|trl|drive|dr|court|ct|park|parkway|pkwy|circle|cir|boulevard|blvd)\b/i,
      message: t("STREET_ADRESS_SENSITIVE_MESSAGE"),
    },
    {
      // PO Box pattern
      pattern:
        /\b[P|p]?(OST|ost)?\s*[O|o]?(FFICE|ffice)?\s*[B|b]?(OX|ox)?\s+\d+/i,
      message: t("PO_STREET_ADRESS_SENSITIVE_MESSAGE"),
    },
    {
      // Zip/Postal code patterns (various formats)
      pattern: /\b\d{5}(-\d{4})?\b|\b[A-Z]\d[A-Z]\s?\d[A-Z]\d\b/i,
      message: t("ZIP_STREET_ADRESS_SENSITIVE_MESSAGE"),
    },
    {
      // Common Indian PIN codes
      pattern: /\b\d{6}\b/,
      message: t("COMMON_INDIAN_PIN_SENSITIVE_MESSAGE"),
    },

    // Location Patterns
    {
      // City, State pattern (US format)
      pattern: /\b(?:[A-Z][a-zA-Z\-\s]+,\s*[A-Z]{2})\b/,
      message: t("CITY_STATE_COMBINATION_SENSITIVE_MESSAGE"),
    },
    {
      // Common Indian city-state patterns
      pattern:
        /\b(?:mumbai|delhi|bangalore|hyderabad|chennai|kolkata|pune|ahmedabad|jaipur)(?:\s*,\s*(?:maharashtra|delhi|karnataka|telangana|tamil nadu|west bengal|rajasthan|gujarat))?\b/i,
      message: t("CITY_STATE_COMBINATION_SENSITIVE_MESSAGE"),
    },

    // Financial Information
    {
      // Credit card numbers (major formats)
      pattern: /\b(?:\d[ -]*?){13,16}\b/,
      message: t("CREDIT_CARD_SENSITIVE_MESSAGE"),
    },
    {
      // Bank account numbers (general pattern)
      pattern: /\b\d{9,18}\b/,
      message: t("BANK_ACCOUNT_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      // IFSC codes (Indian banks)
      pattern: /\b[A-Z]{4}0[A-Z0-9]{6}\b/,
      message: t("IFSC_CODE_SENSITIVE_MESSAGE"),
    },

    // Identification Numbers
    {
      // Aadhaar numbers (Indian)
      pattern: /\b\d{4}\s?\d{4}\s?\d{4}\b/,
      message: t("IDENTIFICATION_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      // PAN card numbers (Indian)
      pattern: /\b[A-Z]{5}[0-9]{4}[A-Z]{1}\b/,
      message: t("PAN_CARD_SENSITIVE_MESSAGE"),
    },
    {
      // Passport numbers (general pattern)
      pattern: /\b[A-Z]{1,2}[0-9]{6,7}\b/,
      message: t("PASSPOST_NUMBER_SENSITIVE_MESSAGE"),
    },

    // Sensitive Keywords
    {
      pattern: /\b(password|passwd|pwd)\b/i,
      message: t("PASSWORD_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(credit\s*card|debit\s*card|cvv|pin\s*code)\b/i,
      message: t("PAYMENT_OR_BANK_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(ssn|social\s*security|tax\s*id)\b/i,
      message: t("GOVT_ID_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(aadhar|aadhaar|pan\s*card|voter\s*id)\b/i,
      message: t("INDIAN_ID_RELATED_SENSITIVE_MESSAGE"),
    },
  ];
};
