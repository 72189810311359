import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { ReactComponent as ThemeBrand } from "../assets/svg/ThemeBrand.svg";
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as Email } from "../assets/svg/Email.svg";
import { ReactComponent as User } from "../assets/svg/User.svg";
import { ReactComponent as Lock } from "../assets/svg/Lock.svg";
import { ReactComponent as EyeHide } from "../assets/svg/EyeHide.svg";
import { ReactComponent as GoogleIcon } from "../assets/svg/googleIcon.svg";
import { ReactComponent as FbIcon } from "../assets/svg/fbIcon.svg";
import { ReactComponent as InstaIcon } from "../assets/svg/instaIcon.svg";
import { useFormik } from "formik";
import "./SignIn.scss";
import { loginValidationSchema } from "../Validation/validationSchemas";
import { loginUser } from "../store/actions/authActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";

const SignIn = () => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState("");

  const { login } = useAuth();

  const handleLogin = async (values) => {
    try {
    
      let loginValues = { ...values };
      
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      if (loginValues?.nickname && emailPattern.test(loginValues.nickname)) {
        loginValues.email = loginValues.nickname;
        delete loginValues.nickname;  
      }
      
      const result = await dispatch(loginUser(loginValues));
      
      if (result.type === "LOGIN_SUCCESS") {
        toast.success(t("LOGIN_SUCCESS"));
        const isAdmin = result?.payload?.authData?.is_admin;
        login();
        
        if (isAdmin) {
          navigate("/admin");
        } else {
          navigate("/home");
        }
      } else {
        toast.error(t("EMAIL_NOT_VERIFIED") || "Login failed!");
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: () => {
      toast.error("Google login failed. Please try again.");
    },
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      // email: "",
      password: "",
      nickname: "",
    },
    validationSchema: loginValidationSchema(t),
    onSubmit: (values) => handleLogin(values),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="Login">
      <div className="Login__inner">
        <a href="#" className="themeBrand">
        <Trendies />
        </a>
        <h2 className="Login__title">{t("SIGN_IN")}</h2>
        <form onSubmit={formik.handleSubmit} className="Login__form">
          {/* <div className="form-group">
            <Email className="icon" />
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("ENTER_EMAIL")}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // required
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}
          </div> */}
          <div className="form-group">
            <User className="icon" />
            <input
              type="text"
              id="nickname"
              name="nickname"
              placeholder={t("ENTER_NICKNAME")}
              value={formik.values.nickname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.nickname && formik.errors.nickname ? (
              <div className="error-message">{formik.errors.nickname}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Lock className="icon" />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder={t("ENTER_PASSWORD")}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="EyeIcon" onClick={togglePasswordVisibility}>
              <EyeHide />
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-message">{formik.errors.password}</div>
            ) : null}
          </div>
          <Link className="forgotPass" to="/forgot-password">
            {t("FORGOT_PASSWORD")} ?{" "}
          </Link>
          <div className="login-btn">
            <button type="submit" className="btn">
              {t("SIGN_IN")}
            </button>
          </div>
        </form>

        <div className="content-text">
          {t("BY_LOGGING_YOU_AGREE_TO")}
          <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
          <span> {t("TERMS_OF_USE")}</span>
        </div>

        <div className="continue-text">
          <span> {t("OR_CONTINUE_WITH")}</span>
        </div>

        <div className="social-icons">
          <button onClick={() => loginWithGoogle()} className="social-button">
            <GoogleIcon />
          </button>
          <button className="social-button">
            <FbIcon />
          </button>
          <button className="social-button">
            <InstaIcon />
          </button>
        </div>

        <p className="signup-link">
          {t("DO_NOT_HAVE_AN_ACCOUNT")}?{" "}
          <Link to="/signup">
            {t("SIGN_UP")} {t("HERE")}
          </Link>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignIn;
