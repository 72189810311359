// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
  position: absolute;
  top: 34%;
  left: 26%;
  transform: translateY(-50%);
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px;
  padding: 15px;
  width: 180px;
}

.popup-content {
  display: flex;
  flex-direction: column;
}
.popup-content label {
  flex-direction: row;
  gap: 8px;
}
.popup-content label input {
  width: auto;
}

.popup-content h4 {
  margin: 0;
  font-size: 14px;
}

.popup-content ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.popup-content li {
  margin-bottom: 8px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-icon {
  cursor: pointer;
  font-size: 14px;
  color: #888;
}

.close-icon:hover {
  color: #000;
}

.searchFilterBlock {
  position: relative;
  width: fit-content;
  max-width: 450px;
  width: 100%;
}
.searchFilterBlock .popup {
  top: 100%;
  transform: none;
  min-width: 180px;
  width: auto;
  left: auto;
  right: 0;
}

input[type=checkbox] {
  accent-color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterPopup/FilterPopup.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,sBAAA;EACA,2CAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,mBAAA;EACA,QAAA;AAEJ;AADI;EACE,WAAA;AAGN;;AAEA;EACE,SAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AACA;EACE,kBAAA;EACI,kBAAA;EACF,gBAAA;EACA,WAAA;AAEJ;AADE;EACE,SAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,QAAA;AAGJ;;AAAA;EACE,qBAAA;AAGF","sourcesContent":[".popup {\n  position: absolute;\n  top: 34%;\n  left: 26%;\n  transform: translateY(-50%);\n  background-color: #fff;\n  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);\n  z-index: 10;\n  border-radius: 8px;\n  padding: 15px;\n  width: 180px;\n}\n\n.popup-content {\n  display: flex;\n  flex-direction: column;\n  label {\n    flex-direction: row;\n    gap: 8px;\n    input {\n      width: auto;\n    }\n  }\n}\n\n.popup-content h4 {\n  margin: 0;\n  font-size: 14px;\n}\n\n.popup-content ul {\n  list-style: none;\n  padding: 0;\n  margin: 20px 0 0;\n}\n\n.popup-content li {\n  margin-bottom: 8px;\n}\n\n.popup-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.close-icon {\n  cursor: pointer;\n  font-size: 14px;\n  color: #888;\n}\n\n.close-icon:hover {\n  color: #000;\n}\n.searchFilterBlock{\n  position: relative;\n      width: fit-content;\n    max-width: 450px;\n    width: 100%;\n  .popup {\n    top: 100%;\n    transform: none;\n    min-width: 180px;\n    width: auto;\n    left: auto;\n    right: 0;\n  }\n}\ninput[type=\"checkbox\"] {\n  accent-color: #333333; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
