import React from "react";
import "./ProductVerificationModal.scss";
import { useTranslation } from "react-i18next";

const ProductVerificationModal = ({ isOpen, onAccept, onReject, onCancel }) => {
  const {t} = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="product-verification-modal-overlay">
      <div className="product-verification-modal">
        <h3>{t("VERIFY_PRODUCT")}</h3>
        <p>{t("ARE_YOU_SURE_WANT_TO_VERIFY_PRODUCT")}</p>
        <div className="product-verification-modal-actions">
          <button className="modal-accept-button" onClick={onAccept}>
            {t("ACCEPT")}
          </button>
          <button className="modal-reject-button" onClick={onReject}>
            {t("REJECT")}
          </button>
          <button className="modal-cancel-button" onClick={onCancel}>
            {t("CANCEL")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductVerificationModal;
