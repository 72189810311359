// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .favorite-products-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }

  .search-filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .search-bar {
    width: 70%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .filter-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .product-list {
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }

  .product-item {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
  }

  .product-item h3 {
    margin: 0;
  }
   */
.favorite-products-container .product-list {
  padding: 30px 0;
}
.favorite-products-container .product-list .btn {
  width: 100%;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/FavoriteProducts.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAAA;AAwCE;EACE,eAAA;AAAJ;AACI;EACE,WAAA;EACA,eAAA;AACN","sourcesContent":["/* .favorite-products-container {\n    padding: 20px;\n    max-width: 600px;\n    margin: auto;\n  }\n  \n  .search-filter-container {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n  \n  .search-bar {\n    width: 70%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .filter-select {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .product-list {\n    border-top: 1px solid #ccc;\n    padding-top: 10px;\n  }\n  \n  .product-item {\n    padding: 10px;\n    border-bottom: 1px solid #f0f0f0;\n  }\n  \n  .product-item h3 {\n    margin: 0;\n  }\n   */\n.favorite-products-container {\n  .product-list {\n    padding: 30px 0;\n    .btn {\n      width: 100%;\n      margin-top: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
