import { ADD_FAVORITE, CLEAR_FAVORITE_COUNT,RENDER_FAVORITE_COUNT } from "../actions/favoritesAction";

// Initial State
const initialState = {
  favoriteCount: 0,
};

// Reducer Function
const favoriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAVORITE:
      return { ...state, favoriteCount: state.favoriteCount + action.payload };
    case CLEAR_FAVORITE_COUNT:
      return {
        ...state,
        favoriteCount: state.favoriteCount - 1,
      };
    case RENDER_FAVORITE_COUNT:
      return {
        ...state,
        favoriteCount: action?.payload,
      };
    default:
      return state;
  }
};

export default favoriteReducer;
