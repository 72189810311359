// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-page-container h2 {
  text-align: center;
}
.product-page-container p {
  margin: 0;
}
.product-page-container .btnPart {
  text-align: right;
  margin: 10px 0;
}

.filter-container {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.filter-container .form-group {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ProductPage.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,SAAA;AAAJ;AAEE;EACE,iBAAA;EACA,cAAA;AAAJ;;AAGA;EACE,aAAA;EACA,qBAAA;EACA,SAAA;AAAF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".product-page-container {\n  h2 {\n    text-align: center;\n  }\n  p {\n    margin: 0;\n  }\n  .btnPart {\n    text-align: right;\n    margin: 10px 0;\n  }\n}\n.filter-container {\n  display: flex;\n  align-items: flex-end;\n  gap: 15px;\n  .form-group {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
