// src/components/Header.js
import React, { useEffect, useState } from "react";
import "./Header.scss";

import { RiLoginBoxLine } from "react-icons/ri";
import { ReactComponent as ThemeBrand } from "../assets/svg/ThemeBrand.svg";
import { ReactComponent as Globe } from "../assets/svg/Globe.svg";
import { ReactComponent as UserProfile } from "../assets/svg/UserProfile.svg";
import { ReactComponent as Menu } from "../assets/svg/Menu.svg";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Siderbar";
import Select from "react-select";
import i18n from "i18next";
import { ReactComponent as Add } from "../assets/svg/Add.svg";
import { ReactComponent as Trendies } from "../assets/svg/trendies_logo.svg";
import { ReactComponent as Bag } from "../assets/svg/Shopping Bag.svg";
import { ReactComponent as Heart } from "../assets/svg/Heart.svg";
import { ReactComponent as Chat } from "../assets/svg/chat.svg";
import { IoNotifications } from "react-icons/io5";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NotificationPanel from "./Notification/NotificationPanel";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isNotification, setIsNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [languageSelect, setLanguageSelect] = useState(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const languageDataOption = [
    { label: "FR", value: "fr" },
    { label: "AR", value: "ar" },
    { label: "EN", value: "en" },
  ];
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const hanldeLanguageClick = () => {
    setLanguageSelect(!languageSelect);
  };

  async function getNotificationByUserId() {
    try {
      const userId = userData.id;
      const responseNotification = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/${userId}`
      );
      const notificationData = responseNotification?.data?.data;
      if (responseNotification?.data?.status) {
        setNotifications(notificationData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    getNotificationByUserId();
  }, []);

  return (
    <header className="header">
      <Link to="/home" className="themeBrand">
        <Trendies />
      </Link>
      <div className="header-icons">
        <button className="btn border-btn">
          <Chat onClick={() => navigate("/chat")} />
        </button>
        <button
          className="btn border-btn"
          onClick={() => {
            userData?.isSeller && userData?.isSellerCreated
              ? navigate("/add-product")
              : !userData?.isSeller && userData?.isSellerCreated
                ? toast.info(t("WAIT_FOR_SELLER_ACCOUNT_APPROVAL"))
                : navigate("/profile");
          }}
        >
          <Add />
        </button>
        <button
          className="btn btn border-btn"
          onClick={() => navigate("/favourites")}
        >
          <Heart />
        </button>
        <button
          className="btn btn border-btn"
          onClick={() => navigate("/cart")}
        >
          <Bag />
        </button>
        <button className="btn btn border-btn" onClick={hanldeLanguageClick}>
          <Globe />
        </button>
        {languageSelect && (
          <div className="select-dropdown">
            <Select
              // value={selectedOption}
              onChange={(selectedOption) => {
                setLanguageSelect(!languageSelect);
                i18n.changeLanguage(selectedOption?.value);
              }}
              options={languageDataOption}
            // formatOptionLabel={formatOptionLabel}
            />
          </div>
        )}
        <button
          className="btn btn border-btn notification-btn"
          onClick={() => setIsNotification(true)}
        >
          {/* <Link to="/notification"> */}
          <IoNotifications />
          {notifications?.length > 0 && (
            <span className="notification-count">{notifications.length}</span>
          )}
          {/* </Link> */}
        </button>
        {
          userData?.id ? <button
            onClick={() => {
              navigate("/profile");
            }}
          >
            <UserProfile />
          </button> : <button
          className="btn btn border-btn notification-btn"
            onClick={() => {
              navigate("/login");
            }}
          >
            <RiLoginBoxLine style={{ fontSize: "20px" }} />
          </button>

        }

        {/* <button className="btn btn border-btn" onClick={toggleDrawer(true)}>
          <Menu />
        </button> */}
      </div>
      <Sidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />

      {isNotification && (
        <Dialog
          open={isNotification}
          keepMounted
          onClose={() => setIsNotification(false)}
          aria-describedby="notification-dialog-description"
          PaperProps={{
            style: {
              marginTop: "80px",
              maxWidth: "450px",
              width: "90%",
              height: "600px",
              position: "fixed",
              top: "4%",
              right: "0",
              overflow: "hidden",
              padding: "0",
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <DialogTitle style={{ padding: "0", marginBottom: "8px" }}>
            <div className="dialog-header">
              <h2
                style={{
                  margin: "0",
                  fontSize: "18px",
                  padding: "0",
                  fontWeight: "bold",
                }}
              >
                Notifications
              </h2>
              <IconButton
                className="close-btn"
                onClick={() => setIsNotification(false)}
              >
                {/* <CloseIcon /> */}X
              </IconButton>
            </div>
            <Box className="Tabbing">
              <TabContext value={value}>
                <TabList
                  className="Tabbing__btn"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={t("VIEW_ALL")} value="1" />
                  <Tab label={t("MENTIONS")} value="2" />
                </TabList>
                <NotificationPanel
                  notifications={notifications}
                  getNotificationByUserId={getNotificationByUserId}
                />
                {/* <TabPanel value="1" className="Tabbing__details">
                  <DialogContent className="dialogContent">
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div className="itemsBox">
                        <p className="itemsBox__title">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </p>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <div className="itemsBoxInner">
                          <div className="itemsBoxInner__img">
                            <img
                              style={{ width: "30px", height: "30px" }}
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbLEq1_oZWg_4J-N3SEauTNVc-euN6Opg3-g&s"
                            />
                          </div>
                          <div className="itemsBoxInner__content">
                            <p>
                              This is looking great! Let’s get started on it.
                            </p>
                            <p>16 MB</p>
                          </div>
                          <button>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXCpzsNO1D6ydP3TKL6zOo0o-6Xy_ba5dBccO-6sd3AROzR8_Vhdff4Cm_w52DkILQoAo&usqp=CAU" />
                          </button>
                        </div>

                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="content-item-container">
                      <Avatar
                        className="item-avatar"
                        alt="Remy Sharp"
                        src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                      />
                      <div style={{ flex: 1 }}>
                        <Typography variant="body2">
                          <strong>Frankie Sullivan</strong> commented on{" "}
                          <strong>your post</strong>
                        </Typography>
                        <p className="item-data">
                          This is looking great! Let’s get started on it.
                        </p>
                        <div className="item-data-time">
                          <p>Friday 2:20pm</p>
                          <p>Sep 20, 2024</p>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </TabPanel> */}
                <TabPanel value="2">{t("MENTIONS")}</TabPanel>
              </TabContext>
            </Box>
          </DialogTitle>

          {/* <DialogActions className="dialogActions">
            <Button variant="text" color="primary">
              Mark all as read
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsNotification(false)}
            >
              View all notifications
            </Button>
          </DialogActions> */}
        </Dialog>
      )}
    </header>
  );
};

export default Header;
